<template>
  <div>
    <div class="main-header g-flex g-align-center">
      <div class="main-header-left g-flex g-align-center">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="main-header-links">
        <router-link
          to="/employee-analytics"
          v-if="$store.state.authUser.role == 'Employee'"
          >Home
        </router-link>
        <router-link
          to="/therapist-analytics"
          v-if="$store.state.authUser.role == 'Psychologist'"
          >Home
        </router-link>
        <router-link
          to="/my-info-view"
          v-if="$store.state.authUser.role == 'Employee'"
          >My Account
        </router-link>
        <router-link
          to="/mood-tracker"
          v-if="$store.state.authUser.role == 'Employee'"
          >Mood Tracker
        </router-link>
        <router-link
          to="/my-doctors"
          v-if="$store.state.authUser.role == 'Employee'"
          >My Doctors
        </router-link>
        <router-link
          to="/my-patient"
          v-if="$store.state.authUser.role == 'Psychologist'"
          >My Patient
        </router-link>
        <router-link
          to="/patient-consultations"
          v-if="$store.state.authUser.role == 'Psychologist'"
          >Consultations</router-link
        >
      </div>
      <div class="main-header-right">
        <div
          class="g-flex g-align-center"
          @click="isToggleUserDropDown = !isToggleUserDropDown"
        >
          {{ authUser.name }}
          <user-circle
            :currentIndex="0"
            :currentUser="authUser.name"
            marginLeft="10px"
            marginRight="0"
            width="45px"
            height="45px"
          ></user-circle>
        </div>
        <div class="main-header-right-dropdwn" v-if="isToggleUserDropDown">
          <button
            class="g-flex g-align-center g-content-center"
            @click="redirectToProfileHandler"
          >
            <i class="fa-solid fa-user"></i>
            Profile
          </button>
          <button
            class="g-flex g-align-center g-content-center"
            @click="userLogoutHandler"
            :disabled="isLoggingOut"
          >
            <span v-if="!isLoggingOut">
              <i class="fa-solid fa-right-from-bracket"></i>
              Log out
            </span>
            <btn-loader v-else></btn-loader>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import BtnLoader from "./reusable/BtnLoader.vue";
import UserCircle from "./reusable/UserCircle.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
export default {
  components: { BtnLoader, UserCircle },
  data() {
    return {
      isToggleUserDropDown: false,
      isLoggingOut: false,
    };
  },
  computed: {
    ...mapState(["authUser"]),
  },
  methods: {
    redirectToProfileHandler() {
      if (this.$store.state.authUser.role == "Psychologist") {
        this.$router.push("/therapist-login-profile");
      }
      if (this.$store.state.authUser.role == "Employee") {
        this.$router.push("/my-info-view");
        this.$store.commit("setPatientInfoActiveTab", "change_passord");
      }
      this.isToggleUserDropDown = !this.isToggleUserDropDown;
    },

    userLogoutHandler() {
      this.isLoggingOut = true;
      axios
        .post(`${this.$store.state.apiUrl}/v1/logout`, null, {
          headers: {
            Authorization: `Bearer ${this.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          localStorage.clear();
          sessionStorage.clear();
          this.$store.commit("setUserLogoutReset");
          this.$router.push("/");
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isLoggingOut = false;
        });
    },
  },
};
</script>

<style scoped>
.main-header {
  background-color: #ffffff;
  justify-content: space-between;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
}
.main-header-left img {
  width: 110px;
  height: 60px;
  margin: 5px 20px;
}
.main-header-right {
  position: relative;
  margin-right: 20px;
}
.main-header-right:hover {
  cursor: pointer;
}
.main-header-right-dropdwn {
  width: 200px;
  background: #ffffff;
  position: absolute;
  top: 50px;
  right: 0;
  border: 1px solid #dddddd;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  z-index: 100;
}
.main-header-right-dropdwn button {
  background: transparent;
  border: none;
  border-bottom: 1px solid #dddddd;
  width: 100%;
  margin: 0;
  margin: 0;
  height: 40px;
}
.main-header-right-dropdwn button:last-child {
  border: none !important;
}
.main-header-right-dropdwn button i {
  margin-right: 6px;
}
.main-header-right-dropdwn button:hover {
  cursor: pointer;
  color: var(--primary-color);
  background: var(--third-main-color);
}
.main-header-left-menu {
  margin-left: 50px;
}
.main-header-left-menu i {
  cursor: pointer;
}
.main-header-links a {
  margin: 0 10px;
  text-decoration: none;
  color: var(--primary-color);
  font-weight: 500;
  padding: 10px;
}
.main-header-links a:hover {
  color: var(--primary-color);
  text-decoration: underline;
}
</style>