<template>
  <div
    class="user-circle"
    :style="{
      backgroundColor: allColors[currentIndex],
      width: width,
      height: height,
      marginLeft: marginLeft,
      marginRight: marginRight,
    }"
  >
    {{ getNameCharacters }}
  </div>
</template>

<script>
export default {
  data() {
    return {
      allColors: {
        0: "#1e4b7d",
        1: "#687282",
        2: "#5e8722",
        3: "#6c6c6c",
        4: "#30649d",
        5: "#464646",
        6: "#1e4b7d",
        7: "#687282",
        8: "#5e8722",
        9: "#6c6c6c",
        10: "#30649d",
        11: "#464646",
        12: "#1e4b7d",
        13: "#687282",
        14: "#5e8722",
        15: "#6c6c6c",
        16: "#30649d",
        17: "#464646",
        18: "#1e4b7d",
        19: "#687282",
        20: "#5e8722",
        21: "#6c6c6c",
        22: "#30649d",
        23: "#464646",
        24: "#1e4b7d",
        25: "#687282",
        26: "#5e8722",
        27: "#6c6c6c",
        28: "#30649d",
        29: "#464646",
      },
    };
  },
  props: {
    currentIndex: { type: Number },
    currentUser: { type: String },
    width: { type: String, default: "38px" },
    height: { type: String, default: "38px" },
    marginLeft: { type: String, default: "0" },
    marginRight: { type: String, default: "0" },
  },
  computed: {
    getNameCharacters() {
      const userName = this.currentUser.split(" ");
      if (userName.length > 1) {
        return userName[0].substring(0, 1) + userName[1].substring(0, 1);
      } else {
        return userName[0].substring(0, 1);
      }
    },
  },
};
</script>

<style scoped>
.user-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
  border-radius: 100%;
  text-transform: uppercase;
}
</style>