<template>
  <div class="employee-header g-flex g-align-center g-space-btw">
    <h3>List of Employees</h3>
    <div style="width: 300px" class="global-search">
      <input
        type="text"
        placeholder="Search..."
        v-model="searchEmployee"
        @keyup.enter="searchEmployeesHandler"
      />
      <button @click="searchEmployeesHandler">
        <i class="fa-solid fa-magnifying-glass"></i>
      </button>
    </div>
  </div>
  <div style="padding: 0 10px">
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Employee number</th>
          <th>Email</th>
          <th>Phone</th>
          <th style="width: 90px">Payment</th>
          <th style="width: 90px">Treatment</th>
          <th>Status</th>
          <!-- <th>Verified</th> -->
          <th>Action</th>
        </tr>
      </thead>
      <tbody v-if="employeesList.length && !isGettingEmployees">
        <tr v-for="(emp, index) in employeesList" :key="emp.id">
          <td class="g-flex g-align-center">
            <user-circle
              :currentIndex="index"
              :currentUser="emp.name"
              marginRight="10px"
            ></user-circle
            >{{ emp.name }}
          </td>
          <td>{{ emp.employee_number }}</td>
          <td>{{ emp.loginDetails.email }}</td>
          <td>{{ emp.phone }}</td>
          <td>
            <span
              :class="
                emp.loginDetails.is_subscription_paid
                  ? 'make-green-bg'
                  : 'make-red-gb'
              "
              >{{
                emp.loginDetails.is_subscription_paid ? "Paid" : "Not paid"
              }}</span
            >
          </td>
          <td>
            <span :class="emp.assigned ? 'make-green-bg' : 'make-red-gb'">
              {{ emp.assigned ? "Has doctor" : "No doctor" }}
            </span>
          </td>
          <td :class="emp.loginDetails.active ? 'green-status' : 'red-status'">
            {{ emp.loginDetails.active ? "Active" : "Inactive" }}
          </td>
          <!-- <td
            :class="emp.loginDetails.verified ? 'green-status' : 'red-status'"
          >
            {{ emp.loginDetails.verified ? "Yes" : "No" }}
          </td> -->
          <td>
            <button
              style="background-color: transparent; border: none"
              @click="toggleSingleEmployeeDetails(emp.id)"
            >
              <i class="fa-solid fa-eye"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="uhuru-pg-container" v-if="!isGettingEmployees && hasNextPage">
      <vue-awesome-paginate
        :total-items="totalPgItems"
        :items-per-page="10"
        :max-pages-shown="8"
        v-model="currentPage"
        paginate-buttons-class="pg-btn"
        active-page-class="pg-btn-active"
        back-button-class="pg-back-btn"
        next-button-class="pg-next-btn"
        prev-button-content="Prev"
        next-button-content="Next"
        :on-click="getCompanyEmployees"
      />
    </div>

    <div
      style="width: 100%; margin: 10px"
      class="g-flex g-align-center g-content-center"
      v-if="isGettingEmployees"
    >
      <table-data-loader></table-data-loader>
    </div>

    <div v-if="isViewSingleEmployeeDetails">
      <modal :closeModal="toggleSingleEmployeeDetails" width="700px">
        <template #content>
          <company-employee-details
            :singleSelectedEmployee="singleSelectedEmployee"
          ></company-employee-details>
        </template>
      </modal>
    </div>
  </div>
</template>
  
  <script>
import axios from "axios";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import CompanyEmployeeDetails from "./CompanyEmployeeDetails.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    UserCircle,
    ActionsBtn,
    WarningMsg,
    CompanyEmployeeDetails,
  },
  data() {
    return {
      isGettingEmployees: false,
      employeesList: [],
      clientId: this.$route.params.id,

      isViewSingleEmployeeDetails: false,
      singleSelectedEmployee: "",

      searchEmployee: "",
      isOnSearchingEmployee: false,
      currentPage: 1,
      totalPgItems: "",
      hasNextPage: false,
    };
  },
  methods: {
    onClickHandler(page) {
      console.log(page);
    },

    searchEmployeesHandler() {
      if (!this.searchEmployee) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }
      this.currentPage = 1;
      this.isOnSearchingEmployee = true;
      this.getCompanyEmployees();
    },

    toggleSingleEmployeeDetails(item) {
      this.singleSelectedEmployee = item;
      this.isViewSingleEmployeeDetails = !this.isViewSingleEmployeeDetails;
    },

    getCompanyEmployees(page) {
      this.isGettingEmployees = true;

      const actionUrl = this.isOnSearchingEmployee
        ? `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/employees?search=${this.searchEmployee}&page=""`
        : `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/employees?page=${page}`;

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.employeesList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingEmployees = false;
        });
    },
  },
  created() {
    this.getCompanyEmployees();
  },
};
</script>
  
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.employee-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  /* background: #f5f5f5; */
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-employee-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
.make-green-bg {
  background: #5e8722;
  padding: 1px 10px;
  color: white;
  border-radius: 20px;
  font-size: 13px;
}
.make-red-gb {
  background: #a60909;
  padding: 1px 10px;
  color: white;
  border-radius: 20px;
  font-size: 13px;
}
</style>