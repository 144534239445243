<template>
  <div>
    <div class="p-quiz-results" v-for="item in resultsList" :key="item.id">
      <p>Result on {{ formatDate(item.consultation_date) }}</p>
      <div class="g-flex quiz-results-div--1">
        <div class="g-flex quiz-results-div--2">
          <div
            class="in-red-results"
            :class="{ 'make-op': item.percentage >= 50 }"
          >
            <p>{{ item.percentage < 50 ? item.percentage + "%" : "" }}</p>
          </div>
          <div
            class="in-yellow-results"
            :class="{ 'make-op': item.percentage > 60 || item.percentage < 50 }"
          >
            <p>
              {{
                item.percentage <= 60 && item.percentage >= 50
                  ? item.percentage + "%"
                  : ""
              }}
            </p>
          </div>
          <div
            class="in-green-results"
            :class="{ 'make-op': item.percentage < 61 }"
          >
            <p>{{ item.percentage > 60 ? item.percentage + "%" : "" }}</p>
          </div>
        </div>
        <button @click="item.isShowMore = !item.isShowMore">
          <i class="fa-solid fa-chevron-down" v-if="!item.isShowMore"></i>
          <i class="fa-solid fa-chevron-up" v-if="item.isShowMore"></i>
        </button>
        <!-- <button
          class="delete-mood"
          @click="toggleDeleteWarningHandler(item.id)"
        >
          <i class="fa-solid fa-trash"></i>
        </button> -->
      </div>
      <span v-if="item.isShowMore">
        <div class="quiz-results-ans">
          <p>Q1. How do you rate your mood?</p>
          <p class="margin--1"><b>Your answer: </b> {{ item.mood.label }}</p>
          <p class="margin--1"><b>Grade: </b> {{ item.mood.results }}%</p>
        </div>
        <div class="quiz-results-ans">
          <p>Q2. How about your energy level?</p>
          <p class="margin--1">
            <b>Your answer: </b> {{ item.energy_level.label }}
          </p>
          <p class="margin--1">
            <b>Grade: </b> {{ item.energy_level.results }}%
          </p>
        </div>
        <div class="quiz-results-ans">
          <p>Q3. How many hours did you sleep?</p>
          <p class="margin--1">
            <b>Your answer: </b> {{ item.sleep_duration.label }}
          </p>
          <p class="margin--1">
            <b>Grade: </b> {{ item.sleep_duration.results }}%
          </p>
        </div>
        <div class="quiz-results-ans">
          <p>Q4. How you done physical exercise well?</p>
          <p class="margin--1">
            <b>Your answer: </b> {{ item.exercise.label }}
          </p>
          <p class="margin--1"><b>Grade: </b> {{ item.exercise.results }}%</p>
        </div>
        <div class="quiz-results-ans">
          <p>Q5. Did you have meditation well?</p>
          <p class="margin--1">
            <b>Your answer: </b> {{ item.meditation.label }}
          </p>
          <p class="margin--1"><b>Grade: </b> {{ item.meditation.results }}%</p>
        </div>
        <div class="quiz-results-ans">
          <p>Q6. What about the food and drink did you have?</p>
          <p class="margin--1"><b>Your answer: </b> {{ item.food_drinks }}</p>
          <!-- <p class="margin--1"><b>Grade: </b>-</p> -->
        </div>
        <div class="quiz-results-ans">
          <p>Q7. Tell us more about mood or feelings?</p>
          <p class="margin--1"><b>Your answer: </b> {{ item.notes }}</p>
          <!-- <p class="margin--1"><b>Grade: </b>-</p> -->
        </div>
      </span>
    </div>
    <div
      style="width: 100%; padding-top: 20px"
      class="g-flex g-align-center g-content-center"
      v-if="isGettingPatientResults"
    >
      <table-data-loader></table-data-loader>
    </div>

    <div v-if="isToggleDeleteWarning">
      <warning-msg
        message="Are you sure you want to delete this mood result"
        :onClick="deleteMoodTrackerHandler"
        :onClosingModal="toggleDeleteWarningHandler"
        :isOnLoading="isDeletingMood"
      ></warning-msg>
    </div>

    <div v-if="isToggleCurrentResult">
      <modal :closeModal="toggleCurrentResultModal" width="450px">
        <template #content>
          <p style="margin-bottom: 14px"><label>MOOD RESULTS</label></p>
          <div class="g-flex quiz-results-div--3" v-if="resultsList.length">
            <div
              class="in-red-results make-round-div"
              :class="{ 'make-op': resultsList[0].percentage >= 50 }"
              v-if="resultsList[0].percentage <= 50"
            >
              <p class="current-results">
                {{
                  resultsList[0].percentage < 50
                    ? resultsList[0].percentage + "%"
                    : ""
                }}
              </p>
              <div>
                <label>Not Good</label>
              </div>
            </div>
            <div
              class="in-yellow-results make-round-div"
              :class="{
                'make-op':
                  resultsList[0].percentage > 60 ||
                  resultsList[0].percentage < 50,
              }"
              v-if="
                resultsList[0].percentage > 50 && resultsList[0].percentage < 60
              "
            >
              <p class="current-results">
                {{
                  resultsList[0].percentage <= 60 &&
                  resultsList[0].percentage >= 50
                    ? resultsList[0].percentage + "%"
                    : ""
                }}
              </p>
              <div>
                <label>Good</label>
              </div>
            </div>
            <div
              class="in-green-results make-round-div"
              :class="{ 'make-op': resultsList[0].percentage < 61 }"
              v-if="resultsList[0].percentage > 60"
            >
              <p class="current-results">
                {{
                  resultsList[0].percentage > 60
                    ? resultsList[0].percentage + "%"
                    : ""
                }}
              </p>
              <div>
                <label>Excellent</label>
              </div>
            </div>
            <div class="current-results-btn-div">
              <button
                @click="
                  isShowMoreOnCurrentresults = !isShowMoreOnCurrentresults
                "
              >
                <i
                  class="fa-solid fa-chevron-down"
                  v-if="!isShowMoreOnCurrentresults"
                ></i>
                <i
                  class="fa-solid fa-chevron-up"
                  v-if="isShowMoreOnCurrentresults"
                ></i>
              </button>
            </div>
          </div>

          <div style="margin-top: 20px" v-if="isShowMoreOnCurrentresults">
            <div class="quiz-results-ans">
              <p>Q1. How do you rate your mood?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].mood.label }}
              </p>
              <p class="margin--1">
                <b>Grade: </b> {{ resultsList[0].mood.results }}%
              </p>
            </div>
            <div class="quiz-results-ans">
              <p>Q2. How about your energy level?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].energy_level.label }}
              </p>
              <p class="margin--1">
                <b>Grade: </b> {{ resultsList[0].energy_level.results }}%
              </p>
            </div>
            <div class="quiz-results-ans">
              <p>Q3. How many hours did you sleep?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].sleep_duration.label }}
              </p>
              <p class="margin--1">
                <b>Grade: </b> {{ resultsList[0].sleep_duration.results }}%
              </p>
            </div>
            <div class="quiz-results-ans">
              <p>Q4. How you done physical exercise well?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].exercise.label }}
              </p>
              <p class="margin--1">
                <b>Grade: </b> {{ resultsList[0].exercise.results }}%
              </p>
            </div>
            <div class="quiz-results-ans">
              <p>Q5. Did you have meditation well?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].meditation.label }}
              </p>
              <p class="margin--1">
                <b>Grade: </b> {{ resultsList[0].meditation.results }}%
              </p>
            </div>
            <div class="quiz-results-ans">
              <p>Q6. What about the food and drink did you have?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].food_drinks }}
              </p>
              <!-- <p class="margin--1"><b>Grade: </b>-</p> -->
            </div>
            <div class="quiz-results-ans">
              <p>Q7. Tell us more about mood or feelings?</p>
              <p class="margin--1">
                <b>Your answer: </b> {{ resultsList[0].notes }}
              </p>
              <!-- <p class="margin--1"><b>Grade: </b>-</p> -->
            </div>
          </div>

          <div
            style="width: 100%; padding-top: 20px"
            class="g-flex g-align-center g-content-center"
            v-if="isGettingPatientResults"
          >
            <table-data-loader></table-data-loader>
          </div>
        </template>
      </modal>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import formatDateMixin from "@/mixins/formatDateMixin";
import TableDataLoader from "./reusable/TableDataLoader.vue";
import WarningMsg from "./reusable/WarningMsg.vue";
import Modal from "./reusable/Modal.vue";

export default {
  components: { TableDataLoader, WarningMsg, Modal },
  data() {
    return {
      resultsList: [],
      isGettingPatientResults: false,
      selectedMoodToDelete: null,
      isToggleDeleteWarning: false,
      isDeletingMood: false,
      isToggleCurrentResult: false,
      isShowMoreOnCurrentresults: false,
    };
  },
  mixins: [formatDateMixin],
  methods: {
    toggleCurrentResultModal() {
      sessionStorage.removeItem("quizIsDoneNow");
      this.isToggleCurrentResult = !this.isToggleCurrentResult;
    },

    toggleDeleteWarningHandler(item) {
      this.selectedMoodToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteMoodTrackerHandler() {
      this.isDeletingMood = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/moods/${this.selectedMoodToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.resultsList = this.resultsList.filter(
            (item) => item.id !== this.selectedMoodToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingMood = false;
          this.isToggleDeleteWarning = false;
        });
    },

    getPatientResultsHandler() {
      this.isGettingPatientResults = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/moods`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.resultsList = response.data.data.data.map((item) => ({
            ...item,
            isShowMore: false,
          }));
        })
        .catch((error) => {
          toast.error("Something went wrong! cannot get your results", {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$router.push("/my-info-view");
        })
        .finally(() => {
          this.isGettingPatientResults = false;
        });
    },
  },
  mounted() {
    if (sessionStorage.getItem("quizIsDoneNow")) {
      this.isToggleCurrentResult = true;
    }
  },
  created() {
    this.getPatientResultsHandler();
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.quiz-results-div--2 {
  width: 90%;
  align-items: center;
}
.quiz-results-div--1 button {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid #494949;
  margin-left: 20px;
  background: transparent;
}
.in-red-results,
.in-yellow-results,
.in-green-results {
  min-width: 100px;
  height: 20px;
  border-radius: 20px;
  flex: 1;
  color: white;
}
.in-red-results {
  background-color: #a60909;
}
.in-yellow-results {
  background-color: #b9960b;
}
.in-green-results {
  background-color: #5e8722;
}
.p-quiz-results {
  width: 50%;
  margin: 0 auto;
  padding-bottom: 20px;
}
.quiz-results-ans {
  text-align: left;
}
.margin--1 {
  margin-left: 30px;
}
.make-op {
  opacity: 0.4;
}
.delete-mood:hover {
  background-color: #a60909;
  border: 1px solid #a60909;
  color: white;
}
.make-round-div {
  width: 100% !important;
  height: 50px !important;
  padding: 20px 0 20px;
}
.current-results-btn-div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
}
.current-results-btn-div button {
  width: 30px;
  height: 30px;
  border-radius: 50px;
  border: 1px solid #494949;
  margin-bottom: 10px;
  margin-left: 20px;
  background: transparent;
}
.current-results {
  font-weight: 600;
  font-size: 24px;
}
</style>