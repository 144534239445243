<template>
  <div class="patient-quiz-cont">
    <div class="patient-quiz-qs" :class="{ 'change-border': formErrors.mood }">
      <p class="quiz-qn">Q1. How do you rate your mood?</p>
      <div class="g-flex">
        <label
          ><input
            type="radio"
            name="q1"
            @change="grabAnswer('mood', 'Happy')"
          />
          Happy</label
        >
        <label
          ><input
            type="radio"
            name="q1"
            @change="grabAnswer('mood', 'Neutral')"
          />
          Neutral</label
        >
        <label
          ><input type="radio" name="q1" @change="grabAnswer('mood', 'Sad')" />
          Sad</label
        >
        <label
          ><input
            type="radio"
            name="q1"
            @change="grabAnswer('mood', 'Anxious')"
          />
          Anxious</label
        >
        <label
          ><input
            type="radio"
            name="q1"
            @change="grabAnswer('mood', 'Angry')"
          />
          Angry</label
        >
      </div>
    </div>

    <div
      class="patient-quiz-qs"
      :class="{ 'change-border': formErrors.energy_level }"
    >
      <p class="quiz-qn">Q2. How about your energy level?</p>
      <div class="g-flex">
        <label
          ><input
            type="radio"
            name="q2"
            @change="grabAnswer('energy_level', 'High')"
          />
          High</label
        >
        <label
          ><input
            type="radio"
            name="q2"
            @change="grabAnswer('energy_level', 'Medium')"
          />
          Medium</label
        >
        <label
          ><input
            type="radio"
            name="q2"
            @change="grabAnswer('energy_level', 'Low')"
          />
          Low</label
        >
      </div>
    </div>

    <div
      class="patient-quiz-qs"
      :class="{ 'change-border': formErrors.sleep_duration }"
    >
      <p class="quiz-qn">Q3. How many hours did you sleep?</p>
      <div class="g-flex">
        <input
          type="number"
          placeholder="Hours Ex: 7"
          v-model="tracker.sleep_duration"
        />
      </div>
    </div>

    <div
      class="patient-quiz-qs"
      :class="{ 'change-border': formErrors.exercise }"
    >
      <p class="quiz-qn">Q4. How you done physical exercise well?</p>
      <div class="g-flex">
        <label
          ><input
            type="radio"
            name="q4"
            @change="grabAnswer('exercise', 'Yes')"
          />
          Yes</label
        >
        <label
          ><input
            type="radio"
            name="q4"
            @change="grabAnswer('exercise', 'No')"
          />
          No</label
        >
        <label
          ><input
            type="radio"
            name="q4"
            @change="grabAnswer('exercise', 'Partial')"
          />
          Partial</label
        >
      </div>
    </div>

    <div
      class="patient-quiz-qs"
      :class="{ 'change-border': formErrors.meditation }"
    >
      <p class="quiz-qn">Q5. Did you have meditation well?</p>
      <div class="g-flex">
        <label
          ><input
            type="radio"
            name="q5"
            @change="grabAnswer('meditation', 'Yes')"
          />
          Yes</label
        >
        <label
          ><input
            type="radio"
            name="q5"
            @change="grabAnswer('meditation', 'No')"
          />
          No</label
        >
        <label
          ><input
            type="radio"
            name="q5"
            @change="grabAnswer('meditation', 'Partial')"
          />
          Partial</label
        >
      </div>
    </div>

    <div
      class="patient-quiz-qs"
      :class="{ 'change-border': formErrors.food_drinks }"
    >
      <p class="quiz-qn">Q6. What about the food and drink did you have?</p>
      <div class="g-flex">
        <input
          type="text"
          placeholder="Food and drinks"
          v-model="tracker.food_drinks"
        />
      </div>
    </div>

    <div class="patient-quiz-qs" :class="{ 'change-border': formErrors.notes }">
      <p class="quiz-qn">Q7. Tell us more about mood or feelings?</p>
      <div class="g-flex">
        <textarea placeholder="Tell us more" v-model="tracker.notes"></textarea>
      </div>
    </div>

    <div class="submit-mood-btn">
      <base-button
        name="Submit"
        :onClick="submitPatientMoodTrackerHandler"
        :isLoading="isMakingChangeOnQuiz"
      ></base-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseButton from "./reusable/BaseButton.vue";

export default {
  components: { BaseButton },
  data() {
    return {
      tracker: {
        mood: "",
        energy_level: "",
        sleep_duration: "",
        exercise: "",
        meditation: "",
        food_drinks: "",
        notes: "",
      },
      currentQuestion: 1,
      isMakingChangeOnQuiz: false,
      formErrors: {},
    };
  },
  props: ["toogleSubmittedResults"],
  methods: {
    grabAnswer(mood, val) {
      this.tracker[mood] = val;
    },

    clearForm() {
      this.tracker.mood = "";
      this.tracker.energy_level = "";
      this.tracker.sleep_duration = "";
      this.tracker.exercise = "";
      this.tracker.meditation = "";
      this.tracker.food_drinks = "";
      this.tracker.notes = "";
    },

    validateForm() {
      this.formErrors = {};

      if (!this.tracker.mood) {
        this.formErrors.mood = true;
      }
      if (!this.tracker.energy_level) {
        this.formErrors.energy_level = true;
      }
      if (!this.tracker.sleep_duration) {
        this.formErrors.sleep_duration = true;
      }
      if (!this.tracker.exercise) {
        this.formErrors.exercise = true;
      }
      if (!this.tracker.meditation) {
        this.formErrors.meditation = true;
      }
      if (!this.tracker.food_drinks) {
        this.formErrors.food_drinks = true;
      }

      return (
        !this.formErrors.mood &&
        !this.formErrors.energy_level &&
        !this.formErrors.sleep_duration &&
        !this.formErrors.exercise &&
        !this.formErrors.meditation &&
        !this.formErrors.food_drinks
      );
    },

    submitPatientMoodTrackerHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnQuiz = true;

      let data = {
        mood: this.tracker.mood,
        energy_level: this.tracker.energy_level,
        sleep_duration: this.tracker.sleep_duration,
        exercise: this.tracker.exercise,
        meditation: this.tracker.meditation,
        food_drinks: this.tracker.food_drinks,
        notes: this.tracker.notes,
      };
      // if (this.tracker.notes) data.notes = this.tracker.notes;

      axios
        .post(`${this.$store.state.apiUrl}/v1/track-mood`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.toogleSubmittedResults();
          sessionStorage.setItem("quizIsDoneNow", "true");
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnQuiz = false;
        });
    },
  },
  mounted() {
    if (sessionStorage.getItem("quizIsDoneNow")) {
      sessionStorage.removeItem("quizIsDoneNow");
    }
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.patient-quiz-qs {
  width: 80%;
  margin: 0 auto 20px;
  border: 1px solid #bababa;
  padding: 20px;
}
.change-border {
  border: 1px solid red !important;
}
.quiz-qn {
  font-weight: 500;
  margin-bottom: 10px;
}
input[type="radio"] {
  width: 20px !important;
  height: 20px !important;
  margin-bottom: 6px;
}
textarea {
  padding: 6px 10px;
}
label {
  display: flex;
  align-items: center;
  font-weight: 300;
  margin-right: 20px;
}
.submit-mood-btn {
  width: 130px;
  margin-left: calc(10% - 20px);
  padding: 10px 0 30px;
}
</style>