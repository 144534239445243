<template>
  <div class="sidebar-link">
    <template v-for="(item, index) in sidebarLinks" :key="index">
      <router-link :to="item.to" @click="makeLinkActive(item)">
        <div
          class="sidebar-link-div g-flex g-align-center"
          :class="{ 'is-active': item.isActive }"
          v-if="roleAllowedChecker(item.roles)"
        >
          <i :class="item.icon"></i>
          <span>{{ item.name }}</span>
        </div>
      </router-link>
    </template>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      sidebarLinks: [
        {
          name: "Dashboard",
          to: "/admin-analytics",
          icon: "fa-solid fa-gauge",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Companies (Clients)",
          to: "/companies",
          icon: "fa-solid fa-users",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Packages",
          to: "/packages",
          icon: "fa-solid fa-cube",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Agents",
          to: "/agents",
          icon: "fa-solid fa-users",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Therapists",
          to: "/therapists",
          icon: "fa-solid fa-user-doctor",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Payments",
          to: "/all-payments",
          icon: "fa-solid fa-money-bill",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Subscriptions",
          to: "/all-subscriptions",
          icon: "fa-solid fa-star",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Call sessions",
          to: "/admin-view-call-sessions",
          icon: "fa-solid fa-phone-volume",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Consultations",
          to: "/admin-view-consultations",
          icon: "fa-solid fa-stethoscope",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Extended subscriptions",
          to: "/all-extended-subscriptions",
          icon: "fa-solid fa-bolt",
          roles: ["Uhuru"],
          isActive: false,
        },
        {
          name: "Dashboard",
          to: "/company-analytics",
          icon: "fa-solid fa-gauge",
          roles: ["Client"],
          isActive: false,
        },
        {
          name: "Employees",
          to: "/employees",
          icon: "fa-solid fa-users",
          roles: ["Client"],
          isActive: false,
        },
        {
          name: "Company profile",
          to: "/company-profile",
          icon: "fa-solid fa-address-card",
          roles: ["Client"],
          isActive: false,
        },
        {
          name: "Subscriptions",
          to: "/my-company-subscriptions",
          icon: "fa-solid fa-star",
          roles: ["Client"],
          isActive: false,
        },
        {
          name: "Modify subscriptions",
          to: "/extend-my-subscriptions",
          icon: "fa-solid fa-bolt",
          roles: ["Client"],
          isActive: false,
        },
        {
          name: "Payments",
          to: "/my-company-payments",
          icon: "fa-solid fa-money-bill",
          roles: ["Client"],
          isActive: false,
        },
        {
          name: "Dashboard",
          to: "/agent-analytics",
          icon: "fa-solid fa-gauge",
          roles: ["Super Agent"],
          isActive: false,
        },
        {
          name: "Companies (Clients)",
          to: "/agent-companies",
          icon: "fa-solid fa-users",
          roles: ["Super Agent"],
          isActive: false,
        },
        {
          name: "Therapists",
          to: "/agent-therapists",
          icon: "fa-solid fa-user-doctor",
          roles: ["Super Agent"],
          isActive: false,
        },
        {
          name: "Call sessions",
          to: "/agent-call-sessions",
          icon: "fa-solid fa-phone-volume",
          roles: ["Super Agent"],
          isActive: false,
        },
        {
          name: "Search Employee",
          to: "/agent-manage-employees",
          icon: "fa-solid fa-magnifying-glass",
          roles: ["Super Agent"],
          isActive: false,
        },
      ],
      currentRoutePath: this.$route.path,
    };
  },
  computed: {
    ...mapState(["authUser"]),
  },
  methods: {
    roleAllowedChecker(value) {
      return value.includes(this.authUser.role);
    },
    makeLinkActive() {
      this.sidebarLinks = this.sidebarLinks.map((item) => {
        if (item.to === this.currentRoutePath) {
          return {
            ...item,
            isActive: true,
          };
        } else {
          return {
            ...item,
            isActive: false,
          };
        }
      });
    },
  },
  created() {
    if (this.currentRoutePath) {
      this.makeLinkActive();
    }
  },
};
</script>

<style scoped>
.sidebar-link {
  margin-top: 20px;
}
.sidebar-link div {
  padding: 10px 0 10px 26px;
}
.sidebar-link a div:hover {
  background-color: var(--third-main-color);
}
.is-active {
  background-color: var(--third-main-color);
}
.sidebar-link a {
  text-decoration: none;
  color: black;
}
.sidebar-link a:hover {
  color: var(--primary-color);
}
.sidebar-link a span {
  margin-left: 20px;
}
</style>