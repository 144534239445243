<template>
  <div>
    <main-header></main-header>
    <div class="my-info g-flex" v-if="!isGettingMyInfo">
      <div class="my-info-left">
        <div class="my-info-header"><P>ACCOUNT</P></div>
        <div class="my-inf-lef-btn-div">
          <button
            :class="{
              'info-btn-active':
                patientInfoActiveTab == 'personal_info' ||
                patientInfoActiveTab == 'user_profile',
            }"
            @click="togglePatientComponent('personal_info')"
          >
            Personal Info
          </button>
        </div>

        <!-- <div class="my-inf-lef-btn-div">
          <button
            :class="{ 'info-btn-active': patientInfoActiveTab == 'user_profile' }"
            @click="togglePatientComponent('user_profile')"
          >
            Your Profile
          </button>
        </div> -->

        <div class="my-inf-lef-btn-div">
          <button
            :class="{
              'info-btn-active': patientInfoActiveTab == 'contanct_person',
            }"
            @click="togglePatientComponent('contanct_person')"
          >
            Contact Persons
          </button>
        </div>

        <div class="my-inf-lef-btn-div">
          <button
            :class="{
              'info-btn-active': patientInfoActiveTab == 'change_passord',
            }"
            @click="togglePatientComponent('change_passord')"
          >
            Login Info
          </button>
        </div>
      </div>
      <div
        class="my-info-right"
        v-if="patientDetails && patientInfoActiveTab == 'personal_info'"
      >
        <personal-info
          :patientDetails="patientDetails"
          :togglePatientComponent="togglePatientComponent"
        ></personal-info>
      </div>
      <div class="my-info-right" v-if="patientInfoActiveTab == 'user_profile'">
        <patient-profile-form
          :getPatientDetailsHandler="getPatientDetailsHandler"
          :patientDetails="patientDetails"
        ></patient-profile-form>
      </div>
      <div
        class="my-info-right"
        v-if="patientInfoActiveTab == 'contanct_person'"
      >
        <patient-contact-person></patient-contact-person>
      </div>
      <div
        class="my-info-right"
        v-if="patientInfoActiveTab == 'change_passord'"
      >
        <change-user-profile></change-user-profile>
      </div>
    </div>
    <div
      style="width: 100%; margin-top: 40px"
      class="g-flex g-align-center g-content-center"
      v-if="isGettingMyInfo"
    >
      <table-data-loader></table-data-loader>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import MainHeader from "@/components/MainHeader.vue";
import PersonalInfo from "@/components/PersonalInfo.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import PatientProfileForm from "@/components/PatientProfileForm.vue";
import UserProfileView from "./UserProfileView.vue";
import ChangeUserProfile from "@/components/ChangeUserProfile.vue";
import PatientContactPerson from "@/components/PatientContactPerson.vue";

import { mapState } from "vuex";

export default {
  components: {
    MainHeader,
    PersonalInfo,
    TableDataLoader,
    PatientProfileForm,
    UserProfileView,
    ChangeUserProfile,
    PatientContactPerson,
  },
  data() {
    return {
      isGettingMyInfo: false,
      patientDetails: null,
    };
  },
  computed: {
    ...mapState(["patientInfoActiveTab"]),
  },
  methods: {
    togglePatientComponent(data) {
      this.$store.commit("setPatientInfoActiveTab", data);
    },
    getPatientDetailsHandler() {
      this.isGettingMyInfo = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/patients`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.patientDetails = response.data.data;
          this.$store.commit("setPatientInfoActiveTab", "personal_info");
        })
        .catch((error) => {
          toast.error("You need to fill profile form to be able to procced", {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$store.commit("setPatientInfoActiveTab", "user_profile");
        })
        .finally(() => {
          this.isGettingMyInfo = false;
        });
    },
  },
  created() {
    this.getPatientDetailsHandler();
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.my-info {
  width: 80%;
  margin: 40px auto 20px;
}
.my-info-left {
  width: 250px;
  height: fit-content;
  background: white;
  border-radius: 5px;
  padding-bottom: 4px;
}
.my-info-header {
  background: var(--primary-color);
  color: white;
  font-weight: 600;
  padding: 6px 10px;
  border-radius: 5px 5px 0 0;
}
.my-info-left button {
  width: 100%;
  height: 30px;
  background: transparent;
  border: none;
  border-bottom: 1px solid #dddddd;
  text-align: left;
}
.my-info-left button:last-child {
  border-bottom: none;
  border: 0 0 5px 5px;
}
.my-info-left button:hover {
  background: var(--third-main-color);
  border-left: 1px solid white;
  border-right: 1px solid white;
}
.info-btn-active {
  background: var(--third-main-color) !important;
  border-left: 1px solid white !important;
  border-right: 1px solid white !important;
}
.my-info-right {
  margin-left: 40px;
  background: white;
  flex: 1;
  border-radius: 10px;
  padding: 20px;
}
</style>