<template>
  <base-layout>
    <template #content>
      <div class="companies-header g-flex g-align-center g-space-btw">
        <h3>List of Companies</h3>

        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchCompanies"
            @keyup.enter="searchCompaniesHandler"
          />
          <button @click="searchCompaniesHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

        <div style="width: 130px">
          <base-button
            name="Add new"
            :onClick="toggleAddNewCompanyModal"
          ></base-button>
        </div>
      </div>
      <div style="padding: 0 10px" class="border--1">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>TIN number</th>
              <th>Website</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="companiesList.length && !isGettingCompanies">
            <tr v-for="(item, index) in companiesList" :key="item.id">
              <td class="g-flex g-align-center">
                <div style="width: 38px; margin-right: 10px">
                  <user-circle
                    :currentIndex="index"
                    :currentUser="item.client_name"
                    marginRight="0"
                  ></user-circle>
                </div>
                {{ item.client_name }}
              </td>
              <td>{{ item.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.tin_number }}</td>
              <td>{{ item.website }}</td>
              <td
                :class="
                  item.status !== 'Active' ? 'red-status ' : 'green-status'
                "
              >
                {{ item.status }}
              </td>

              <td class="td-action">
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      @click="redirectToCompanyDetail(item.id)"
                    >
                      Details
                    </button>
                    <button
                      class="action-btn-item"
                      @click="toggleUpdateCompany(item)"
                    >
                      Edit
                    </button>
                    <!-- <button
                      class="action-btn-item"
                      v-if="item.status == 'Pending'"
                      @click="toggleApproveCompany(item.id)"
                    >
                      Approve
                    </button> -->
                    <button
                      class="action-btn-item"
                      @click="toggleDeleteWarningHandler(item.id)"
                    >
                      Delete
                    </button>
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingCompanies && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getCompaniesList"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingCompanies"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>
      <div v-if="isToggleAddNewCompany">
        <modal :closeModal="toggleAddNewCompanyModal" width="800px">
          <template #content>
            <company-form
              :returnOnSuccess="updateWithAddedCompany"
              :companyToUpdate="companyToUpdate"
            ></company-form>
          </template>
        </modal>
      </div>
      <div v-if="isToggleDeleteWarning">
        <warning-msg
          message="Are you sure you want to delete this company"
          :onClick="deleteCompanyHandler"
          :onClosingModal="toggleDeleteWarningHandler"
          :isOnLoading="isDeletingCompany"
        ></warning-msg>
      </div>
      <!-- <div v-if="isApproveCompany">
        <modal :closeModal="toggleApproveCompany" width="350px">
          <template #content>
            <approve-company
              :companyToApprove="companyToApprove"
              :returnApprovedCompany="returnApprovedCompany"
            ></approve-company>
          </template>
        </modal>
      </div> -->
    </template>
  </base-layout>
</template>
    
<script>
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import CompanyForm from "@/components/CompanyForm.vue";
import ApproveCompany from "@/components/ApproveCompany.vue";

import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    UserCircle,
    ActionsBtn,
    WarningMsg,
    CompanyForm,
    ApproveCompany,
  },
  data() {
    return {
      isToggleAddNewCompany: false,
      isGettingCompanies: false,
      companiesList: [],
      isDeletingCompany: false,
      selectedCompanyToDelete: null,
      isToggleDeleteWarning: false,
      isApproveCompany: false,
      companyToApprove: null,
      companyToUpdate: null,

      searchCompanies: "",
      isOnSearchingCompanies: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    searchCompaniesHandler() {
      if (!this.searchCompanies) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }

      this.isOnSearchingCompanies = true;
      this.getCompaniesList();
    },

    toggleAddNewCompanyModal() {
      this.companyToUpdate = null;
      this.isToggleAddNewCompany = !this.isToggleAddNewCompany;
    },

    updateWithAddedCompany(data) {
      if (this.companyToUpdate) {
        this.companiesList = this.companiesList.map((item) => {
          if (item.id == this.companyToUpdate.id) {
            return data;
          }
          return item;
        });
      } else {
        this.companiesList.push(data);
      }
      this.toggleAddNewCompanyModal();
    },

    getCompaniesList(page) {
      this.isGettingCompanies = true;
      const actionUrl = this.isOnSearchingCompanies
        ? `${this.$store.state.apiUrl}/v1/uhuru/clients?search=${this.searchCompanies}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/uhuru/clients?page=${page}`;
      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.companiesList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingCompanies = false;
          this.isOnSearchingCompanies = false;
        });
    },

    toggleDeleteWarningHandler(item) {
      this.selectedCompanyToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteCompanyHandler() {
      this.isDeletingCompany = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.selectedCompanyToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.companiesList = this.companiesList.filter(
            (item) => item.id !== this.selectedCompanyToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingCompany = false;
          this.toggleDeleteWarningHandler();
        });
    },

    // toggleApproveCompany(id) {
    //   this.companyToApprove = id;
    //   this.isApproveCompany = !this.isApproveCompany;
    // },

    returnApprovedCompany(id, data) {
      const companyIndex = this.companiesList.findIndex((obj) => obj.id === id);
      this.companiesList[companyIndex].status = data.status;
      this.isApproveCompany = false;
    },

    toggleUpdateCompany(item) {
      this.companyToUpdate = item;
      this.isToggleAddNewCompany = !this.isToggleAddNewCompany;
    },

    redirectToCompanyDetail(id) {
      this.$router.push("/company-details/" + id);
    },
  },
  created() {
    this.getCompaniesList();
  },
};
</script>
    
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.companies-header {
  margin: 20px;
}
.companies-header input {
  margin-bottom: 0 !important;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}
.g-filter-cont select {
  border: none !important;
  width: 120px;
  /* background: #f5f5f5; */
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-companies-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
.border--1 {
  border: 1px solid #dddddd;
}
</style>