<template>
  <div class="employee-header g-flex g-align-center g-space-btw">
    <h3>Company payments</h3>
    <div class="g-flex g-align-center">
      <label>From</label>
      <input type="date" v-model="paymentsFrom" @input="searchByRangeOfDates" />
      <label style="margin-left: 6px">To</label>
      <input type="date" v-model="paymentsTo" @input="searchByRangeOfDates" />
    </div>
    <div style="width: 130px">
      <base-button
        name="Add new"
        @click="toggleAddCompanyPayment"
      ></base-button>
    </div>
  </div>
  <div style="padding: 0 10px">
    <table>
      <thead>
        <tr>
          <th>Paid date</th>
          <th>Paid amount</th>
          <th>Payment mode</th>
          <th>Reference number</th>
          <th>Payment month</th>
          <th>Status</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody v-if="paymentsList.length && !isGettingPayments">
        <tr v-for="item in paymentsList" :key="item.id">
          <td class="g-flex g-align-center">
            {{ formatDate(item.payment_date) }}
          </td>
          <td>{{ formatMoney(item.paid_amount) }}</td>
          <td>{{ item.payment_mode }}</td>
          <td>{{ item.reference_number || "-" }}</td>
          <td>
            {{ item.payment_month }}
          </td>
          <td
            :class="item.status == 'Approved' ? 'green-status' : 'red-status'"
          >
            {{ item.status }}
          </td>
          <td>
            <actions-btn>
              <template #content>
                <button
                  class="action-btn-item"
                  v-if="item.notes"
                  @click="toggleViewNoteHandler(item.notes)"
                >
                  View Note
                </button>
                <a target="_blank" :href="item.proof" v-if="item.proof">
                  <button class="action-btn-item">View Proof</button>
                </a>
                <button
                  class="action-btn-item"
                  v-if="item.status !== 'Approved'"
                  :disabled="isChangingPaymentStatus"
                  @click="toggleChangePaymentStatus(item.id, 'Approved')"
                >
                  Approve
                </button>
                <button
                  class="action-btn-item"
                  v-if="item.status == 'Approved'"
                  :disabled="isChangingPaymentStatus"
                  @click="toggleChangePaymentStatus(item.id, 'Denied')"
                >
                  Disapprove
                </button>
                <button
                  class="action-btn-item"
                  @click="toggleDeletePayment(item.id)"
                >
                  Delete
                </button>
              </template>
            </actions-btn>
          </td>
        </tr>
      </tbody>
    </table>

    <div class="uhuru-pg-container" v-if="!isGettingPayments && hasNextPage">
      <vue-awesome-paginate
        :total-items="totalPgItems"
        :items-per-page="10"
        :max-pages-shown="8"
        v-model="currentPage"
        paginate-buttons-class="pg-btn"
        active-page-class="pg-btn-active"
        back-button-class="pg-back-btn"
        next-button-class="pg-next-btn"
        prev-button-content="Prev"
        next-button-content="Next"
        :on-click="getCompanyPaymentsHandler"
      />
    </div>

    <div
      style="width: 100%; margin: 10px"
      class="g-flex g-align-center g-content-center"
      v-if="isGettingPayments"
    >
      <table-data-loader></table-data-loader>
    </div>

    <div v-if="isToggleAddCompanyPayment">
      <modal :closeModal="toggleAddCompanyPayment" width="350px">
        <template #content>
          <div>
            <h3 class="heading--1">Add New Payment</h3>
          </div>
          <label>Paid Date</label>
          <input type="date" placeholder="" v-model="payment_date" />
          <p
            v-if="formErrors.payment_date && !payment_date"
            class="form-errors"
          >
            Paid date is required
          </p>

          <label>Amount</label>
          <input
            type="number"
            placeholder="Enter amount"
            readonly
            v-model="paid_amount"
          />
          <p v-if="formErrors.paid_amount && !paid_amount" class="form-errors">
            Amount paid is required
          </p>

          <label>Payment Mode</label>
          <select v-model="payment_mode">
            <option value="">-----Select option-----</option>
            <option :value="item" v-for="item in paymentModes" :key="item">
              {{ item }}
            </option>
          </select>
          <p
            v-if="formErrors.payment_mode && !payment_mode"
            class="form-errors"
          >
            Select payment mode please
          </p>

          <label>Reference Number</label>
          <input
            type="text"
            placeholder="Enter reference number"
            v-model="reference_number"
          />

          <label>Small Note</label>
          <textarea v-model="notes" placeholder="Write small note"></textarea>
          <p v-if="formErrors.notes && !notes" class="form-errors">
            Small note is required
          </p>

          <label>Proof File</label>
          <input type="file" placeholder="" @change="onProofFileAdded" />

          <div style="width: 150px; margin-bottom: 10px">
            <base-button
              name="Add Payment"
              :isLoading="isMakingChangeOnPayment"
              :onClick="addNewCompanyPaymentHandler"
            ></base-button>
          </div>
        </template>
      </modal>
    </div>

    <div v-if="isToggleViewNote">
      <modal :closeModal="toggleViewNoteHandler" width="350px">
        <template #content>
          <p style="margin-bottom: 14px"><label>PAYMENT NOTE</label></p>
          <p>{{ selectedNotes }}</p>
        </template>
      </modal>
    </div>

    <div v-if="isChangePaymentStatus">
      <warning-msg
        message="Are you sure you want to change status of this payment"
        :onClosingModal="toggleDeletePayment"
        :onClick="approveDisapprovePayment"
        :isOnLoading="isChangingPaymentStatus"
      ></warning-msg>
    </div>

    <div v-if="hasDeletePaymentToggled">
      <warning-msg
        message="Are you sure you want to delete this payment"
        :onClosingModal="toggleDeletePayment"
        :onClick="deletePaymentHandler"
        :isOnLoading="isDeletingPayment"
      ></warning-msg>
    </div>
  </div>
</template>
    
<script>
import axios from "axios";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import formatMoney from "@/mixins/formatMoney";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    UserCircle,
    ActionsBtn,
    WarningMsg,
  },
  data() {
    return {
      isGettingPayments: false,
      paymentsList: [],
      clientId: this.$route.params.id,
      isToggleAddCompanyPayment: false,

      activeSucription: "",
      isMakingChangeOnPayment: false,
      // payment form
      paid_amount: "",
      payment_date: "",
      payment_mode: "",
      reference_number: "",
      payment_month: "",
      notes: "",
      proof: "",
      formErrors: {},

      paymentModes: null,
      selectedNotes: "",
      isToggleViewNote: false,
      isChangePaymentStatus: false,
      isChangingPaymentStatus: false,
      paymentToChange: "",
      newPaymentStatus: "",

      hasDeletePaymentToggled: false,
      paymentToDelete: "",
      isDeletingPayment: false,

      paymentsFrom: "",
      paymentsTo: this.formatDate(new Date()),
      isOnSearchingPayments: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin, formatMoney],
  computed: {},
  props: {
    allCompanyDetails: {
      type: Object,
    },
  },
  methods: {
    searchByRangeOfDates() {
      if (!this.paymentsFrom) return;
      if (!this.paymentsFrom || !this.paymentsTo) {
        return toast.error("Please select range of date", {
          theme: "colored",
        });
      }
      this.isOnSearchingPayments = true;
      this.getCompanyPaymentsHandler();
    },

    toggleViewNoteHandler(note) {
      this.selectedNotes = note;
      this.isToggleViewNote = !this.isToggleViewNote;
    },

    toggleDeletePayment(id) {
      this.paymentToDelete = id;
      this.hasDeletePaymentToggled = !this.hasDeletePaymentToggled;
    },

    deletePaymentHandler() {
      this.isDeletingPayment = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/payments/${this.paymentToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.paymentsList = this.paymentsList.filter(
            (item) => item.id !== this.paymentToDelete
          );
          this.hasDeletePaymentToggled = !this.hasDeletePaymentToggled;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingPayment = false;
        });
    },

    toggleAddCompanyPayment() {
      this.isToggleAddCompanyPayment = !this.isToggleAddCompanyPayment;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.paid_amount) {
        this.formErrors.paid_amount = true;
      }
      if (!this.payment_date) {
        this.formErrors.payment_date = true;
      }
      if (!this.payment_mode) {
        this.formErrors.payment_mode = true;
      }

      return (
        !this.formErrors.paid_amount &&
        !this.formErrors.payment_date &&
        !this.formErrors.payment_mode
      );
    },

    onProofFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.proof = files[0];
    },

    addNewCompanyPaymentHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isMakingChangeOnPayment = true;

      let data = new FormData();

      data.append("paid_amount", this.paid_amount);
      data.append("payment_date", this.payment_date);
      data.append("payment_mode", this.payment_mode);
      data.append("reference_number", this.reference_number);
      data.append("notes", this.notes);

      if (typeof this.proof === "object") {
        data.append("proof", this.proof);
      }

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.activeSucription.id}/add-payment`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.paymentsList.push(response.data.data);
          this.isToggleAddCompanyPayment = false;
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnPayment = false;
        });
    },

    toggleChangePaymentStatus(id, status) {
      this.isChangePaymentStatus = !this.isChangePaymentStatus;
      this.paymentToChange = id;
      this.newPaymentStatus = status;
    },

    approveDisapprovePayment() {
      this.isChangingPaymentStatus = true;
      let data = {
        status: this.newPaymentStatus,
        _method: "PUT",
      };
      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/payments/${this.paymentToChange}/approve`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.paymentsList = this.paymentsList.map((item) => {
            if (item.id == this.paymentToChange) {
              return response.data.data;
            } else {
              return item;
            }
          });
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.isChangePaymentStatus = false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isChangingPaymentStatus = false;
        });
    },

    getCompanyPaymentsHandler(page) {
      this.isGettingPayments = true;
      const actionUrl = this.isOnSearchingPayments
        ? `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/payments?range_from=${this.paymentsFrom}&range_until=${this.paymentsTo}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/payments?page=${page}`;

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.paymentsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPayments = false;
          this.isOnSearchingPayments = false;
        });
    },

    getPaymentModes() {
      axios
        .get(`${this.$store.state.apiUrl}/v1/payments-mode`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          this.paymentModes = res.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {});
    },
  },
  created() {
    this.getCompanyPaymentsHandler();
    this.activeSucription = this.allCompanyDetails.subscriptions.find(
      (item) => (item.status = "Active")
    );
    if (this.activeSucription) {
      if (this.activeSucription.payment_type == "Monthly") {
        this.paid_amount =
          this.allCompanyDetails.monthly_payment * this.activeSucription.slots;
      } else {
        this.paid_amount = this.activeSucription.total_amount;
      }
    }

    this.getPaymentModes();
  },
};
</script>
    
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.heading--1 {
  margin: 10px 0;
  font-weight: 600;
  text-transform: uppercase;
}
.employee-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-employee-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input,
textarea,
select {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
</style>