<template>
  <div v-if="allDetails">
    <h3>Company Details Overview</h3>
    <div class="g-flex" style="width: 100%">
      <div style="width: 30%">
        <p><b>Name: </b>{{ allDetails.client_name }}</p>
        <p><b>Code: </b>{{ allDetails.client_code }}</p>
        <p><b>Email: </b>{{ allDetails.email }}</p>
        <p><b>Phone: </b>{{ allDetails.phone }}</p>
        <p><b>TIN number: </b>{{ allDetails.tin_number }}</p>
        <p><b>status: </b>{{ allDetails.status }}</p>
        <p><b>Payment type: </b>{{ allDetails.payment_type }}</p>
      </div>
      <div style="margin-left: 40px">
        <p><b>Package consumers: </b>{{ allDetails.slots }}</p>
        <p><b>Website: </b>{{ allDetails.website }}</p>
        <p><b>Name a person to contact: </b>{{ allDetails.contact_person }}</p>
        <p>
          <b>Phone a person to contact: </b
          >{{ allDetails.contact_person_phone }}
        </p>
        <p>
          <b>Email a person to contact: </b
          >{{ allDetails.contact_person_email }}
        </p>
        <p>
          <b>Post a person to contact: </b>{{ allDetails.contact_person_title }}
        </p>
        <p><b>Starting date: </b>{{ formatDate(allDetails.starting_date) }}</p>
      </div>
    </div>
    <div>
      <div
        style="width: 200px; margin: 20px 0"
        v-if="allDetails.registration_certificate"
      >
        <a target="_blank" :href="allDetails.registration_certificate">
          <base-button name="View RDB Cert"></base-button>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "@/mixins/formatDateMixin";
import BaseButton from "./reusable/BaseButton.vue";
export default {
  components: { BaseButton },
  data() {
    return {};
  },
  props: ["allDetails"],
  mixins: [formatDateMixin],
  methods: {},
};
</script>

<style scoped>
a {
  text-decoration: none;
}
</style>