<template>
  <div>
    <div>
      <p class="single-call-header"><b>CALL SESSION NOTES</b></p>
      <table>
        <thead>
          <tr>
            <th>Notes</th>
            <th>Recorded_on</th>
          </tr>
        </thead>
        <tbody v-if="callSessionNotes.length && !isGettingMoreOnCallSession">
          <tr v-for="item in callSessionNotes" :key="item.id">
            <td v-html="item.notes"></td>
            <td>{{ formatDate(item.created_at) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div
      style="width: 100%"
      class="g-flex g-content-center"
      v-if="isGettingMoreOnCallSession"
    >
      <table-data-loader></table-data-loader>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "@/mixins/formatDateMixin";
import TableDataLoader from "./reusable/TableDataLoader.vue";
import ActionsBtn from "./reusable/ActionsBtn.vue";
export default {
  components: { TableDataLoader, ActionsBtn },
  props: ["callSessionNotes", "isGettingMoreOnCallSession"],
  mixins: [formatDateMixin],
};
</script>

<style scoped>
p {
  margin: 0;
}
.single-call-header {
  margin: 10px 0;
  text-decoration: underline;
}
</style>