<template>
  <div>
    <main-header></main-header>
    <div class="consult-cont">
      <h3 class="heading--1">ADD CONSULTATIONS</h3>

      <div class="consult-body">
        <div style="margin: 20px">
          <label>Current problem</label>
          <div style="height: 320px">
            <editor v-model="current_problem" :init="editorConfig"></editor>
          </div>
          <p
            v-if="formErrors.current_problem && !current_problem"
            class="form-errors"
          >
            Current problem is a required
          </p>

          <label>Examination of mental state</label>
          <div style="height: 320px">
            <editor
              v-model="examination_of_mental_state"
              :init="editorConfig"
            ></editor>
          </div>
          <p
            v-if="
              formErrors.examination_of_mental_state &&
              !examination_of_mental_state
            "
            class="form-errors"
          >
            Examination of mental state is required
          </p>

          <label>Treatment plan</label>
          <div style="height: 320px">
            <editor v-model="treatment_plan" :init="editorConfig"></editor>
          </div>
          <p
            v-if="formErrors.treatment_plan && !treatment_plan"
            class="form-errors"
          >
            Treatment plan is required
          </p>

          <div class="g-flex">
            <div>
              <label>Consultation date</label>
              <input type="date" v-model="date_of_visit" />
              <p
                v-if="formErrors.date_of_visit && !date_of_visit"
                class="form-errors"
              >
                Consultation date is required
              </p>
            </div>
            <div style="margin: 0 10px">
              <label>Consultation form</label>
              <input type="file" @change="onConsultaionFormFile" />
              <p
                v-if="formErrors.consultation_form && !consultation_form"
                class="form-errors"
              >
                Consultation form is required
              </p>
            </div>
          </div>
          <div style="width: 130px; margin: 20px 0 0">
            <base-button
              name="Submit"
              :isLoading="isMakingChangeOnConsultation"
              :onClick="addNewConsultation"
            ></base-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";

import MainHeader from "@/components/MainHeader.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import SecondaryButton from "@/components/reusable/SecondaryButton.vue";
import Editor from "@tinymce/tinymce-vue";

export default {
  components: {
    MainHeader,
    BaseButton,
    TableDataLoader,
    SecondaryButton,
    Editor,
  },
  data() {
    return {
      isGettingConsultations: false,
      isGettingSingleConsultation: false,
      singlePatientDetails: null,
      activePatient: "",

      formErrors: {},
      current_problem: "",
      examination_of_mental_state: "",
      treatment_plan: "",
      consultation_form: "",
      date_of_visit: "",
      isMakingChangeOnConsultation: false,

      selectedConsultationToEdit: "",
      patientId: this.$route.params.id,

      content: "",
      editorConfig: {
        height: "300px",
        max_height: 300,
        min_height: 300,
        menubar: true,
        modules: {
          plugins: [
            "advlist autolink lists link image charmap print preview anchor",
            "searchreplace visualblocks code fullscreen",
            "insertdatetime media table paste code help wordcount",
          ],
          toolbar:
            "undo redo | formatselect | bold italic backcolor | \
            alignleft aligncenter alignright alignjustify | \
            bullist numlist outdent indent | removeformat | help | \
            heading1 heading2 heading3 | blockquote",
          content_style:
            "body { font-family: Arial, Helvetica, sans-serif; font-size: 14px; }",
        },
      },
    };
  },
  methods: {
    validateForm() {
      this.formErrors = {};

      if (!this.current_problem) {
        this.formErrors.current_problem = true;
      }
      if (!this.examination_of_mental_state) {
        this.formErrors.examination_of_mental_state = true;
      }
      if (!this.treatment_plan) {
        this.formErrors.treatment_plan = true;
      }
      if (!this.consultation_form) {
        this.formErrors.consultation_form = true;
      }
      if (!this.date_of_visit) {
        this.formErrors.date_of_visit = true;
      }

      return (
        !this.formErrors.current_problem &&
        !this.formErrors.examination_of_mental_state &&
        !this.formErrors.treatment_plan &&
        !this.formErrors.consultation_form &&
        !this.formErrors.date_of_visit
      );
    },

    onConsultaionFormFile(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.consultation_form = files[0];
    },

    addNewConsultation() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnConsultation = true;

      let data = new FormData();

      if (typeof this.consultation_form === "object") {
        data.append("consultation_form", this.consultation_form);
      }
      if (!this.selectedConsultationToEdit)
        data.append("patient", this.patientId);
      data.append("current_problem", this.current_problem);
      data.append(
        "examination_of_mental_state",
        this.examination_of_mental_state
      );
      data.append("date_of_visit", this.date_of_visit);
      data.append("treatment_plan", this.treatment_plan);

      if (this.selectedConsultationToEdit) data.append("_method", "PUT");

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/therapists/consultations${
            this.selectedConsultationToEdit
              ? "/" + this.selectedConsultationToEdit
              : ""
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$router.push("/patient-consultations");
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnConsultation = false;
        });
    },

    getSingleConsultationDetails(employee_id) {
      this.isGettingSingleConsultation = true;
      this.activePatient = employee_id;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/therapists/patients/${employee_id}/details`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.singlePatientDetails = response.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSingleConsultation = false;
        });
    },

    isReadyForEditChecker() {
      const consultToEdit = sessionStorage.getItem("editConsultation");
      if (consultToEdit) {
        const consult = JSON.parse(consultToEdit);
        this.selectedConsultationToEdit = consult.id;
        this.current_problem = consult.current_problem;
        this.examination_of_mental_state = consult.examination_of_mental_state;
        this.treatment_plan = consult.treatment_plan;
        this.consultation_form = consult.consultation_form;
        this.date_of_visit = consult.date_of_visit;
      }
    },
  },
  created() {
    this.isReadyForEditChecker();
  },
};
</script>
  
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.consult-cont {
  width: 60%;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.consult-body {
  border-top: 0.5px solid #a8a8a8;
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none;
}
</style>