<template>
  <div>
    <div class="add-border">
      <h2>Re-usable components tests</h2>
    </div>
    <div style="width: 160px">
      <base-button></base-button>
    </div>
    <div style="width: 160px; margin-top: 10px">
      <secondary-button></secondary-button>
    </div>
    <div
      style="
        width: 360px;
        margin-top: 10px;
        display: flex;
        flex-direction: column;
      "
    >
      <input type="text" placeholder="Enter your email" />
      <input type="date" placeholder="Enter your email" />
      <select>
        <option value="">---- Select value ----</option>
        <option value="">William</option>
      </select>
    </div>
    <!-- <warning-msg></warning-msg> -->
    <!-- <modal>
      <template #content> this is modal </template>
    </modal> -->
    <main-table></main-table>
  </div>
</template>

<script>
import BaseButton from "./reusable/BaseButton.vue";
import MainTable from "./reusable/MainTable.vue";
import Modal from "./reusable/Modal.vue";
import SecondaryButton from "./reusable/SecondaryButton.vue";
import UserCircle from "./reusable/UserCircle.vue";
import WarningMsg from "./reusable/WarningMsg.vue";
export default {
  components: {
    BaseButton,
    SecondaryButton,
    WarningMsg,
    MainTable,
    UserCircle,
    Modal,
  },
  name: "HelloWorld",
  props: {
    msg: String,
  },
};
</script>


<style scoped>
.add-border {
  border-radius: 10px;
  background: #fff;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
}
</style>
