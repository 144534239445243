<template>
  <div class="warning-msg">
    <div class="modal-shadow" @click="onClosingModal"></div>
    <div class="warning-msg-container">
      <div class="warning-msg-div">
        <h4 class="warning-header">
          {{ message }}
        </h4>
        <div class="border-under"></div>
        <div class="warning-msg-middle">
          <button
            class="warning-btn-cancel"
            :class="isOnLoading ? 'disableOnLoading' : ''"
            @click="onClosingModal"
          >
            Cancel
          </button>
          <button
            class="warning-btn-yes"
            :class="isOnLoading ? 'disableOnLoading' : ''"
            @click="onClick"
          >
            <btn-loader v-if="isOnLoading"></btn-loader>
            {{ !isOnLoading ? "Yes" : "" }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
import BtnLoader from "./BtnLoader.vue";
export default {
  components: { BtnLoader },
  data() {
    return {
      isMakeChange: false,
    };
  },
  props: {
    onClick: { type: Function },
    onClosingModal: { type: Function },
    isOnLoading: { type: Boolean, default: false },
    message: { type: String, default: "Are you sure, you want to proceed" },
  },
};
</script>
    
<style scoped>
.warning-header {
  padding: 20px 20px 0;
  text-align: center;
}
.warning-msg {
  position: absolute;
  width: calc(100% - 10px);
  height: auto;
  top: 0;
  left: 0;
}
.modal-shadow {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.42);
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.warning-msg-container {
  width: 350px;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  height: auto;
  position: fixed;
  z-index: 100;
  max-height: 90vh;
  overflow-y: auto;
}

.warning-msg-div {
  height: auto;
  margin: 10px auto;
  background-color: #ffffff;
  border-radius: 8px;
}

.border-under {
  border: 1px solid #e4e4e4;
  margin: -10px 20px 10px;
}

.warning-msg-middle {
  padding: 16px;
  display: flex;
  justify-content: center;
}

button {
  padding: 5px 20px;
  margin: 0 10px;
  width: 40%;
  border-radius: 6px !important;
  margin-bottom: 8px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

button:hover {
  cursor: pointer;
}

.warning-btn-cancel {
  color: #ee1d1d;
  background-color: #ffffff;
  border: 1px solid #ee1d1d;
}

.warning-btn-yes {
  color: #ffffff;
  background-color: var(--primary-color);
  border: 1px solid var(--primary-color);
}

button[disabled="disabled"] {
  cursor: not-allowed !important;
  opacity: 0.6;
}

.disableOnLoading {
  opacity: 1 !important;
}

p {
  color: red;
  text-align: center;
  font-size: 14px;
}

@media (max-width: 500px) {
  .warning-msg-container {
    width: 87% !important;
  }
}
</style>