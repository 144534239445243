<template>
  <div class="data-loader"></div>
</template>
  
<script>
export default {};
</script>
  
<style scoped>
.data-loader {
  width: 60px;
  height: 60px;
  border: 4px solid #0f2743;
  border-bottom-color: #b8d7ee;
  border-radius: 50%;
  display: inline-block;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-animation: rotation 1s linear infinite;
  animation: rotation 1s linear infinite;
}
</style>