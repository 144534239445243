<template>
  <base-layout>
    <template #content>
      <div>
        <div class="subsc-header g-flex g-align-center g-space-btw">
          <h3>All Subscriptions</h3>
          <div class="g-flex g-align-center">
            <label>From</label>
            <input
              type="date"
              v-model="rangeFrom"
              @input="searchByEveryThingHandler"
            />
            <label style="margin-left: 6px">To</label>
            <input
              type="date"
              v-model="rangeTo"
              @input="searchByEveryThingHandler"
            />
          </div>
          <div style="width: 300px" class="global-search">
            <input
              type="text"
              placeholder="Search..."
              v-model="searchValue"
              @keyup.enter="searchByEveryThingHandler"
            />
            <button @click="searchByEveryThingHandler">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
        </div>
        <div style="padding: 0 10px">
          <table>
            <thead>
              <tr>
                <th>Company</th>
                <th>Payment type</th>
                <th>Starting date</th>
                <th>Ending date</th>
                <th>Total annual amount</th>
                <th>Consumers</th>
                <th>Status</th>
                <th>Cancelled</th>
                <th style="min-width: 80px">Actions</th>
              </tr>
            </thead>
            <tbody v-if="subscriptionList.length && !isGettingSubsc">
              <tr v-for="item in subscriptionList" :key="item.id">
                <td>
                  {{ item.client.client_name }}
                </td>
                <td>
                  {{ item.payment_type }}
                  <span
                    class="py-warning"
                    v-if="checkSubscOverdue(item.subscription_payments)"
                  >
                    has to pay
                  </span>
                </td>
                <td>{{ formatDate(item.starting_date) }}</td>
                <td>{{ formatDate(item.ending_date) }}</td>
                <td>{{ formatMoney(item.total_amount) }}</td>
                <td>{{ item.slots }}</td>
                <td
                  :class="
                    item.status == 'Active' ? 'green-status' : 'red-status'
                  "
                >
                  {{ item.status }}
                </td>
                <td :class="item.cancelled ? 'green-status' : 'red-status'">
                  {{ item.cancelled ? "Yes" : "No" }}
                </td>
                <td>
                  <actions-btn>
                    <template #content>
                      <button
                        class="action-btn-item"
                        v-if="item.status !== 'Active'"
                        @click="toggleApproveSubscription(item.id)"
                      >
                        Approve
                      </button>
                      <button
                        class="action-btn-item"
                        @click="toggleDeleteSubscription(item.id)"
                      >
                        Delete
                      </button>
                      <a
                        target="_blank"
                        :href="item.contract"
                        style="color: black"
                      >
                        <button class="action-btn-item">View Contract</button>
                      </a>
                    </template>
                  </actions-btn>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="uhuru-pg-container" v-if="!isGettingSubsc && hasNextPage">
            <vue-awesome-paginate
              :total-items="totalPgItems"
              :items-per-page="10"
              :max-pages-shown="8"
              v-model="currentPage"
              paginate-buttons-class="pg-btn"
              active-page-class="pg-btn-active"
              back-button-class="pg-back-btn"
              next-button-class="pg-next-btn"
              prev-button-content="Prev"
              next-button-content="Next"
              :on-click="getAllSubscriptionsHandler"
            />
          </div>

          <div
            style="width: 100%; margin: 10px"
            class="g-flex g-align-center g-content-center"
            v-if="isGettingSubsc"
          >
            <table-data-loader></table-data-loader>
          </div>
        </div>

        <div v-if="isToggleApproveWarning">
          <warning-msg
            message="Are you sure you want to approve this subscription"
            :onClick="approveSubscriptionHandler"
            :onClosingModal="toggleApproveSubscription"
            :isOnLoading="isApprovingSubsc"
          ></warning-msg>
        </div>

        <div v-if="isToggleDeleteeWarning">
          <warning-msg
            message="Are you sure you want to delete this subscription"
            :onClick="deleteSubscriptionHandler"
            :onClosingModal="toggleDeleteSubscription"
            :isOnLoading="isDeletingSubsc"
          ></warning-msg>
        </div>
      </div>
    </template>
  </base-layout>
</template>
    
<script>
import axios from "axios";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import formatMoney from "@/mixins/formatMoney";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import WarningMsg from "@/components/reusable/WarningMsg.vue";

export default {
  components: {
    BaseLayout,
    ActionsBtn,
    TableDataLoader,
    WarningMsg,
  },
  data() {
    return {
      subscriptionList: [],
      isGettingSubsc: false,

      rangeFrom: "",
      rangeTo: this.formatDate(new Date()),
      searchValue: "",
      isOnSearchingSubscs: false,

      isToggleApproveWarning: false,
      isApprovingSubsc: false,
      subscToApprove: "",
      subscStatus: "",
      subscToDelete: "",
      isToggleDeleteeWarning: false,
      isDeletingSubsc: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin, formatMoney],
  methods: {
    checkSubscOverdue(subscPayment) {
      if (subscPayment !== "N/A") {
        return subscPayment.overdue == "false" ? false : true;
      }
    },

    toggleApproveSubscription(id) {
      this.subscToApprove = id;
      this.isToggleApproveWarning = !this.isToggleApproveWarning;
    },

    toggleDeleteSubscription(id) {
      this.subscToDelete = id;
      this.isToggleDeleteeWarning = !this.isToggleDeleteeWarning;
    },

    approveSubscriptionHandler() {
      this.isApprovingSubsc = true;

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.subscToApprove}/change-status?status=Active`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.getAllSubscriptionsHandler();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isApprovingSubsc = false;
          this.isToggleApproveWarning = false;
        });
    },

    // TODO: To be reusable
    deleteSubscriptionHandler() {
      this.isDeletingSubsc = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.subscToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.subscriptionList = this.subscriptionList.filter(
            (item) => item.id !== this.subscToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingSubsc = false;
          this.isToggleDeleteeWarning = !this.isToggleDeleteeWarning;
        });
    },

    searchByEveryThingHandler() {
      if (!this.rangeFrom && !this.searchValue) {
        return;
      }
      this.isOnSearchingSubscs = true;
      this.getAllSubscriptionsHandler();
    },

    generateUrlHandler() {
      if (
        this.isOnSearchingSubscs &&
        this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/subscriptions?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&search=${this.searchValue}`;
      }

      if (this.isOnSearchingSubscs && this.searchValue && !this.rangeFrom) {
        return `${this.$store.state.apiUrl}/v1/uhuru/subscriptions?search=${this.searchValue}`;
      }

      if (
        this.isOnSearchingSubscs &&
        !this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/subscriptions?range_from=${this.rangeFrom}&range_until=${this.rangeTo}`;
      }

      if (!this.isOnSearchingSubscs) {
        return `${this.$store.state.apiUrl}/v1/uhuru/subscriptions`;
      }
    },

    getAllSubscriptionsHandler(page) {
      this.isGettingSubsc = true;
      let actionUrl = "";
      if (!this.isOnSearchingSubscs) {
        actionUrl = this.generateUrlHandler() + `?page=${page}`;
      } else {
        actionUrl = this.generateUrlHandler() + `&page=${page}`;
      }
      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.subscriptionList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSubsc = false;
        });
    },
  },
  created() {
    this.getAllSubscriptionsHandler();
  },
};
</script>
    
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.subsc-header {
  margin: 20px;
}
.subsc-header input {
  margin-bottom: 0 !important;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-subsc-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
select {
  margin-bottom: 10px;
}

/* Switch on and off */
.on {
  color: #0294ff;
  padding-left: 8px;
  padding-top: 3px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 26px;
  min-width: 55px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #0294ff;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ticket-desable {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px !important;
  width: 18px !important;
  top: 2px !important;
  left: 3px !important;
  border: 1px solid #0294ff !important;
  background-color: white !important;
  -webkit-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  transition: 0.4s !important;
}

input:checked + .slider {
  background-color: rgb(247, 247, 247) !important;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 1px rgb(247, 247, 247) !important;
  box-shadow: 0 0 1px rgb(247, 247, 247) !important;
}

input:checked + .slider:after {
  content: "Yes" !important;
  position: relative !important;
  top: 1px;
  right: -5px;
  color: #0294ff;
}
input:not(:checked) + .slider:after {
  content: "No" !important;
  position: absolute !important;
  right: 8px !important;
  top: 1px;
  color: #0294ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(29px) !important;
  -ms-transform: translateX(29px) !important;
  transform: translateX(29px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px !important;
}

.slider.round:before {
  border-radius: 50% !important;
}
.py-warning {
  background: #b9960b;
  color: #ffffff;
  padding: 0 8px;
  font-size: 13px;
  border-radius: 10px;
}
</style>