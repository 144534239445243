<template>
  <base-layout>
    <template #content>
      <div class="employee-header g-flex g-align-center g-space-btw">
        <h3>All payments</h3>
        <div class="g-flex g-align-center">
          <label>From</label>
          <input
            type="date"
            v-model="paymentsFrom"
            @input="searchByEveryThingHandler"
          />
          <label style="margin-left: 6px">To</label>
          <input
            type="date"
            v-model="paymentsTo"
            @input="searchByEveryThingHandler"
          />
        </div>
        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchPayments"
            @keyup.enter="searchByEveryThingHandler"
          />
          <button @click="searchByEveryThingHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
        <div class="g-filter-cont">
          <i class="fa-solid fa-filter"></i>
          <select v-model="pyModeValue" @change="searchByEveryThingHandler">
            <option value="">Filter</option>
            <option
              :value="item"
              v-for="(item, index) in paymentModes"
              :key="index"
            >
              {{ item }}
            </option>
          </select>
        </div>
      </div>
      <div style="padding: 0 10px">
        <table>
          <thead>
            <tr>
              <th>Company</th>
              <th>Paid date</th>
              <th>Paid amount</th>
              <th>Payment mode</th>
              <th>Reference number</th>
              <th>Payment month</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="paymentsList.length && !isGettingPayments">
            <tr v-for="item in paymentsList" :key="item.id">
              <td>{{ item.subscription.client.client_name }}</td>
              <td>
                {{ formatDate(item.payment_date) }}
              </td>
              <td>{{ formatMoney(item.paid_amount) }}</td>
              <td>{{ item.payment_mode }}</td>
              <td>{{ item.reference_number || "-" }}</td>
              <td>
                {{ item.payment_month }}
              </td>
              <td
                :class="
                  item.status == 'Approved' ? 'green-status' : 'red-status'
                "
              >
                {{ item.status }}
              </td>
              <td>
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      v-if="item.notes"
                      @click="togglePaymentNoteHandler(item.notes)"
                    >
                      View note
                    </button>
                    <a target="_blank" :href="item.proof" v-if="item.proof">
                      <button class="action-btn-item">View Proof</button>
                    </a>
                    <button
                      class="action-btn-item"
                      v-if="item.status !== 'Approved'"
                      :disabled="isChangingPaymentStatus"
                      @click="toggleChangePaymentStatus(item.id, 'Approved')"
                    >
                      Approve
                    </button>
                    <button
                      class="action-btn-item"
                      v-if="item.status == 'Approved'"
                      :disabled="isChangingPaymentStatus"
                      @click="toggleChangePaymentStatus(item.id, 'Denied')"
                    >
                      Disapprove
                    </button>
                    <button
                      class="action-btn-item"
                      @click="toggleDeletePayment(item.id)"
                    >
                      Delete
                    </button>
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingPayments && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getAllPaymentsHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingPayments"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>

      <div v-if="isViewPaymentNote">
        <modal :closeModal="togglePaymentNoteHandler" width="360px">
          <template #content>
            <p style="margin-bottom: 10px">
              <b style="font-weight: 500">PAYMENT NOTE</b>
            </p>
            <p>{{ selectedNote }}</p>
          </template>
        </modal>
      </div>

      <div v-if="isChangePaymentStatus">
        <warning-msg
          message="Are you sure you want to change status of this payment"
          :onClosingModal="toggleDeletePayment"
          :onClick="approveDisapprovePayment"
          :isOnLoading="isChangingPaymentStatus"
        ></warning-msg>
      </div>

      <div v-if="hasDeletePaymentToggled">
        <warning-msg
          message="Are you sure you want to delete this payment"
          :onClosingModal="toggleDeletePayment"
          :onClick="deletePaymentHandler"
          :isOnLoading="isDeletingPayment"
        ></warning-msg>
      </div>
    </template>
  </base-layout>
</template>
      
  <script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import formatMoney from "@/mixins/formatMoney";
import WarningMsg from "@/components/reusable/WarningMsg.vue";

export default {
  components: {
    BaseLayout,
    TableDataLoader,
    ActionsBtn,
    BaseButton,
    Modal,
    WarningMsg,
  },
  data() {
    return {
      isGettingPayments: false,
      paymentsList: [],
      isToggleAddPayment: false,
      isMakingChangeOnPayment: false,

      // payment form
      paid_amount: "",
      payment_date: "",
      payment_mode: "",
      reference_number: "",
      payment_month: "",
      notes: "",
      proof: "",
      formErrors: {},

      paymentModes: [],

      isViewPaymentNote: false,
      selectedNote: "",

      paymentsFrom: "",
      paymentsTo: this.formatDate(new Date()),
      searchPayments: "",
      isOnSearchingPayments: false,
      pyModeValue: "",

      isChangingPaymentStatus: false,
      hasDeletePaymentToggled: false,
      isChangePaymentStatus: false,
      isChangingPaymentStatus: false,
      paymentToChange: "",
      newPaymentStatus: "",

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin, formatMoney],
  methods: {
    toggleChangePaymentStatus(id, status) {
      this.isChangePaymentStatus = !this.isChangePaymentStatus;
      this.paymentToChange = id;
      this.newPaymentStatus = status;
    },

    // TODO: Make it reusable method
    approveDisapprovePayment() {
      this.isChangingPaymentStatus = true;
      let data = {
        status: this.newPaymentStatus,
        _method: "PUT",
      };
      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/payments/${this.paymentToChange}/approve`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.isChangePaymentStatus = false;
          this.getAllPaymentsHandler();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isChangingPaymentStatus = false;
        });
    },

    toggleDeletePayment(id) {
      this.paymentToDelete = id;
      this.hasDeletePaymentToggled = !this.hasDeletePaymentToggled;
    },

    // TODO: Make it reusable method
    deletePaymentHandler() {
      this.isDeletingPayment = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/payments/${this.paymentToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.paymentsList = this.paymentsList.filter(
            (item) => item.id !== this.paymentToDelete
          );
          this.hasDeletePaymentToggled = !this.hasDeletePaymentToggled;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingPayment = false;
        });
    },

    searchByEveryThingHandler() {
      if (!this.paymentsFrom && !this.searchPayments && !this.pyModeValue) {
        return;
      }
      this.isOnSearchingPayments = true;
      this.getAllPaymentsHandler();
    },

    togglePaymentNoteHandler(item) {
      this.selectedNote = item;
      this.isViewPaymentNote = !this.isViewPaymentNote;
    },

    generateUrlHandler() {
      if (
        this.isOnSearchingPayments &&
        this.searchPayments &&
        this.paymentsFrom &&
        this.paymentsTo &&
        this.pyModeValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?range_from=${this.paymentsFrom}&range_until=${this.paymentsTo}&search=${this.searchPayments}&mode=${this.pyModeValue}`;
      }
      if (
        this.isOnSearchingPayments &&
        this.searchPayments &&
        this.paymentsFrom &&
        this.paymentsTo &&
        !this.pyModeValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?range_from=${this.paymentsFrom}&range_until=${this.paymentsTo}&search=${this.searchPayments}`;
      }
      if (
        this.isOnSearchingPayments &&
        this.searchPayments &&
        !this.paymentsFrom &&
        this.pyModeValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?search=${this.searchPayments}&mode=${this.pyModeValue}`;
      }
      if (
        this.isOnSearchingPayments &&
        !this.searchPayments &&
        this.paymentsFrom &&
        this.paymentsTo &&
        this.pyModeValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?range_from=${this.paymentsFrom}&range_until=${this.paymentsTo}&mode=${this.pyModeValue}`;
      }
      if (
        this.isOnSearchingPayments &&
        !this.searchPayments &&
        !this.pyModeValue &&
        this.paymentsFrom &&
        this.paymentsTo
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?range_from=${this.paymentsFrom}&range_until=${this.paymentsTo}`;
      }
      if (
        this.isOnSearchingPayments &&
        this.searchPayments &&
        !this.pyModeValue &&
        !this.paymentsFrom
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?search=${this.searchPayments}`;
      }
      if (
        this.isOnSearchingPayments &&
        !this.searchPayments &&
        this.pyModeValue &&
        !this.paymentsFrom
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments?mode=${this.pyModeValue}`;
      }
      if (!this.isOnSearchingPayments) {
        return `${this.$store.state.apiUrl}/v1/uhuru/payments`;
      }
    },

    getAllPaymentsHandler(page) {
      this.isGettingPayments = true;

      let actionUrl = "";
      if (!this.isOnSearchingPayments) {
        actionUrl = this.generateUrlHandler() + `?page=${page}`;
      } else {
        actionUrl = this.generateUrlHandler() + `&page=${page}`;
      }

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.paymentsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPayments = false;
          this.isOnSearchingPayments = false;
        });
    },

    getPaymentModes() {
      axios
        .get(`${this.$store.state.apiUrl}/v1/payments-mode`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          this.paymentModes = res.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {});
    },
  },
  created() {
    this.getAllPaymentsHandler();
    this.getPaymentModes();
  },
};
</script>
      
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.heading--1 {
  margin: 10px 0;
  font-weight: 600;
  text-transform: uppercase;
}
.employee-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-employee-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
</style>