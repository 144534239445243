<template>
  <div>
    <div>
      <div class="g-flex g-align-center g-space-btw">
        <h3 style="margin: 0">List of Persons to Contact</h3>
        <div style="width: 130px; margin-bottom: 10px">
          <base-button
            name="Add New"
            :onClick="toggleAddNewPersonModal"
          ></base-button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Phone</th>
            <th>Email</th>
            <th>Relationship</th>
            <th>Contact type</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="contactPersonsList.length && !isGettingPersons">
          <tr v-for="item in contactPersonsList" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.email }}</td>
            <td>{{ item.relationship }}</td>
            <td>{{ item.contact_type }}</td>
            <td class="td-action">
              <actions-btn>
                <template #content>
                  <button
                    class="action-btn-item"
                    @click="toggleUpdatePersonHandler(item)"
                  >
                    Edit
                  </button>
                  <button
                    class="action-btn-item"
                    @click="toggleDeleteWarningHandler(item.id)"
                  >
                    Delete
                  </button>
                </template>
              </actions-btn>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="uhuru-pg-container" v-if="!isGettingPersons && hasNextPage">
        <vue-awesome-paginate
          :total-items="totalPgItems"
          :items-per-page="10"
          :max-pages-shown="8"
          v-model="currentPage"
          paginate-buttons-class="pg-btn"
          active-page-class="pg-btn-active"
          back-button-class="pg-back-btn"
          next-button-class="pg-next-btn"
          prev-button-content="Prev"
          next-button-content="Next"
          :on-click="getContactPersonHandler"
        />
      </div>

      <div
        style="width: 100%; margin: 10px"
        class="g-flex g-align-center g-content-center"
        v-if="isGettingPersons"
      >
        <table-data-loader></table-data-loader>
      </div>
    </div>
    <div v-if="isToggleContanctPersonForm">
      <modal :closeModal="toggleAddNewPersonModal" width="350px">
        <template #content>
          <div>
            <h3 style="margin: 20px 0">
              {{ selectedPersonToEdit ? "Edit " : "Add New " }}Person to Contact
            </h3>
          </div>
          <div class="add-new-person-form">
            <label>Person Name</label>
            <input type="text" placeholder="Enter person name" v-model="name" />
            <p v-if="formErrors.name && !name" class="form-errors">
              Person name is a required
            </p>

            <label>Phone number</label>
            <input
              type="text"
              placeholder="Enter person's phone number"
              v-model="phone"
            />
            <p v-if="formErrors.phone && !phone" class="form-errors">
              Person's phone number is a required
            </p>

            <label>Email address</label>
            <input
              type="email"
              placeholder="Enter email address"
              v-model="email"
            />
            <p v-if="formErrors.email && !email" class="form-errors">
              Email address is required
            </p>

            <label>Relationship</label>
            <input
              type="text"
              placeholder="EX: Bother, Friend,..."
              v-model="relationship"
            />
            <p
              v-if="formErrors.relationship && !relationship"
              class="form-errors"
            >
              Relationship is required
            </p>

            <label>Contact Type</label>
            <select v-model="contact_type">
              <option value="">-----Select option-----</option>
              <option value="Ensurance">Ensurance</option>
              <option value="Emergency">Emergency</option>
              <option value="Guardian">Guardian</option>
              <option value="Next of Kin">Next of Kin</option>
              <option value="State Agency">State Agency</option>
              <option value="Lawyer">Lawyer</option>
              <option value="Employer">Employer</option>
              <option value="Other">Other</option>
            </select>
            <p
              v-if="formErrors.contact_type && !contact_type"
              class="form-errors"
            >
              Contact type is required
            </p>
          </div>
          <div style="margin: 20px 0" v-if="!selectedPersonToEdit">
            <base-button
              name="Add Person"
              :isLoading="isMakingChangeOnPerson"
              :onClick="addNewContactPersonHandler"
            ></base-button>
          </div>
          <div style="margin: 20px 0" v-if="selectedPersonToEdit">
            <base-button
              name="Edit Person"
              :isLoading="isMakingChangeOnPerson"
              :onClick="addNewContactPersonHandler"
            ></base-button>
          </div>
        </template>
      </modal>
    </div>
  </div>
  <div v-if="isToggleDeleteWarning">
    <warning-msg
      message="Are you sure you want to delete this person"
      :onClick="deleteContactPersonHandler"
      :onClosingModal="toggleDeleteWarningHandler"
      :isOnLoading="isDeletingPerson"
    ></warning-msg>
  </div>
</template>
  
  <script>
import axios from "axios";
import TableDataLoader from "./reusable/TableDataLoader.vue";
import BaseButton from "./reusable/BaseButton.vue";
import Modal from "./reusable/Modal.vue";
import ActionsBtn from "./reusable/ActionsBtn.vue";
import WarningMsg from "./reusable/WarningMsg.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    TableDataLoader,
    BaseButton,
    Modal,
    ActionsBtn,
    WarningMsg,
  },
  data() {
    return {
      isGettingPersons: false,
      contactPersonsList: [],
      isToggleContanctPersonForm: false,

      formErrors: {},
      isMakingChangeOnPerson: false,
      isDeletingPerson: false,
      isToggleDeleteWarning: false,
      selectedPersonToDelete: "",
      selectedPersonToEdit: null,
      //   Person
      name: "",
      phone: "",
      email: "",
      relationship: "",
      contact_type: "",

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    getContactPersonHandler(page) {
      this.isGettingPersons = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/contacts?page=${page}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.contactPersonsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPersons = false;
        });
    },

    toggleAddNewPersonModal() {
      this.selectedPersonToEdit = null;
      this.clearForm();
      this.isToggleContanctPersonForm = !this.isToggleContanctPersonForm;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.name) {
        this.formErrors.name = true;
      }
      if (!this.phone) {
        this.formErrors.phone = true;
      }
      if (!this.email) {
        this.formErrors.email = true;
      }
      if (!this.relationship) {
        this.formErrors.relationship = true;
      }
      if (!this.contact_type) {
        this.formErrors.contact_type = true;
      }

      return (
        !this.formErrors.name &&
        !this.formErrors.phone &&
        !this.formErrors.email &&
        !this.formErrors.relationship &&
        !this.formErrors.contact_type
      );
    },

    clearForm() {
      this.name = "";
      this.phone = "";
      this.email = "";
      this.relationship = "";
      this.contact_type = "";
    },

    addNewContactPersonHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnPerson = true;

      let data = {
        name: this.name,
        phone: this.phone,
        email: this.email,
        relationship: this.relationship,
        contact_type: this.contact_type,
      };

      if (this.selectedPersonToEdit) data._method = "PUT";

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/contacts${
            this.selectedPersonToEdit ? "/" + this.selectedPersonToEdit : ""
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          if (this.selectedPersonToEdit) {
            this.contactPersonsList = this.contactPersonsList.map((item) => {
              if (item.id == this.selectedPersonToEdit) {
                return response.data.data;
              } else {
                return item;
              }
            });
          } else {
            this.contactPersonsList.push(response.data.data);
          }
          this.toggleAddNewPersonModal();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnPerson = false;
        });
    },

    toggleUpdatePersonHandler(item) {
      this.selectedPersonToEdit = item.id;
      this.name = item.name;
      this.phone = item.phone;
      this.email = item.email;
      this.relationship = item.relationship;
      this.contact_type = item.contact_type;
      this.isToggleContanctPersonForm = true;
    },

    toggleDeleteWarningHandler(item) {
      this.selectedPersonToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteContactPersonHandler() {
      this.isDeletingPerson = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/contacts/${this.selectedPersonToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.contactPersonsList = this.contactPersonsList.filter(
            (item) => item.id !== this.selectedPersonToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingPerson = false;
          this.toggleDeleteWarningHandler();
        });
    },
  },
  created() {
    this.getContactPersonHandler();
  },
};
</script>
  
<style scoped>
.add-new-person-form input,
select {
  margin-bottom: 10px;
}
</style>