<template>
  <base-layout>
    <template #content>
      <div class="emp-analytics-cont">
        <div class="emp-analytics-body">
          <div class="uh-analy-cards-cont">
            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-users"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ clients }}</p>
                <div>
                  <label>Total companies</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-users"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ employees }}</p>
                <div>
                  <label>Total employees</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-phone-volume"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_sessions_current_month }}</p>
                <div>
                  <label>Monthly call sessions</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-users"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ agents }}</p>
                <div>
                  <label>Total agents</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-user-doctor"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ therapist }}</p>
                <div>
                  <label>Total therapist</label>
                </div>
              </div>
            </div>
          </div>

          <div class="g-flex" style="width: 100%; margin: 30px 0">
            <div style="width: 50%">
              <p><label>PAYMENTS</label></p>
              <apexchart
                type="area"
                height="350"
                :options="chartOptionsFour"
                :series="seriesFour"
              ></apexchart>
            </div>
            <div style="width: 50%">
              <p><label>CONSULTATIONS</label></p>
              <apexchart
                type="area"
                height="350"
                :options="chartOptionsFive"
                :series="seriesFive"
              ></apexchart>
            </div>
          </div>

          <div class="g-flex" style="width: 100%; margin: 30px 0">
            <div style="width: 50%">
              <p><label>CALL SESSIONS</label></p>
              <apexchart
                type="area"
                height="350"
                :options="chartOptionsOne"
                :series="seriesOne"
              ></apexchart>
            </div>
            <div style="width: 50%">
              <!-- <p><label>CALL DURATIONS</label></p>
              <apexchart
                type="area"
                height="350"
                :options="chartOptionsTwo"
                :series="seriesTwo"
              ></apexchart> -->
              <div>
                <p><label>WEEKLY CALL SESSIONS</label></p>
                <apexchart
                  type="pie"
                  width="450"
                  :options="chartOptionsThree"
                  :series="seriesThree"
                ></apexchart>
              </div>
            </div>
          </div>

          <div class="g-flex" style="width: 100%; margin: 30px 0">
            <div style="width: 50%">
              <div>
                <p><label>CALL SESSIONS PER AGENT</label></p>
                <table>
                  <thead>
                    <tr>
                      <th>Agent name</th>
                      <th>Call sessions</th>
                      <th>Call durations</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, index) in topAgents" :key="index">
                      <td>{{ item.agent }}</td>
                      <td>{{ item.total_sessions }}</td>
                      <td>{{ item.call_duration }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div style="width: 50%"></div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import MainHeader from "@/components/MainHeader.vue";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
export default {
  components: { MainHeader, BaseLayout },
  data() {
    return {
      isGettingAnalytics: false,
      analyticsAllData: {
        total: [],
        callDuration: [],
        callDates: [],
      },

      topAgents: [],

      analyticsOnAgents: {
        total: [],
        callDuration: [],
        names: [],
      },

      paymentsTotal: [],
      paymentsMonth: [],
      consultationsTotal: [],
      consultationsMonth: [],

      employees: 18,
      clients: 2,
      agents: 1,
      therapist: 2,

      totalCallSessions: 0,
      total_sessions_current_month: 0,

      chartOptionsOne: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#0f676c"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesOne: [
        {
          name: "call sessions",
          data: [],
        },
      ],

      chartOptionsTwo: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#464646"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesTwo: [
        {
          name: "call durations",
          data: [],
        },
      ],

      seriesThree: [],
      chartOptionsThree: {
        chart: {
          width: 700,
          type: "pie",
        },
        labels: [],
        colors: [
          "#10666C",
          "#39539E",
          "#5E8722",
          "#0077B5",
          "#1e4b7d",
          "#5e8722",
          "#6c6c6c",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      chartOptionsFour: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#1e4b7d"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesFour: [
        {
          name: "Payments",
          data: [],
        },
      ],

      chartOptionsFive: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#5e8722"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesFive: [
        {
          name: "Consultations",
          data: [],
        },
      ],
    };
  },

  methods: {
    assignAnalyticsData(data) {
      this.total_sessions_current_month = data.total_sessions_current_month;
      this.employees = data.employees;
      this.clients = data.clients;
      this.agents = data.agents;
      this.therapist = data.therapist;

      if (data.payments.length) {
        data.payments.forEach((item) => {
          this.paymentsTotal.push(item.total);
          this.paymentsMonth.push(item.month);

          this.seriesFour = [
            {
              name: "Payments",
              data: this.paymentsTotal,
            },
          ];
          this.chartOptionsFour = {
            xaxis: {
              categories: this.paymentsMonth,
            },
            dataLabels: {
              enabled: false,
            },
          };
        });
      }

      if (data.consultations.length) {
        data.consultations.forEach((item) => {
          this.consultationsTotal.push(item.total);
          this.consultationsMonth.push(item.month);

          this.seriesFive = [
            {
              name: "Consultations",
              data: this.consultationsTotal,
            },
          ];
          this.chartOptionsFive = {
            xaxis: {
              categories: this.consultationsMonth,
            },
            dataLabels: {
              enabled: false,
            },
          };
        });
      }

      if (data.call_sessions.length) {
        data.call_sessions.map((item) => {
          this.analyticsAllData.total.push(item.total);
          this.analyticsAllData.callDuration.push(item.call_duration);
          this.analyticsAllData.callDates.push(item.month);

          this.seriesOne = [
            {
              name: "call sessions",
              data: this.analyticsAllData.total,
            },
          ];
          this.chartOptionsOne = {
            xaxis: {
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
          };

          this.seriesTwo = [
            {
              name: "call durations",
              data: this.analyticsAllData.callDuration,
            },
          ];
          this.chartOptionsTwo = {
            xaxis: {
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
          };
        });
      }

      if (data.top_agents.length) {
        this.topAgents = data.top_agents;
      }

      if (data.this_weekl_call_duration.length) {
        data.this_weekl_call_duration.map((item) => {
          this.chartOptionsThree.labels.push(item.weekday);
          this.seriesThree.push(parseInt(item.call_duration));
        });
      }
    },
    getAdminAnalytics() {
      this.isGettingAnalytics = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/uhuru/analytics`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.assignAnalyticsData(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingAnalytics = false;
        });
    },
  },

  created() {
    this.getAdminAnalytics();
  },
};
</script>

<style scoped>
.emp-analytics-body {
  width: 90%;
  background: white;
  padding: 20px;
  margin: 40px auto 0;
}
</style>
