export default {
    methods: {
        onFileChange(e) {
            var files = e.target.files;
            if (!files.length) return;
            this.registration_certificate = files[0];
        },
        validateClientInfoForm() {
            this.registerErrors = {};

            if (!this.phone) {
                this.registerErrors.phone = true;
            }
            if (!this.contact_person) {
                this.registerErrors.contact_person = true;
            }
            if (!this.contact_person_phone) {
                this.registerErrors.contact_person_phone = true;
            }
            if (!this.contact_person_email) {
                this.registerErrors.contact_person_email = true;
            }
            if (!this.tin_number) {
                this.registerErrors.tin_number = true;
            }
            if (!this.registration_certificate) {
                this.registerErrors.registration_certificate = true;
            }
            if (!this.physical_address) {
                this.registerErrors.physical_address = true;
            }
            if (!this.website) {
                this.registerErrors.website = true;
            }
            if (!this.mode_of_communication) {
                this.registerErrors.mode_of_communication = true;
            }

            if (this.userType === "Company") {
                return (
                    !this.registerErrors.contact_person &&
                    !this.registerErrors.phone &&
                    !this.registerErrors.contact_person_phone &&
                    !this.registerErrors.contact_person_email &&
                    !this.registerErrors.tin_number &&
                    !this.registerErrors.registration_certificate &&
                    !this.registerErrors.physical_address &&
                    !this.registerErrors.website &&
                    !this.registerErrors.mode_of_communication
                );
            } else {
                return (
                    !this.registerErrors.phone &&
                    !this.registerErrors.physical_address &&
                    !this.registerErrors.mode_of_communication
                );
            }
        },
    }
}