<template>
  <base-layout>
    <template #content>
      <div style="padding: 10px">
        <main-table></main-table>
      </div>
    </template>
  </base-layout>
</template>

<script>
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import MainTable from "@/components/reusable/MainTable.vue";
export default {
  components: { BaseLayout, MainTable },
};
</script>

<style scoped>
</style>