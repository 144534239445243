<template>
  <div>
    <main-header></main-header>
    <div class="my-patients-cont">
      <change-user-profile></change-user-profile>
    </div>
  </div>
</template>

<script>
import MainHeader from "@/components/MainHeader.vue";
import ChangeUserProfile from "@/components/ChangeUserProfile.vue";
export default {
  components: {
    MainHeader,
    ChangeUserProfile,
  },
  data() {
    return {};
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.my-patients-cont {
  width: 80%;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
</style>