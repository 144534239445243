<template>
  <div>
    <button class="main-button" @click="isToggleDpDown = !isToggleDpDown">
      <img src="/images/actions_4.svg" alt="" />
    </button>
    <div
      class="dp-down-cont"
      v-if="isToggleDpDown"
      @click="isToggleDpDown = !isToggleDpDown"
    >
      <slot name="content"></slot>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      isToggleDpDown: false,
    };
  },
  computed: {
    ...mapState(["isToggleActionsDpDown"]),
  },
  watch: {},
  methods: {},
};
</script>

<style scoped>
.main-button {
  width: 36px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  border-radius: 4px;
}
.main-button:hover {
  background: var(--third-main-color);
}
img {
  width: 20px;
  height: 10px;
}
.dp-down-cont {
  position: absolute;
  min-width: 80px;
  max-width: 100px;
  padding: 5px 0;
  border: 1px solid #dddddd;
  border-radius: 4px;
  background: white;
  margin-top: 4px;
}
</style>