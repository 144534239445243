<template>
  <base-layout>
    <template #content>
      <div>
        <div class="subsc-header g-flex g-align-center g-space-btw">
          <h3>Extended subscriptions</h3>
          <div class="g-flex">
            <div style="width: 200px">
              <base-button
                name="Extend subscription"
                @click="toggleExtendSubscHandler"
              ></base-button>
            </div>
            <!-- <div style="width: 40px; margin-left: 20px">
              <button class="g-back-btn" title="Back" @click="goBackHandler">
                <i class="fa-solid fa-arrow-left"></i>
              </button>
            </div> -->
          </div>
        </div>
        <div style="padding: 0 10px">
          <table>
            <thead>
              <tr>
                <th>Payment status</th>
                <th>Consumers</th>
                <th>Confirmed</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="subscriptionList.length && !isGettingSubsc">
              <tr v-for="item in subscriptionList" :key="item.id">
                <td>
                  {{ item.paid ? "Paid" : "Not Paid" }}
                </td>
                <td>{{ item.slots }}</td>
                <td :class="item.confirmed ? 'green-status' : 'red-status'">
                  {{ item.confirmed ? "Yes" : "No" }}
                </td>
                <td
                  :class="
                    item.status == 'Approved' ? 'green-status' : 'red-status'
                  "
                >
                  {{ item.status }}
                </td>
                <td style="width: 300px">
                  <actions-btn>
                    <template #content>
                      <button
                        class="action-btn-item"
                        v-if="!item.paid"
                        @click="toggleAddNewPayment(item.id, item.slots)"
                      >
                        Add payment
                      </button>
                      <button
                        class="action-btn-item"
                        v-if="item.paid"
                        @click="toggleViewPaymentsHandler(item.payment)"
                      >
                        View payment
                      </button>
                      <button
                        class="action-btn-item subsc-act-delete"
                        @click="toggleDeleteExtendedSubsc(item.id)"
                      >
                        Delete
                      </button>
                    </template>
                  </actions-btn>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="uhuru-pg-container" v-if="!isGettingSubsc && hasNextPage">
            <vue-awesome-paginate
              :total-items="totalPgItems"
              :items-per-page="10"
              :max-pages-shown="8"
              v-model="currentPage"
              paginate-buttons-class="pg-btn"
              active-page-class="pg-btn-active"
              back-button-class="pg-back-btn"
              next-button-class="pg-next-btn"
              prev-button-content="Prev"
              next-button-content="Next"
              :on-click="getExtendedSubscriptionsHandler"
            />
          </div>

          <div
            style="width: 100%; margin: 10px"
            class="g-flex g-align-center g-content-center"
            v-if="isGettingSubsc"
          >
            <table-data-loader></table-data-loader>
          </div>
          <div
            style="width: 100%; margin: 10px"
            class="g-flex g-align-center g-content-center"
            v-if="!isGettingSubsc && !subscriptionList.length"
          >
            <p>No extend subscription found</p>
          </div>
        </div>

        <div v-if="isToggleExtendSubsc">
          <modal :closeModal="toggleExtendSubscHandler" width="360px">
            <template #content>
              <div>
                <h3 class="heading--1">Extend my subscription</h3>
              </div>

              <div class="extend-label-div">
                <label>Upload employees list using excel</label>
              </div>
              <p class="extend-msg">
                <b>NB: </b>
                Excel should have a column of name, email, phone, password and
                code
              </p>
              <input type="file" placeholder="" @change="onAddEmployeeList" />

              <div style="width: 150px; margin-bottom: 10px">
                <base-button
                  name="Add"
                  :isLoading="isExtendingSubsc"
                  :onClick="extendCurrentSubscHandler"
                ></base-button>
              </div>
            </template>
          </modal>
        </div>

        <div v-if="isToggleAddPayment">
          <modal :closeModal="closeAddNewPayment" width="360px">
            <template #content>
              <div v-if="!isGettingCompanyProfile">
                <div>
                  <h3 class="heading--1">Add New Payment</h3>
                </div>
                <label>Paid Date</label>
                <input type="date" placeholder="" v-model="payment_date" />
                <p
                  v-if="formErrors.payment_date && !payment_date"
                  class="form-errors"
                >
                  Paid date is required
                </p>

                <label>Amount</label>
                <input
                  type="number"
                  placeholder="Enter amount"
                  readonly
                  v-model="paid_amount"
                />
                <p
                  v-if="formErrors.paid_amount && !paid_amount"
                  class="form-errors"
                >
                  Amount paid is required
                </p>

                <label>Payment Mode</label>
                <select v-model="payment_mode">
                  <option value="">-----Select option-----</option>
                  <option
                    :value="item"
                    v-for="item in paymentModes"
                    :key="item"
                  >
                    {{ item }}
                  </option>
                </select>
                <p
                  v-if="formErrors.payment_mode && !payment_mode"
                  class="form-errors"
                >
                  Select payment mode please
                </p>

                <label>Reference Number</label>
                <input
                  type="text"
                  placeholder="Enter reference number"
                  v-model="reference_number"
                />

                <label>Small Note</label>
                <textarea
                  v-model="notes"
                  placeholder="Write small note"
                ></textarea>
                <p v-if="formErrors.notes && !notes" class="form-errors">
                  Small note is required
                </p>

                <label>Proof File</label>
                <input type="file" placeholder="" @change="onProofFileAdded" />

                <div style="width: 150px; margin-bottom: 10px">
                  <base-button
                    name="Save Payment"
                    :isLoading="isMakingChangeOnPayment"
                    :onClick="addNewPaymentHandler"
                  ></base-button>
                </div>
              </div>
              <div
                style="width: 100%; margin: 10px"
                class="g-flex g-align-center g-content-center"
                v-if="isGettingCompanyProfile"
              >
                <table-data-loader></table-data-loader>
              </div>
            </template>
          </modal>
        </div>

        <div v-if="isToggleViewPayments">
          <modal :closeModal="toggleViewPaymentsHandler" width="800px">
            <template #content>
              <p><b>PAYMENTS</b></p>
              <table>
                <thead>
                  <tr>
                    <th>Paid amount</th>
                    <th>Payment date</th>
                    <th>Payment mode</th>
                    <th>Payment month</th>
                    <th>Reference number</th>
                    <!-- <th>Notes</th> -->
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="selectedPayments.length">
                  <tr v-for="item in selectedPayments" :key="item.id">
                    <td>{{ formatMoney(item.paid_amount) }}</td>
                    <td>{{ formatDate(item.payment_date) }}</td>
                    <td>{{ item.payment_mode }}</td>
                    <td>{{ item.payment_month }}</td>
                    <td>{{ item.reference_number }}</td>
                    <!-- <td>{{ item.notes ? "-" : item.notes }}</td> -->
                    <td>
                      <a target="_blank" :href="item.proof">
                        <button class="subsc-act-btn">Proof</button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </modal>
        </div>

        <div v-if="isToggleDeleteSubsc">
          <warning-msg
            message="Are you sure you want to delete this extended subscription"
            :onClick="deleteExtendedSubsc"
            :onClosingModal="toggleDeleteExtendedSubsc"
            :isOnLoading="isDeletingSubsc"
          ></warning-msg>
        </div>
      </div>
    </template>
  </base-layout>
</template>
    
<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import formatMoney from "@/mixins/formatMoney";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import goBackHandler from "@/mixins/goBackHandler";

export default {
  components: {
    BaseLayout,
    ActionsBtn,
    TableDataLoader,
    BaseButton,
    Modal,
    WarningMsg,
  },
  data() {
    return {
      subscriptionList: [],
      isGettingSubsc: false,
      isToggleExtendSubsc: false,
      isExtendingSubsc: false,
      employees_list: null,

      // payment form
      paid_amount: "",
      payment_date: "",
      payment_mode: "",
      reference_number: "",
      payment_month: "",
      notes: "",
      proof: "",
      formErrors: {},
      isMakingChangeOnPayment: false,
      isToggleAddPayment: false,

      changedSubsc: "",
      paymentModes: null,
      subscConsumers: "",
      isGettingCompanyProfile: false,

      isToggleDeleteSubsc: false,
      isDeletingSubsc: false,
      subscToDelete: "",
      isDeletingSubsc: false,

      isToggleViewPayments: false,
      selectedPayments: [],

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin, formatMoney, goBackHandler],
  methods: {
    toggleViewPaymentsHandler(data) {
      this.selectedPayments = data;
      this.isToggleViewPayments = !this.isToggleViewPayments;
    },

    toggleDeleteExtendedSubsc(item) {
      this.subscToDelete = item;
      this.isToggleDeleteSubsc = !this.isToggleDeleteSubsc;
    },

    deleteExtendedSubsc() {
      this.isDeletingSubsc = true;
      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/subscriptions/change-requests/${this.subscToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.subscriptionList = this.subscriptionList.filter(
            (item) => item.id !== this.subscToDelete
          );
        })
        .catch((error) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          if (error.response.data.errors) {
            return toast.error(error.response.data.errors, {
              theme: "colored",
            });
          }
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingSubsc = false;
          this.isToggleDeleteSubsc = false;
        });
    },

    toggleExtendSubscHandler() {
      this.isToggleExtendSubsc = !this.isToggleExtendSubsc;
    },

    onAddEmployeeList(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.employees_list = files[0];
    },

    extendCurrentSubscHandler() {
      if (!this.employees_list) {
        return toast.error("Please upload excel sheet", {
          theme: "colored",
        });
      }
      this.isExtendingSubsc = true;

      let data = new FormData();

      if (typeof this.employees_list === "object") {
        data.append("employees_list", this.employees_list);
      }

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/subscriptions/employeee/new`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.isToggleExtendSubsc = false;
          this.getExtendedSubscriptionsHandler();
        })
        .catch((error) => {
          if (error.response.data.error) {
            toast.error(error.response.data.error, {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isExtendingSubsc = false;
        });
    },

    closeAddNewPayment() {
      this.isToggleAddPayment = !this.isToggleAddPayment;
    },

    toggleAddNewPayment(id, consumers) {
      this.changedSubsc = id;
      this.subscConsumers = consumers;
      this.isToggleAddPayment = !this.isToggleAddPayment;
      this.getCompanyProfile();
    },

    validateForm() {
      this.formErrors = {};

      if (!this.paid_amount) {
        this.formErrors.paid_amount = true;
      }
      if (!this.payment_date) {
        this.formErrors.payment_date = true;
      }
      if (!this.payment_mode) {
        this.formErrors.payment_mode = true;
      }

      return (
        !this.formErrors.paid_amount &&
        !this.formErrors.payment_date &&
        !this.formErrors.payment_mode
      );
    },

    onProofFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.proof = files[0];
    },

    addNewPaymentHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isMakingChangeOnPayment = true;

      let data = new FormData();

      data.append("paid_amount", this.paid_amount);
      data.append("payment_date", this.payment_date);
      data.append("payment_mode", this.payment_mode);
      data.append("reference_number", this.reference_number);
      data.append("notes", this.notes);

      if (typeof this.proof === "object") {
        data.append("proof", this.proof);
      }

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/subscriptions/${this.changedSubsc}/add-payment`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.isToggleAddPayment = false;
          this.getExtendedSubscriptionsHandler();
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnPayment = false;
        });
    },

    // TODO: Make it reusable
    getCompanyProfile() {
      this.isGettingCompanyProfile = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/dashboard/profile`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          if (res.data.data.payment_type == "Monthly") {
            this.paid_amount =
              res.data.data.monthly_payment * this.subscConsumers;
          } else {
            this.paid_amount = res.data.data.total_amount;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingCompanyProfile = false;
        });
    },

    getPaymentModes() {
      axios
        .get(`${this.$store.state.apiUrl}/v1/payments-mode`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          this.paymentModes = res.data.data.filter((item) => item != "Cash");
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {});
    },

    getExtendedSubscriptionsHandler(page) {
      this.isGettingSubsc = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/subscriptions/request-for-change?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.subscriptionList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSubsc = false;
        });
    },
  },
  created() {
    this.getExtendedSubscriptionsHandler();
    this.getPaymentModes();
  },
};
</script>
    
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.subsc-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-subsc-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input,
textarea,
select {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
.extend-label-div {
  padding: 8px 0 16px !important;
}
.extend-msg {
  font-size: 15px;
  margin-bottom: 10px;
}
.subsc-act-btn {
  width: 120px;
}

.subsc-act-delete:hover {
  background: #a60909;
  color: white;
  border: 1px solid #a60909;
}
</style>