<template>
  <div>
    <h3 class="heading--1">
      {{ companyToUpdate ? "Edit" : "Add New" }} Company
    </h3>
  </div>
  <div class="add-new-companies-form g-flex">
    <div class="form-left">
      <label>Company Name</label>
      <input
        type="text"
        placeholder="Enter company name"
        v-model="client_name"
      />
      <p v-if="companyErrors.client_name && !client_name" class="form-errors">
        Company name is required
      </p>

      <label>Company Email</label>
      <input type="email" placeholder="Enter company email" v-model="email" />
      <p v-if="companyErrors.email && !email" class="form-errors">
        Enter employee email please
      </p>

      <label>Company Phone Number</label>
      <input type="number" placeholder="Company phone number" v-model="phone" />
      <p v-if="companyErrors.phone && !phone" class="form-errors">
        Enter company phone number
      </p>

      <label>Company TIN Number</label>
      <input
        type="number"
        placeholder="Company TIN number"
        v-model="tin_number"
      />
      <p v-if="companyErrors.tin_number && !tin_number" class="form-errors">
        Company TIN number is require
      </p>

      <label>Company Website</label>
      <input type="url" placeholder="Company website" v-model="website" />
      <p v-if="companyErrors.website && !website" class="form-errors">
        Company website is required
      </p>

      <label>Phyisical address</label>
      <input
        type="text"
        placeholder="EX: Kigali-Gasabo-Remera OR road number"
        v-model="physical_address"
      />
      <p
        v-if="companyErrors.physical_address && !physical_address"
        class="form-errors"
      >
        Phyisical address is required
      </p>

      <label>Select Payment Type</label>
      <select v-model="payment_type">
        <option value="">-----Select payment type-----</option>
        <option value="Monthly">Monthly</option>
        <option value="Yearly">Annually(Per Year)</option>
      </select>
      <p v-if="companyErrors.payment_type && !payment_type" class="form-errors">
        Select payment type option
      </p>
      <div>
        <label>RDB Registration Certificate</label>
        <input type="file" placeholder="" @change="onRDBCertFileAdded" />
        <p
          v-if="
            companyErrors.registration_certificate && !registration_certificate
          "
          class="form-errors"
        >
          RDB Registration Certificate is required
        </p>
      </div>
    </div>
    <div class="form-right">
      <label>How many people to consume a package</label>
      <input
        type="number"
        placeholder="Enter number of consumers"
        v-model="slots"
      />
      <p v-if="companyErrors.slots && !slots" class="form-errors">
        Number of people who will consume a package is required
      </p>

      <label>Starting Date</label>
      <input type="date" placeholder="" v-model="starting_date" />
      <p
        v-if="companyErrors.starting_date && !starting_date"
        class="form-errors"
      >
        Starting date is required
      </p>

      <label>Name of Main Contact Person</label>
      <input
        type="text"
        placeholder="Name of the main person to contact"
        v-model="contact_person"
      />
      <p
        v-if="companyErrors.contact_person && !contact_person"
        class="form-errors"
      >
        Name of the main person to contact is required
      </p>

      <label>Post of Main Contact Person</label>
      <input
        type="text"
        placeholder="Post of the main person to contact"
        v-model="contact_person_title"
      />
      <p
        v-if="companyErrors.contact_person_title && !contact_person_title"
        class="form-errors"
      >
        Post of the main person to contact is required
      </p>

      <label>Phone of Main Contact Person</label>
      <input
        type="number"
        placeholder="Phone of the main person to contact"
        v-model="contact_person_phone"
      />
      <p
        v-if="companyErrors.contact_person_phone && !contact_person_phone"
        class="form-errors"
      >
        Phone of the main person to contact is required
      </p>

      <label>Email of Main Contact Person</label>
      <input
        type="email"
        placeholder="Email of the main person to contact"
        v-model="contact_person_email"
      />
      <p
        v-if="companyErrors.contact_person_email && !contact_person_email"
        class="form-errors"
      >
        Phone of the main person to contact is required
      </p>

      <div>
        <!-- <div>
          <label>RDB Registration Certificate</label>
          <input type="file" placeholder="" @change="onRDBCertFileAdded" />
          <p
            v-if="
              companyErrors.registration_certificate &&
              !registration_certificate
            "
            class="form-errors"
          >
            RDB Registration Certificate is required
          </p>
        </div> -->
        <div v-if="!companyToUpdate">
          <label>Contract File</label>
          <input type="file" placeholder="" @change="onContractFileAdded" />
          <p
            v-if="companyErrors.contract_document && !contract_document"
            class="form-errors"
          >
            Contract file is required
          </p>
        </div>
      </div>
    </div>
  </div>
  <div style="width: 150px; margin: 10px 0">
    <base-button
      :name="btnDisplayMsg"
      :isLoading="isMakingChangeOnCompany"
      :onClick="addNewCompanyHandler"
    ></base-button>
  </div>
</template>

<script>
import axios from "axios";
import BaseButton from "./reusable/BaseButton.vue";
import formatDate from "../mixins/formatDateMixin";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: { BaseButton },
  data() {
    return {
      client_name: "",
      phone: "",
      email: "",
      tin_number: "",
      website: "",
      physical_address: "",
      contact_person: "",
      contact_person_title: "",
      contact_person_phone: "",
      contact_person_email: "",
      payment_type: "",
      slots: "",
      starting_date: "",
      contract_document: null,
      registration_certificate: null,
      isMakingChangeOnCompany: false,
      companyErrors: {},
    };
  },
  computed: {
    btnDisplayMsg() {
      return this.companyToUpdate ? "Edit Company" : "Add Company";
    },
  },
  props: {
    returnOnSuccess: {
      type: Function,
    },
    companyToUpdate: {
      type: Object,
      default: null,
    },
  },
  mixins: [formatDate],
  methods: {
    validateForm() {
      this.companyErrors = {};

      if (!this.client_name) {
        this.companyErrors.client_name = true;
      }
      if (!this.phone) {
        this.companyErrors.phone = true;
      }
      if (!this.email) {
        this.companyErrors.email = true;
      }
      if (!this.contact_person) {
        this.companyErrors.contact_person = true;
      }
      if (!this.contact_person_title) {
        this.companyErrors.contact_person_title = true;
      }
      if (!this.contact_person_phone) {
        this.companyErrors.contact_person_phone = true;
      }
      if (!this.contact_person_email) {
        this.companyErrors.contact_person_email = true;
      }
      if (!this.tin_number) {
        this.companyErrors.tin_number = true;
      }
      if (!this.starting_date) {
        this.companyErrors.starting_date = true;
      }
      if (!this.registration_certificate) {
        this.companyErrors.registration_certificate = true;
      }
      if (!this.physical_address) {
        this.companyErrors.physical_address = true;
      }
      if (!this.website) {
        this.companyErrors.website = true;
      }
      if (!this.contract_document && !this.companyToUpdate) {
        this.companyErrors.contract_document = true;
      }
      if (!this.payment_type) {
        this.companyErrors.payment_type = true;
      }
      if (!this.slots) {
        this.companyErrors.slots = true;
      }

      return (
        !this.companyErrors.client_name &&
        !this.companyErrors.phone &&
        !this.companyErrors.email &&
        !this.companyErrors.contact_person_title &&
        !this.companyErrors.contact_person &&
        !this.companyErrors.contact_person_phone &&
        !this.companyErrors.contact_person_email &&
        !this.companyErrors.tin_number &&
        !this.companyErrors.starting_date &&
        !this.companyErrors.registration_certificate &&
        !this.companyErrors.physical_address &&
        !this.companyErrors.website &&
        !this.companyErrors.contract_document &&
        !this.companyErrors.payment_type &&
        !this.companyErrors.slots
      );
    },

    onContractFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.contract_document = files[0];
    },

    onRDBCertFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.registration_certificate = files[0];
    },

    addNewCompanyHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isMakingChangeOnCompany = true;

      let data = new FormData();

      data.append("client_name", this.client_name);
      data.append("phone", this.phone);
      data.append("email", this.email);
      data.append("contact_person_title", this.contact_person_title);
      data.append("contact_person", this.contact_person);
      data.append("contact_person_phone", this.contact_person_phone);
      data.append("contact_person_email", this.contact_person_email);
      data.append("tin_number", this.tin_number);
      data.append("starting_date", this.starting_date);
      data.append("website", this.website);
      data.append("physical_address", this.physical_address);
      data.append("payment_type", this.payment_type);
      data.append("slots", this.slots);
      if (this.companyToUpdate) {
        data.append("_method", "PUT");
      }
      if (typeof this.registration_certificate === "object") {
        data.append("registration_certificate", this.registration_certificate);
      }
      if (typeof this.contract_document === "object" && !this.companyToUpdate) {
        data.append("contract_document", this.contract_document);
      }

      let actionUrl = this.companyToUpdate
        ? `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.companyToUpdate.id}`
        : `${this.$store.state.apiUrl}/v1/uhuru/clients`;

      axios
        .post(actionUrl, data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.returnOnSuccess(response.data.data);
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnCompany = false;
        });
    },
  },
  created() {
    if (this.companyToUpdate) {
      this.client_name = this.companyToUpdate.client_name;
      this.phone = this.companyToUpdate.phone;
      this.email = this.companyToUpdate.email;
      this.contact_person_title = this.companyToUpdate.contact_person_title;
      this.contact_person = this.companyToUpdate.contact_person;
      this.contact_person_phone = this.companyToUpdate.contact_person_phone;
      this.contact_person_email = this.companyToUpdate.contact_person_email;
      this.tin_number = this.companyToUpdate.tin_number;
      this.starting_date = this.formatDate(this.companyToUpdate.starting_date);
      this.registration_certificate =
        this.companyToUpdate.registration_certificate;
      this.physical_address = this.companyToUpdate.physical_address;
      this.website = this.companyToUpdate.website;
      this.contract_document = this.companyToUpdate.contract;
      this.payment_type = this.companyToUpdate.payment_type;
      this.slots = this.companyToUpdate.slots;
    }
  },
};
</script>

<style scoped>
.heading--1 {
  margin: 10px 0;
  font-weight: 600;
  text-transform: uppercase;
}
input {
  margin-bottom: 10px;
}
select {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
  height: 26px;
}
.add-new-companies-form {
  width: 100%;
}
.add-new-companies-form .form-left {
  width: 50%;
  margin-right: 15px;
}
.add-new-companies-form .form-right {
  width: 50%;
  margin-left: 15px;
}
.company-form-files {
  margin: 20px 0;
  border: 1px solid #a8a8a8;
  padding: 20px;
  border-radius: 10px;
}
</style>