<template>
  <div>
    <main-header></main-header>
    <div class="my-health-cont">
      <div style="padding-bottom: 20px" v-if="patientDetails">
        <div class="my-health-user">
          <div
            class="my-health-user-top g-flex g-align-center g-content-center"
          >
            <user-circle
              :currentIndex="0"
              :currentUser="$store.state.authUser.name"
              marginRight="0"
              marginLeft="0"
              width="60px"
              height="60px"
            ></user-circle>
            <p>{{ $store.state.authUser.name }}</p>
          </div>
          <div
            class="my-health-user-bottom g-flex g-align-center g-content-center"
          >
            <p>
              <i class="fa-solid fa-location-dot"></i>
              {{ patientDetails.address }}
            </p>
            <p>
              <i class="fa-solid fa-envelope"></i>
              {{ patientDetails.employment_details.loginDetails.email }}
            </p>
            <p>
              <i class="fa-solid fa-square-phone"></i>
              {{ patientDetails.phone }}
            </p>
          </div>
        </div>

        <div class="my-health-tabs">
          <button
            :class="{ 'active-btn-tab': isActiveBtnTab == 'make_test' }"
            @click="toggleActiveTabHandler('make_test')"
          >
            Make Test
          </button>
          <button
            :class="{ 'active-btn-tab': isActiveBtnTab == 'view_result' }"
            @click="toggleActiveTabHandler('view_result')"
          >
            View Result
          </button>
          <!-- <button
            :class="{ 'active-btn-tab': isActiveBtnTab == 'given_advice' }"
            @click="toggleActiveTabHandler('given_advice')"
          >
            Given advice
          </button> -->
        </div>

        <div v-if="isActiveBtnTab == 'make_test'">
          <patient-quiz
            :toogleSubmittedResults="toogleSubmittedResults"
          ></patient-quiz>
        </div>
        <div v-if="isActiveBtnTab == 'view_result'">
          <p style="text-align: center">
            <patient-quiz-results></patient-quiz-results>
          </p>
        </div>
        <div v-if="isActiveBtnTab == 'given_advice'">
          <p style="text-align: center">
            This feature of advice from doctor is comming soon
          </p>
        </div>
      </div>
      <div
        style="width: 100%; padding-top: 20px"
        class="g-flex g-align-center g-content-center"
        v-if="isGettingMyInfo"
      >
        <table-data-loader></table-data-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import MainHeader from "@/components/MainHeader.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import PatientQuiz from "@/components/PatientQuiz.vue";
import PatientQuizResults from "@/components/PatientQuizResults.vue";
export default {
  components: {
    MainHeader,
    UserCircle,
    TableDataLoader,
    PatientQuiz,
    PatientQuizResults,
  },
  data() {
    return {
      patientDetails: null,
      isGettingMyInfo: null,
      isActiveBtnTab: "make_test",
    };
  },
  methods: {
    toggleActiveTabHandler(val) {
      this.isActiveBtnTab = val;
    },

    toogleSubmittedResults() {
      this.isActiveBtnTab = "view_result";
    },

    // TODO: Refactor this to be reusable
    getPatientDetailsHandler() {
      this.isGettingMyInfo = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/patients`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.patientDetails = response.data.data;
        })
        .catch((error) => {
          toast.error("You need to fill profile form to be able to procced", {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$router.push("/my-info-view");
        })
        .finally(() => {
          this.isGettingMyInfo = false;
        });
    },
  },
  created() {
    this.getPatientDetailsHandler();
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.my-health-cont {
  width: 80%;
  min-height: 100px;
  background: white;
  margin: 40px auto 0;
  border-radius: 10px;
}
.my-health-user-top {
  width: 100%;
  padding: 20px 0 10px 0;
  flex-direction: column;
}
.my-health-user-bottom {
  padding: 30px 0;
}
.my-health-user-bottom p {
  margin: 0 40px;
  color: #505050;
}
.my-health-tabs {
  width: fit-content;
  border: 1px solid var(--primary-color);
  margin: 10px auto 30px !important;
  border-radius: 20px;
}
.my-health-tabs button {
  width: 200px;
  background: transparent;
  border: 1px solid var(--primary-color);
  height: 34px;
}
.my-health-tabs button:first-child {
  border-radius: 20px 0 0 20px;
}
.my-health-tabs button:last-child {
  border-radius: 0 20px 20px 0;
}
.my-health-tabs button:hover {
  color: white;
  background: var(--primary-color);
  opacity: 0.8;
}
.active-btn-tab {
  background: var(--primary-color) !important;
  color: white !important;
}
</style>