<template>
  <div>
    <div class="pricing-top-header g-flex g-align-center">
      <div class="login-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="g-flex g-align-center">
        <p>{{ authUser.name }}</p>
        <user-circle
          :currentIndex="0"
          :currentUser="authUser.name"
          marginLeft="10px"
        ></user-circle>
      </div>
    </div>
    <all-packages></all-packages>
  </div>
</template>

<script>
import AllPackages from "@/components/AllPackages.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";

import { mapState } from "vuex";

export default {
  components: { AllPackages, UserCircle },
  computed: {
    ...mapState(["authUser"]),
  },
};
</script>

<style scoped>
.pricing-top-header {
  background: white;
  height: 80px;
  width: calc(100% - 40px);
  justify-content: space-between;
  padding: 0 20px;
}
.pricing-top-header img {
  width: 100px;
}
</style>