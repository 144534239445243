<template>
  <div>
    <h3>VIEW SINGLE PACKAGE</h3>
    <p><b>Name: </b>{{ selectedPackage.name }}</p>
    <p>
      <b>Monthly price: </b>{{ selectedPackage.price_per_month }} <i>Rwf</i>
    </p>
    <p><b>Annual price: </b>{{ selectedPackage.price_per_year }} <i>Rwf</i></p>
    <p><b>Details: </b>{{ selectedPackage.details }}</p>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
  computed: {},
  props: ["selectedPackage"],
  methods: {},
};
</script>

<style scoped>
h3 {
  border: 1px solid #ddd;
}
</style>