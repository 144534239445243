<template>
  <button @click="onClick" :disabled="isLoading">
    <btn-loader v-if="isLoading"></btn-loader>
    {{ !isLoading ? name : "" }}
  </button>
</template>

<script>
import BtnLoader from "./BtnLoader";
export default {
  components: { BtnLoader },
  props: {
    name: {
      type: String,
      default: "Click me",
    },
    onClick: {
      type: Function,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
button {
  background: var(--primary-color);
  color: #ffffff;
  border: none;
  padding: 10px 12px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
button:hover {
  background: #193f6b;
  cursor: pointer;
}
</style>