<template>
  <div>
    <main-header></main-header>
    <div class="my-doctors-cont">
      <h3 class="heading--1">MY DOCTORS</h3>
      <div class="my-doctors-search g-flex"></div>
      <div class="my-doctors-body">
        <div
          class="my-doctors-list"
          v-if="doctorsList.length && !isGettingDoctors"
        >
          <div
            class="single-patient-div"
            v-for="(item, index) in doctorsList"
            :key="item.id"
          >
            <!-- <img
              src="/images/Group_91.svg"
              alt=""
              class="single-patient-option"
              @click.stop="item.isShow = !item.isShow"
            /> -->
            <div>
              <user-circle
                :currentIndex="index"
                :currentUser="item.therapist_name"
                width="80px"
                height="80px"
              ></user-circle>
            </div>
            <p class="p--1">{{ item.therapist_name }}</p>
            <p>Email: {{ item.therapist_email }}</p>
            <p>Phone: {{ item.therapist_phone }}</p>
            <p>Assigned at: {{ item.assigned_at }}</p>
            <p>
              Status:
              <span
                :class="
                  item.status == 'Pending'
                    ? 'pending-color'
                    : item.status == 'On-going'
                    ? 'onGoing-color'
                    : item.status == 'Completed'
                    ? 'completed-color'
                    : 'rejected-color'
                "
                >{{ item.status }}</span
              >
            </p>
          </div>
        </div>
      </div>
      <div
        v-if="isGettingDoctors"
        style="width: 100%; margin-top: 20px"
        class="g-flex g-content-center"
      >
        <table-data-loader></table-data-loader>
      </div>
      <div class="uhuru-pg-container" v-if="!isGettingDoctors && hasNextPage">
        <vue-awesome-paginate
          :total-items="totalPgItems"
          :items-per-page="10"
          :max-pages-shown="8"
          v-model="currentPage"
          paginate-buttons-class="pg-btn"
          active-page-class="pg-btn-active"
          back-button-class="pg-back-btn"
          next-button-class="pg-next-btn"
          prev-button-content="Prev"
          next-button-content="Next"
          :on-click="getMyAssignedPatient"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";

import MainHeader from "@/components/MainHeader.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import LoadingLetter from "@/components/reusable/LoadingLetter.vue";
export default {
  components: {
    MainHeader,
    BaseButton,
    UserCircle,
    TableDataLoader,
    LoadingLetter,
  },
  data() {
    return {
      isGettingDoctors: false,
      doctorsList: [],

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    getMyAssignedPatient(page) {
      this.isGettingDoctors = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/assigned-therapists?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.doctorsList = response.data.data.data.map((item) => ({
            ...item,
            isShow: false,
          }));

          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingDoctors = false;
        });
    },
  },
  created() {
    this.getMyAssignedPatient();
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.my-doctors-cont {
  width: 80%;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.my-doctors-search {
  width: 470px;
  margin: 10px auto;
}
.my-doctors-search input {
  width: 300px;
  margin-right: 30px;
}
.my-doctors-body {
  border-top: 0.5px solid #a8a8a8;
  width: 100%;
  display: flex;
}
.my-doctors-list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: auto;
  gap: 10px;
}
.single-patient-div {
  border: 0.5px solid #a8a8a8;
  padding: 10px 10px 20px;
  position: relative;
}
.single-patient-div:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.make-active {
  background-color: #f5f5f5;
}
.single-patient-div > div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.single-patient-div p {
  text-align: center;
}
.p--1 {
  font-weight: 500;
}
.p--2 {
  font-size: 15px;
}
.my-doctors-details {
  padding: 20px;
}
.heading--1 {
  font-weight: 500;
}
.single-patient-option {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.patient-option-btn-div {
  position: absolute;
  right: 10px;
  top: 36px;
  display: flex;
  flex-direction: column;
  width: 180px;
  background: white;
  padding: 5px 0;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
}
.patient-option-btn-div button {
  text-align: left;
  background: transparent;
  border: none;
  height: 30px;
}
.patient-option-btn-div button:hover {
  background: var(--primary-color);
  color: white;
}

.pending-color,
.onGoing-color,
.completed-color,
.rejected-color {
  background: #b9960b;
  padding: 1px 10px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}
.pending-color {
  background: #b9960b;
}
.onGoing-color {
  background: #1e4b7d;
}
.completed-color {
  background: #5e8722;
}
.rejected-color {
  background: #a60909;
}
</style>