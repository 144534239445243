<template>
  <base-layout>
    <template #content>
      <div class="agents-header g-flex g-align-center g-space-btw">
        <h3>List of Agents</h3>

        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchAgents"
            @keyup.enter="searchTAgentsHandler"
          />
          <button @click="searchTAgentsHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

        <div style="width: 130px">
          <base-button
            name="Add new"
            :onClick="toggleAddNewAgentModal"
          ></base-button>
        </div>
      </div>
      <div style="padding: 0 10px" class="border--1">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Verified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="agentsList.length && !isGettingAgents">
            <tr v-for="(item, index) in agentsList" :key="item.id">
              <td class="g-flex g-align-center">
                <user-circle
                  :currentIndex="index"
                  :currentUser="item.name"
                  marginRight="10px"
                ></user-circle>
                {{ item.name }}
              </td>
              <td>{{ item.email }}</td>
              <td :class="item.active ? 'green-status ' : 'red-status'">
                {{ item.active ? "Active" : "Inactive" }}
              </td>
              <td :class="item.verified ? 'green-status ' : 'red-status'">
                {{ item.verified ? "Yes" : "No" }}
              </td>

              <td class="td-action">
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      @click="toggleUpdateAgent(item)"
                    >
                      Edit
                    </button>
                    <button
                      class="action-btn-item"
                      v-if="item.active"
                      @click="
                        toggleBlockUnblockHandler(item.id, 'block-access')
                      "
                    >
                      Block
                    </button>
                    <button
                      class="action-btn-item"
                      v-if="!item.active"
                      @click="toggleBlockUnblockHandler(item.id, 'unblock')"
                    >
                      Unblock
                    </button>
                    <button
                      class="action-btn-item"
                      @click="toggleDeleteWarningHandler(item.id)"
                    >
                      Delete
                    </button>
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div class="uhuru-pg-container" v-if="!isGettingAgents && hasNextPage">
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getAgentsListHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingAgents"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>
      <div v-if="isToggleAddNewAgent">
        <modal :closeModal="toggleAddNewAgentModal" width="380px">
          <template #content>
            <div>
              <h3 style="margin: 20px 0">
                {{ selectedAgentToEdit ? "Edit " : "Add New " }}Agent
              </h3>
            </div>
            <div>
              <label>Agent Name</label>
              <input
                type="text"
                placeholder="Enter agent name"
                v-model="name"
              />
              <p v-if="formErrors.name && !name" class="form-errors">
                Agent name is a required
              </p>

              <label>Email address</label>
              <input
                type="email"
                placeholder="Enter email address"
                v-model="email"
              />
              <p v-if="formErrors.email && !email" class="form-errors">
                Agent email address is required
              </p>
            </div>
            <div style="margin: 20px 0" v-if="!selectedAgentToEdit">
              <base-button
                name="Add Agent"
                :isLoading="isMakingChangeOnAgent"
                :onClick="addNewAgentHandler"
              ></base-button>
            </div>
            <div style="margin: 20px 0" v-if="selectedAgentToEdit">
              <base-button
                name="Edit Agent"
                :isLoading="isMakingChangeOnAgent"
                :onClick="addNewAgentHandler"
              ></base-button>
            </div>
          </template>
        </modal>
      </div>
      <div v-if="isToggleDeleteWarning">
        <warning-msg
          message="Are you sure you want to delete this agent"
          :onClick="deleteAgentHandler"
          :onClosingModal="toggleDeleteWarningHandler"
          :isOnLoading="isDeletingCompany"
        ></warning-msg>
      </div>
      <div v-if="isToggleBlockUnblockWarning">
        <warning-msg
          message="Are you sure you want change status of this agent"
          :onClick="block_unblockAgentHandler"
          :onClosingModal="toggleBlockUnblockHandler"
          :isOnLoading="isBlockingUnblocking"
        ></warning-msg>
      </div>
    </template>
  </base-layout>
</template>
      
<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    ActionsBtn,
    WarningMsg,
    UserCircle,
    UserCircle,
  },
  data() {
    return {
      isToggleAddNewAgent: false,
      isGettingAgents: false,
      agentsList: [],
      isDeletingCompany: false,
      selectedAgentToDelete: null,
      isToggleDeleteWarning: false,
      selectedAgentToEdit: null,
      formErrors: {},
      isMakingChangeOnAgent: false,
      block_unblock: "",
      selectedAgentToBlockUnblock: null,
      isToggleBlockUnblockWarning: false,
      isBlockingUnblocking: false,
      name: "",
      email: "",

      searchAgents: "",
      isOnSearchingAgents: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    searchTAgentsHandler() {
      if (!this.searchAgents) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }

      this.isOnSearchingAgents = true;
      this.getAgentsListHandler();
    },

    toggleAddNewAgentModal() {
      this.selectedAgentToEdit = null;
      this.name = "";
      this.email = "";
      this.isToggleAddNewAgent = !this.isToggleAddNewAgent;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.name) {
        this.formErrors.name = true;
      }
      if (!this.email) {
        this.formErrors.email = true;
      }

      return !this.formErrors.name && !this.formErrors.email;
    },

    addNewAgentHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnAgent = true;

      let data = {
        name: this.name,
        email: this.email,
        role: "Super Agent",
      };

      if (this.selectedAgentToEdit) data._method = "PUT";

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/agents${
            this.selectedAgentToEdit ? "/" + this.selectedAgentToEdit : ""
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          if (this.selectedAgentToEdit) {
            this.agentsList = this.agentsList.map((item) => {
              if (item.id == this.selectedAgentToEdit) {
                return response.data.data;
              } else {
                return item;
              }
            });
          } else {
            this.agentsList.push(response.data.data);
          }
          this.toggleAddNewAgentModal();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnAgent = false;
        });
    },

    getAgentsListHandler(page) {
      this.isGettingAgents = true;

      const actionUrl = this.isOnSearchingAgents
        ? `${this.$store.state.apiUrl}/v1/uhuru/agents?search=${this.searchAgents}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/uhuru/agents?page=${page}`;

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.agentsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingAgents = false;
        });
    },

    toggleDeleteWarningHandler(item) {
      this.selectedAgentToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteAgentHandler() {
      this.isDeletingCompany = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/agents/${this.selectedAgentToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.agentsList = this.agentsList.filter(
            (item) => item.id !== this.selectedAgentToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingCompany = false;
          this.toggleDeleteWarningHandler();
        });
    },

    toggleUpdateAgent(item) {
      this.selectedAgentToEdit = item.id;
      this.name = item.name;
      this.email = item.email;
      this.isToggleAddNewAgent = !this.isToggleAddNewAgent;
    },

    toggleBlockUnblockHandler(item, block_unblock) {
      this.selectedAgentToBlockUnblock = item;
      this.block_unblock = block_unblock;
      this.isToggleBlockUnblockWarning = !this.isToggleBlockUnblockWarning;
    },

    block_unblockAgentHandler() {
      this.isBlockingUnblocking = true;

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/uhuru/agents/${this.selectedAgentToBlockUnblock}/${this.block_unblock}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.agentsList = this.agentsList.map((item) => {
            if (item.id == this.selectedAgentToBlockUnblock) {
              return response.data.data;
            } else {
              return item;
            }
          });

          this.isToggleBlockUnblockWarning = !this.isToggleBlockUnblockWarning;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isBlockingUnblocking = false;
        });
    },
  },
  created() {
    this.getAgentsListHandler();
  },
};
</script>
      
    <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.agents-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}
.g-filter-cont select {
  border: none !important;
  width: 120px;
  /* background: #f5f5f5; */
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-agents-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
.border--1 {
  border: 1px solid #dddddd;
}
input {
  margin-bottom: 10px;
}
</style>