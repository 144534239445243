<template>
  <base-layout>
    <template #content>
      <div class="employee-header g-flex g-align-center g-space-btw">
        <h3>Company payments</h3>
        <div class="g-flex g-align-center">
          <label>From</label>
          <input
            type="date"
            v-model="paymentsFrom"
            @input="searchByRangeOfDates"
          />
          <label style="margin-left: 6px">To</label>
          <input
            type="date"
            v-model="paymentsTo"
            @input="searchByRangeOfDates"
          />
        </div>
        <div style="width: 130px">
          <base-button
            name="Add Payment"
            @click="toggleAddNewPayment"
          ></base-button>
        </div>
      </div>
      <div style="padding: 0 10px">
        <table>
          <thead>
            <tr>
              <th>Paid date</th>
              <th>Paid amount</th>
              <th>Payment mode</th>
              <th>Reference number</th>
              <th>Payment month</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="paymentsList.length && !isGettingPayments">
            <tr v-for="item in paymentsList" :key="item.id">
              <td class="g-flex g-align-center">
                {{ formatDate(item.payment_date) }}
              </td>
              <td>{{ formatMoney(item.paid_amount) }}</td>
              <td>{{ item.payment_mode }}</td>
              <td>{{ item.reference_number || "-" }}</td>
              <td>
                {{ item.payment_month }}
              </td>
              <td
                :class="
                  item.status == 'Approved' ? 'green-status' : 'red-status'
                "
              >
                {{ item.status }}
              </td>
              <td>
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      v-if="item.notes"
                      @click="togglePaymentNoteHandler(item.notes)"
                    >
                      View note
                    </button>
                    <a target="_blank" :href="item.proof" v-if="item.proof">
                      <button class="action-btn-item">View Proof</button>
                    </a>
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingPayments && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getCompanyPaymentsHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingPayments"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>

      <div v-if="isToggleAddPayment">
        <modal :closeModal="toggleAddNewPayment" width="360px">
          <template #content>
            <div>
              <h3 class="heading--1">Add New Payment</h3>
            </div>
            <label>Paid Date</label>
            <input type="date" placeholder="" v-model="payment_date" />
            <p
              v-if="formErrors.payment_date && !payment_date"
              class="form-errors"
            >
              Paid date is required
            </p>

            <label>Amount</label>
            <input
              type="number"
              placeholder="Enter amount"
              readonly
              v-model="paid_amount"
            />
            <p
              v-if="formErrors.paid_amount && !paid_amount"
              class="form-errors"
            >
              Amount paid is required
            </p>

            <label>Payment Mode</label>
            <select v-model="payment_mode">
              <option value="">-----Select option-----</option>
              <option :value="item" v-for="item in paymentModes" :key="item">
                {{ item }}
              </option>
            </select>
            <p
              v-if="formErrors.payment_mode && !payment_mode"
              class="form-errors"
            >
              Select payment mode please
            </p>

            <label>Reference Number</label>
            <input
              type="text"
              placeholder="Enter reference number"
              v-model="reference_number"
            />

            <label>Small Note</label>
            <textarea v-model="notes" placeholder="Write small note"></textarea>
            <p v-if="formErrors.notes && !notes" class="form-errors">
              Small note is required
            </p>

            <label>Proof File</label>
            <input type="file" placeholder="" @change="onProofFileAdded" />

            <div style="width: 150px; margin-bottom: 10px">
              <base-button
                name="Add Payment"
                :isLoading="isMakingChangeOnPayment"
                :onClick="addNewPaymentHandler"
              ></base-button>
            </div>
          </template>
        </modal>
      </div>

      <div v-if="isViewPaymentNote">
        <modal :closeModal="togglePaymentNoteHandler" width="360px">
          <template #content>
            <p style="margin-bottom: 10px">
              <b style="font-weight: 500">PAYMENT NOTE</b>
            </p>
            <p>{{ selectedNote }}</p>
          </template>
        </modal>
      </div>
    </template>
  </base-layout>
</template>
      
  <script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import formatMoney from "@/mixins/formatMoney";

export default {
  components: {
    BaseLayout,
    TableDataLoader,
    ActionsBtn,
    BaseButton,
    Modal,
  },
  data() {
    return {
      isGettingPayments: false,
      paymentsList: [],
      isToggleAddPayment: false,
      isMakingChangeOnPayment: false,

      // payment form
      paid_amount: "",
      payment_date: "",
      payment_mode: "",
      reference_number: "",
      payment_month: "",
      notes: "",
      proof: "",
      formErrors: {},

      paymentModes: null,

      isViewPaymentNote: false,
      selectedNote: "",

      paymentsFrom: "",
      paymentsTo: this.formatDate(new Date()),
      isOnSearchingPayments: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin, formatMoney],
  methods: {
    searchByRangeOfDates() {
      if (!this.paymentsFrom) return;
      if (!this.paymentsFrom || !this.paymentsTo) {
        return toast.error("Please select range of date", {
          theme: "colored",
        });
      }
      this.isOnSearchingPayments = true;
      this.getCompanyPaymentsHandler();
    },

    togglePaymentNoteHandler(item) {
      this.selectedNote = item;
      this.isViewPaymentNote = !this.isViewPaymentNote;
    },

    toggleAddNewPayment() {
      this.isToggleAddPayment = !this.isToggleAddPayment;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.paid_amount) {
        this.formErrors.paid_amount = true;
      }
      if (!this.payment_date) {
        this.formErrors.payment_date = true;
      }
      if (!this.payment_mode) {
        this.formErrors.payment_mode = true;
      }

      return (
        !this.formErrors.paid_amount &&
        !this.formErrors.payment_date &&
        !this.formErrors.payment_mode
      );
    },

    onProofFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.proof = files[0];
    },

    addNewPaymentHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isMakingChangeOnPayment = true;

      let data = new FormData();

      data.append("paid_amount", this.paid_amount);
      data.append("payment_date", this.payment_date);
      data.append("payment_mode", this.payment_mode);
      data.append("reference_number", this.reference_number);
      data.append("notes", this.notes);

      if (typeof this.proof === "object") {
        data.append("proof", this.proof);
      }

      axios
        .post(`${this.$store.state.apiUrl}/v1/add-payment`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.paymentsList.push(response.data.data);
          this.isToggleAddPayment = false;
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnPayment = false;
        });
    },

    getCompanyPaymentsHandler(page) {
      this.isGettingPayments = true;

      const actionUrl = this.isOnSearchingPayments
        ? `${this.$store.state.apiUrl}/v1/my-payments?range_from=${this.paymentsFrom}&range_until=${this.paymentsTo}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/my-payments?page=${page}`;
      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.paymentsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPayments = false;
          this.isOnSearchingPayments = false;
        });
    },

    getCompanyProfile() {
      this.isGettingCompanyProfile = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/dashboard/profile`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          if (res.data.data.payment_type == "Monthly") {
            this.paid_amount =
              res.data.data.monthly_payment * res.data.data.slots;
          } else {
            this.paid_amount = res.data.data.total_amount;
          }
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingCompanyProfile = false;
        });
    },

    getPaymentModes() {
      axios
        .get(`${this.$store.state.apiUrl}/v1/payments-mode`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          this.paymentModes = res.data.data.filter((item) => item != "Cash");
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {});
    },
  },
  created() {
    this.getCompanyPaymentsHandler();
    this.getCompanyProfile();
    this.getPaymentModes();
  },
};
</script>
      
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.heading--1 {
  margin: 10px 0;
  font-weight: 600;
  text-transform: uppercase;
}
.employee-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-employee-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input,
textarea,
select {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
</style>