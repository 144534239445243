<template>
  <button @click="onClick" :disabled="isLoading">
    <btn-loader v-if="isLoading"></btn-loader>
    {{ !isLoading ? name : "" }}
  </button>
</template>

<script>
import BtnLoader from "./BtnLoader.vue";
export default {
  components: { BtnLoader },
  props: {
    name: {
      type: String,
      default: "Click me",
    },
    onClick: { type: Function },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped>
button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 10px 30px;
  border-radius: 4px;
  font-size: 16px;
  width: 100%;
}
button:hover {
  background: #1e4b7d;
  cursor: pointer;
  color: #ffffff;
}
</style>