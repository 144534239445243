<template>
  <div>
    <div class="subsc-header g-flex g-align-center g-space-btw">
      <h3>Company Subscriptions</h3>
      <div class="g-flex g-align-center">
        <label>From</label>
        <input type="date" v-model="subscsFrom" @input="searchByRangeOfDates" />
        <label style="margin-left: 6px">To</label>
        <input type="date" v-model="subscsTo" @input="searchByRangeOfDates" />
      </div>
      <div style="width: 130px">
        <base-button
          name="Add new"
          @click="toggleAddnewSubscription"
        ></base-button>
      </div>
    </div>
    <div style="padding: 0 10px">
      <table>
        <thead>
          <tr>
            <th>Payment type</th>
            <th>Starting date</th>
            <th>Ending date</th>
            <th>Total annual amount</th>
            <th>Consumers</th>
            <th>Status</th>
            <th>Cancelled</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="companySubsc.length && !isGettingSubscs">
          <tr v-for="item in companySubsc" :key="item.id">
            <td>
              {{ item.payment_type }}
              <span
                class="py-warning"
                v-if="checkSubscOverdue(item.subscription_payments)"
              >
                has to pay
              </span>
            </td>
            <td>{{ formatDate(item.starting_date) }}</td>
            <td>{{ formatDate(item.ending_date) }}</td>
            <td>{{ formatMoney(item.total_amount) }}</td>
            <td>{{ item.slots }}</td>
            <td
              :class="item.status == 'Active' ? 'green-status' : 'red-status'"
            >
              {{ item.status }}
            </td>

            <td :class="item.cancelled ? 'green-status' : 'red-status'">
              {{ item.cancelled ? "Yes" : "No" }}
            </td>
            <td>
              <actions-btn>
                <template #content>
                  <router-link :to="'/extended-subscription/' + item.id">
                    <button class="action-btn-item">View Extended</button>
                  </router-link>
                  <button
                    class="action-btn-item"
                    @click="toggleViewPaymentsHandler(item.id)"
                  >
                    Payments
                  </button>
                  <button
                    class="action-btn-item"
                    v-if="item.status !== 'Active'"
                    @click="toggleApproveSubscription(item.id)"
                  >
                    Approve
                  </button>
                  <button
                    class="action-btn-item"
                    @click="toggleUpdateSubscription(item)"
                  >
                    Edit
                  </button>
                  <button
                    class="action-btn-item"
                    @click="toggleDeleteSubscription(item.id)"
                  >
                    Delete
                  </button>
                  <a target="_blank" :href="item.contract">
                    <button class="action-btn-item">View Contract</button>
                  </a>
                </template>
              </actions-btn>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="uhuru-pg-container" v-if="!isGettingSubscs && hasNextPage">
      <vue-awesome-paginate
        :total-items="totalPgItems"
        :items-per-page="10"
        :max-pages-shown="8"
        v-model="currentPage"
        paginate-buttons-class="pg-btn"
        active-page-class="pg-btn-active"
        back-button-class="pg-back-btn"
        next-button-class="pg-next-btn"
        prev-button-content="Prev"
        next-button-content="Next"
        :on-click="getCompanySubscsHandler"
      />
    </div>

    <div
      style="width: 100%; margin: 10px"
      class="g-flex g-align-center g-content-center"
      v-if="isGettingSubscs"
    >
      <table-data-loader></table-data-loader>
    </div>

    <div v-if="isAddNewSubscription">
      <modal :closeModal="toggleAddnewSubscription" width="350px">
        <template #content>
          <div>
            <h3 style="margin: 20px 0">
              {{ subscToUpdate ? "Edit" : "Add New" }} Subscription
            </h3>
          </div>
          <div class="add-new-employee-form">
            <label>Starting date</label>
            <input type="date" placeholder="" v-model="starting_date" />
            <p
              v-if="subscErrors.starting_date && !starting_date"
              class="form-errors"
            >
              Enter the starting date
            </p>

            <label>Package Consumers</label>
            <input
              type="number"
              placeholder="Enter the number of package consumers"
              v-model="slots"
            />
            <p v-if="subscErrors.slots && !slots" class="form-errors">
              Number of people who will consume a package is required
            </p>

            <label>Select Payment Type</label>
            <select v-model="payment_type">
              <option value="">-----Select payment type-----</option>
              <option value="Monthly">Monthly</option>
              <option value="Yearly">Annually(Per Year)</option>
            </select>
            <p
              v-if="subscErrors.payment_type && !payment_type"
              class="form-errors"
            >
              Select payment type option for company
            </p>
          </div>
          <div v-if="subscToUpdate">
            <div>
              <label>Select Status</label>
              <select v-model="subscStatus">
                <option value="" disabled>-----Select payment type-----</option>
                <option value="Active">Active</option>
                <option value="Inactive">Inactive</option>
                <option value="Cancelled">Cancelled</option>
                <option value="Expired">Expired</option>
                <option value="Draft">Draft</option>
                <option value="Pending">Pending</option>
              </select>
              <p
                v-if="subscErrors.payment_type && !payment_type"
                class="form-errors"
              >
                To select status option is required
              </p>
            </div>
            <div>
              <label>Subscription Cancelled</label>
              <div style="margin-bottom: 10px">
                <label class="switch">
                  <input type="checkbox" v-model="subscCancelled" checked />
                  <span class="slider round"></span>
                </label>
              </div>
            </div>
          </div>
          <div>
            <label> Contract File</label>
            <input type="file" placeholder="" @change="onContractFileAdded" />
            <p v-if="subscErrors.contract && !contract" class="form-errors">
              Contract file is required
            </p>
          </div>
          <div style="margin: 20px 0" v-if="!subscToUpdate">
            <base-button
              name="Add Subscription"
              :isLoading="isMakingChangeOnSubscription"
              :onClick="addNewSubscriptionHandler"
            ></base-button>
          </div>
          <div style="margin: 20px 0" v-if="subscToUpdate">
            <base-button
              name="Edit Subscription"
              :isLoading="isMakingChangeOnSubscription"
              :onClick="addNewSubscriptionHandler"
            ></base-button>
          </div>
        </template>
      </modal>
    </div>

    <div v-if="isToggleApproveWarning">
      <warning-msg
        message="Are you sure you want to approve this subscription"
        :onClick="approveSubscriptionHandler"
        :onClosingModal="toggleApproveSubscription"
        :isOnLoading="isApprovingSubsc"
      ></warning-msg>
    </div>

    <div v-if="isToggleDeleteeWarning">
      <warning-msg
        message="Are you sure you want to delete this subscription"
        :onClick="deleteSubscriptionHandler"
        :onClosingModal="toggleDeleteSubscription"
        :isOnLoading="isDeletingSubsc"
      ></warning-msg>
    </div>

    <div v-if="isToggleViewPayments">
      <modal :closeModal="toggleViewPaymentsHandler" width="900px">
        <template #content>
          <admin-vue-subsc-payments
            :subscToViewPayments="subscToViewPayments"
          ></admin-vue-subsc-payments>
        </template>
      </modal>
    </div>
  </div>
</template>
    
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "./reusable/WarningMsg.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import formatMoney from "@/mixins/formatMoney";
import AdminVueSubscPayments from "./AdminVueSubscPayments.vue";
import TableDataLoader from "./reusable/TableDataLoader.vue";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    ActionsBtn,
    WarningMsg,
    AdminVueSubscPayments,
    TableDataLoader,
  },
  data() {
    return {
      isAddNewSubscription: false,
      starting_date: "",
      slots: "",
      payment_type: "",
      subscToUpdate: null,
      clientId: this.$route.params.id,
      isMakingChangeOnSubscription: false,
      subscErrors: {},
      isToggleApproveWarning: false,
      isApprovingSubsc: false,
      subscToApprove: "",
      subscStatus: "",
      subscCancelled: false,
      contract: "",
      subscToDelete: "",
      isToggleDeleteeWarning: false,
      isDeletingSubsc: false,

      isToggleViewPayments: false,
      subscToViewPayments: "",
      companySubsc: [],
      isGettingSubscs: false,

      subscsFrom: "",
      subscsTo: this.formatDate(new Date()),
      isOnSearchingSubscs: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  props: [
    "returnOnSuccess",
    "retrunUpdateSubscription",
    "returnDeleteSubscription",
  ],
  mixins: [formatDateMixin, formatMoney],
  methods: {
    checkSubscOverdue(subscPayment) {
      if (subscPayment !== "N/A") {
        return subscPayment.overdue == "false" ? false : true;
      }
    },

    searchByRangeOfDates() {
      if (!this.subscsFrom) return;
      if (!this.subscsFrom || !this.subscsTo) {
        return toast.error("Please select range of date", {
          theme: "colored",
        });
      }
      this.isOnSearchingSubscs = true;
      this.getCompanySubscsHandler();
    },

    toggleViewPaymentsHandler(id) {
      this.subscToViewPayments = id;
      this.isToggleViewPayments = !this.isToggleViewPayments;
    },

    toggleDeleteSubscription(id) {
      this.subscToDelete = id;
      this.isToggleDeleteeWarning = !this.isToggleDeleteeWarning;
    },

    deleteSubscriptionHandler() {
      this.isDeletingSubsc = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.subscToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.returnDeleteSubscription(this.subscToDelete);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingSubsc = false;
          this.isToggleDeleteeWarning = !this.isToggleDeleteeWarning;
        });
    },

    toggleAddnewSubscription() {
      this.subscToUpdate = false;
      this.isAddNewSubscription = !this.isAddNewSubscription;
    },

    onContractFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.contract = files[0];
    },

    validateForm() {
      this.subscErrors = {};

      if (!this.starting_date) {
        this.subscErrors.starting_date = true;
      }
      if (!this.slots) {
        this.subscErrors.slots = true;
      }
      if (!this.payment_type) {
        this.subscErrors.payment_type = true;
      }
      if (!this.contract) {
        this.subscErrors.contract = true;
      }

      return (
        !this.subscErrors.starting_date &&
        !this.subscErrors.slots &&
        !this.subscErrors.payment_type &&
        !this.subscErrors.contract
      );
    },

    addNewSubscriptionHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isMakingChangeOnSubscription = true;

      let data = new FormData();

      data.append("starting_date", this.starting_date);
      data.append("slots", this.slots);
      data.append("payment_type", this.payment_type);
      if (this.subscToUpdate) {
        data.append("_method", "PUT");
        data.append("status", this.subscStatus);
        data.append("cancelled", this.subscCancelled ? 1 : 0);
      }

      if (typeof this.contract === "object") {
        data.append("contract", this.contract);
      }

      let actionUrl = this.subscToUpdate
        ? `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.subscToUpdate}/update`
        : `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/add-new-subscription`;

      axios
        .post(actionUrl, data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          if (this.subscToUpdate) {
            this.retrunUpdateSubscription(response.data.data);
          } else {
            this.returnOnSuccess(response.data.data);
          }
          this.isAddNewSubscription = false;
          this.starting_date = "";
          this.slots = "";
          this.payment_type = "";
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnSubscription = false;
        });
    },

    approveSubscriptionHandler() {
      this.isApprovingSubsc = true;

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.subscToApprove}/change-status?status=Active`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.getCompanySubscsHandler();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isApprovingSubsc = false;
          this.toggleApproveSubscription();
        });
    },

    toggleApproveSubscription(id) {
      this.subscToApprove = id;
      this.isToggleApproveWarning = !this.isToggleApproveWarning;
    },

    toggleUpdateSubscription(item) {
      this.subscToUpdate = item.id;
      this.starting_date = this.formatDate(item.starting_date);
      this.slots = item.slots;
      this.payment_type = item.payment_type;
      this.subscStatus = item.status;
      this.subscCancelled = item.cancelled;
      this.contract = item.contract;
      this.isAddNewSubscription = !this.isAddNewSubscription;
    },

    getCompanySubscsHandler(page) {
      this.isGettingSubscs = true;
      const actionUrl = this.isOnSearchingSubscs
        ? `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/subscriptions?from=${this.subscsFrom}&until=${this.subscsTo}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}/subscriptions?page=${page}`;
      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.companySubsc = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSubscs = false;
          this.isOnSearchingSubscs = false;
        });
    },
  },
  created() {
    this.getCompanySubscsHandler();
  },
};
</script>
    
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.subsc-header {
  margin: 20px;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-subsc-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
select {
  margin-bottom: 10px;
}

/* Switch on and off */
.on {
  color: #0294ff;
  padding-left: 8px;
  padding-top: 3px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 55px;
  height: 26px;
  min-width: 55px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid #0294ff;
  background-color: white;
  -webkit-transition: 0.4s;
  -o-transition: 0.4s;
  transition: 0.4s;
}

.ticket-desable {
  opacity: 0.6 !important;
  cursor: not-allowed !important;
}

.slider:before {
  position: absolute;
  content: "";
  height: 18px !important;
  width: 18px !important;
  top: 2px !important;
  left: 3px !important;
  border: 1px solid #0294ff !important;
  background-color: white !important;
  -webkit-transition: 0.4s !important;
  -o-transition: 0.4s !important;
  transition: 0.4s !important;
}

input:checked + .slider {
  background-color: rgb(247, 247, 247) !important;
}

input:focus + .slider {
  -webkit-box-shadow: 0 0 1px rgb(247, 247, 247) !important;
  box-shadow: 0 0 1px rgb(247, 247, 247) !important;
}

input:checked + .slider:after {
  content: "Yes" !important;
  position: relative !important;
  top: 1px;
  right: -5px;
  color: #0294ff;
}
input:not(:checked) + .slider:after {
  content: "No" !important;
  position: absolute !important;
  right: 8px !important;
  top: 1px;
  color: #0294ff;
}

input:checked + .slider:before {
  -webkit-transform: translateX(29px) !important;
  -ms-transform: translateX(29px) !important;
  transform: translateX(29px) !important;
}

/* Rounded sliders */
.slider.round {
  border-radius: 26px !important;
}

.slider.round:before {
  border-radius: 50% !important;
}
.py-warning {
  background: #b9960b;
  color: #ffffff;
  padding: 0 8px;
  font-size: 13px;
  border-radius: 10px;
}
</style>