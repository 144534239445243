<template>
  <div>
    <div>
      <div class="g-flex g-align-center g-space-btw">
        <h3 style="margin: 0">List of Packages</h3>
        <div style="width: 130px; margin-bottom: 10px">
          <base-button
            name="Add New"
            :onClick="toggleAddNewPackageModal"
          ></base-button>
        </div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Details</th>
            <th>Monthly</th>
            <th>Annual</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="packagesList.length && !isGettingPackages">
          <tr v-for="item in packagesList" :key="item.id">
            <td>{{ item.name }}</td>
            <td>{{ item.details }}</td>
            <td>{{ item.price_per_month }} RWF</td>
            <td>{{ item.price_per_year }} RWF</td>
            <td class="td-action">
              <actions-btn>
                <template #content>
                  <button
                    class="action-btn-item"
                    @click="selectedPackageToViewHandler(item)"
                  >
                    View
                  </button>
                  <button
                    class="action-btn-item"
                    @click="toggleUpdateSinglePackageHandler(item)"
                  >
                    Edit
                  </button>
                  <button
                    class="action-btn-item"
                    @click="toggleDeleteWarningHandler(item.id)"
                  >
                    Delete
                  </button>
                </template>
              </actions-btn>
            </td>
          </tr>
        </tbody>
      </table>

      <div class="uhuru-pg-container" v-if="!isGettingPackages && hasNextPage">
        <vue-awesome-paginate
          :total-items="totalPgItems"
          :items-per-page="10"
          :max-pages-shown="8"
          v-model="currentPage"
          paginate-buttons-class="pg-btn"
          active-page-class="pg-btn-active"
          back-button-class="pg-back-btn"
          next-button-class="pg-next-btn"
          prev-button-content="Prev"
          next-button-content="Next"
          :on-click="getUhuruPackages"
        />
      </div>

      <div
        style="width: 100%; margin: 10px"
        class="g-flex g-align-center g-content-center"
        v-if="isGettingPackages"
      >
        <table-data-loader></table-data-loader>
      </div>
    </div>
    <div v-if="isToggleAddNewPackage">
      <modal :closeModal="toggleAddNewPackageModal" width="350px">
        <template #content>
          <div>
            <h3 style="margin: 20px 0">
              {{ selectedPackageToEdit ? "Edit Package" : "Add New Package" }}
            </h3>
          </div>
          <div class="add-new-package-form">
            <label>Package Name</label>
            <input
              type="text"
              placeholder="Enter package name"
              v-model="packageName"
            />
            <p
              v-if="addPackageErrors.packageName && !packageName"
              class="form-errors"
            >
              Enter package name
            </p>
            <label>Monthly Price</label>
            <input
              type="number"
              placeholder="Enter monthly price"
              v-model="monthlyPrice"
            />
            <p
              v-if="addPackageErrors.monthlyPrice && !monthlyPrice"
              class="form-errors"
            >
              Enter monthly price
            </p>
            <label>Price per Year</label>
            <input
              type="number"
              placeholder="Enter annual price"
              v-model="annualPrice"
            />
            <p
              v-if="addPackageErrors.annualPrice && !annualPrice"
              class="form-errors"
            >
              Enter monthly price
            </p>
            <label>Package Details</label>
            <textarea
              v-model="packageDetails"
              placeholder="Enter package details"
            ></textarea>
            <p
              v-if="addPackageErrors.packageDetails && !packageDetails"
              class="form-errors"
            >
              Enter package details
            </p>
          </div>
          <div style="margin: 20px 0" v-if="!selectedPackageToEdit">
            <base-button
              name="Add Package"
              :isLoading="isMakingChangeOnPackage"
              :onClick="addNewPackageHandler"
            ></base-button>
          </div>
          <div style="margin: 20px 0" v-if="selectedPackageToEdit">
            <base-button
              name="Edit Package"
              :isLoading="isMakingChangeOnPackage"
              :onClick="editSinglePackageHandler"
            ></base-button>
          </div>
        </template>
      </modal>
    </div>
    <div v-if="isViewSinglePackageModal">
      <modal :closeModal="toggleViewSinglePackageModal" width="600px">
        <template #content>
          <single-package
            :selectedPackage="selectedPackageToView"
          ></single-package>
        </template>
      </modal>
    </div>
  </div>
  <div v-if="isToggleDeleteWarning">
    <warning-msg
      message="Are you sure you want to delete this package"
      :onClick="deletePackageHandler"
      :onClosingModal="toggleDeleteWarningHandler"
      :isOnLoading="isDeletingPackage"
    ></warning-msg>
  </div>
</template>

<script>
import axios from "axios";
import TableDataLoader from "./reusable/TableDataLoader.vue";
import BaseButton from "./reusable/BaseButton.vue";
import Modal from "./reusable/Modal.vue";
import SinglePackage from "./SinglePackage.vue";
import ActionsBtn from "./reusable/ActionsBtn.vue";
import WarningMsg from "./reusable/WarningMsg.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    TableDataLoader,
    BaseButton,
    Modal,
    SinglePackage,
    ActionsBtn,
    WarningMsg,
  },
  data() {
    return {
      isGettingPackages: false,
      packagesList: [],
      isToggleAddNewPackage: false,
      packageName: "",
      packageDetails: "",
      monthlyPrice: "",
      annualPrice: "",
      addPackageErrors: {},
      isMakingChangeOnPackage: false,
      isViewSinglePackageModal: false,
      selectedPackageToView: null,
      isDeletingPackage: false,
      isToggleDeleteWarning: false,
      selectedPackageToDelete: "",
      selectedPackageToEdit: null,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    getUhuruPackages(page) {
      this.isGettingPackages = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/uhuru/pricing?page=${page}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.packagesList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPackages = false;
        });
    },

    toggleAddNewPackageModal() {
      this.selectedPackageToEdit = null;
      this.clearPackageForm();
      this.isToggleAddNewPackage = !this.isToggleAddNewPackage;
    },

    validateAddPackageForm() {
      this.addPackageErrors = {};

      if (!this.packageName) {
        this.addPackageErrors.packageName = true;
      }
      if (!this.packageDetails) {
        this.addPackageErrors.packageDetails = true;
      }
      if (!this.monthlyPrice) {
        this.addPackageErrors.monthlyPrice = true;
      }
      if (!this.annualPrice) {
        this.addPackageErrors.annualPrice = true;
      }

      return (
        !this.addPackageErrors.packageName &&
        !this.addPackageErrors.packageDetails &&
        !this.addPackageErrors.monthlyPrice &&
        !this.addPackageErrors.annualPrice
      );
    },

    clearPackageForm() {
      this.packageName = "";
      this.packageDetails = "";
      this.monthlyPrice = "";
      this.annualPrice = "";
    },

    addNewPackageHandler() {
      if (!this.validateAddPackageForm()) {
        return;
      }

      this.isMakingChangeOnPackage = true;

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/pricing?name=${this.packageName}&details=${this.packageDetails}&price_per_month=${this.monthlyPrice}&price_per_year=${this.annualPrice}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.packagesList.push(response.data.data);
          this.toggleAddNewPackageModal();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnPackage = false;
        });
    },

    toggleUpdateSinglePackageHandler(item) {
      this.selectedPackageToEdit = item.id;
      this.packageName = item.name;
      this.packageDetails = item.details;
      this.monthlyPrice = item.price_per_month.replace(/,/g, "");
      this.annualPrice = item.price_per_year.replace(/,/g, "");
      this.isToggleAddNewPackage = true;
    },

    editSinglePackageHandler() {
      if (!this.validateAddPackageForm()) {
        return;
      }

      this.isMakingChangeOnPackage = true;

      axios
        .patch(
          `${this.$store.state.apiUrl}/v1/uhuru/pricing/${this.selectedPackageToEdit}?name=${this.packageName}&details=${this.packageDetails}&price_per_month=${this.monthlyPrice}&price_per_year=${this.annualPrice}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.packagesList = this.packagesList.map((item) => {
            if (item.id == this.selectedPackageToEdit) {
              return response.data.data;
            }
            return item;
          });
          this.clearPackageForm();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnPackage = false;
          this.toggleAddNewPackageModal();
        });
    },

    toggleDeleteWarningHandler(item) {
      this.selectedPackageToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deletePackageHandler() {
      this.isDeletingPackage = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/pricing/${this.selectedPackageToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.packagesList = this.packagesList.filter(
            (item) => item.id !== this.selectedPackageToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingPackage = false;
          this.toggleDeleteWarningHandler();
        });
    },

    toggleViewSinglePackageModal() {
      this.isViewSinglePackageModal = !this.isViewSinglePackageModal;
    },

    selectedPackageToViewHandler(item) {
      this.selectedPackageToView = item;
      this.toggleViewSinglePackageModal();
    },
  },
  created() {
    this.getUhuruPackages();
  },
};
</script>

<style scoped>
.add-new-package-form input {
  margin-bottom: 10px;
}
</style>