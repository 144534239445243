<template>
  <div>
    <main-header></main-header>
    <div class="my-patients-cont">
      <h3 class="heading--1">MY PATIENTS</h3>
      <div class="my-patients-search g-flex">
        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchAgents"
            @keyup.enter="searchTAgentsHandler"
          />
          <button @click="searchTAgentsHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div class="my-patients-body">
        <div class="my-patients-list" v-if="!isGettingPatients">
          <div
            class="single-patient-div"
            :class="{ 'make-active': activePatient == item.employee_id }"
            v-for="(item, index) in patientsList"
            :key="item.id"
            @click="getSinglePatientDetails(item.employee_id)"
          >
            <img
              src="/images/Group_91.svg"
              alt=""
              class="single-patient-option"
              @click.stop="item.isShow = !item.isShow"
            />
            <div>
              <user-circle
                :currentIndex="index"
                :currentUser="item.name"
                width="80px"
                height="80px"
              ></user-circle>
            </div>
            <p class="p--1">{{ item.name }}</p>
            <p>
              Status:
              <span
                :class="
                  item.status == 'Pending'
                    ? 'pending-color'
                    : item.status == 'On-going'
                    ? 'onGoing-color'
                    : item.status == 'Completed'
                    ? 'completed-color'
                    : 'rejected-color'
                "
                >{{ item.status }}</span
              >
            </p>
            <span v-if="item.isShow">
              <div class="patient-option-btn-div">
                <button
                  :disabled="
                    item.status == 'Completed' ||
                    item.status == 'Rejected' ||
                    item.status == 'Pending'
                  "
                  @click.stop="addNewConsultation(item.employee_id)"
                >
                  Add Consultation
                </button>
                <button
                  :disabled="
                    item.status == 'Completed' || item.status == 'On-going'
                  "
                  @click.stop="approveAssignedPatient(item.id)"
                >
                  <loading-letter v-if="isMakingOnGoing"></loading-letter>
                  {{ !isMakingOnGoing ? "Accept" : "" }}
                </button>
                <button
                  :disabled="
                    item.status == 'Completed' || item.status == 'Rejected'
                  "
                  @click.stop="denyAssignedPatient(item.id)"
                >
                  <loading-letter v-if="isRejectingPatient"></loading-letter>
                  {{ !isRejectingPatient ? "Deny/Reject" : "" }}
                </button>
                <button
                  :disabled="
                    item.status == 'Completed' ||
                    item.status == 'Rejected' ||
                    item.status == 'Pending'
                  "
                  @click.stop="markAsComplete(item.id)"
                >
                  <loading-letter v-if="isMarkingComplete"></loading-letter>
                  {{ !isMarkingComplete ? "Mark as Complete" : "" }}
                </button>
              </div>
            </span>
          </div>

          <div
            class="uhuru-pg-container"
            v-if="!isGettingPatients && hasNextPage"
          >
            <vue-awesome-paginate
              :total-items="totalPgItems"
              :items-per-page="10"
              :max-pages-shown="8"
              v-model="currentPage"
              paginate-buttons-class="pg-btn"
              active-page-class="pg-btn-active"
              back-button-class="pg-back-btn"
              next-button-class="pg-next-btn"
              prev-button-content="Prev"
              next-button-content="Next"
              :on-click="getMyAssignedPatient"
            />
          </div>
        </div>

        <div
          v-if="isGettingPatients"
          style="width: 100%; margin-top: 20px"
          class="g-flex g-content-center"
        >
          <table-data-loader></table-data-loader>
        </div>
        <div class="my-patients-details">
          <h3
            class="heading--1"
            style="font-size: 16px"
            v-if="!isGettingPatients"
          >
            PATIENT DETAILS
          </h3>

          <div
            v-if="singlePatientDetails && !isGettingSinglePatient"
            style="margin: 10px"
          >
            <single-patient-details
              :singlePatientDetails="singlePatientDetails"
            ></single-patient-details>
          </div>
          <div
            v-if="isGettingSinglePatient"
            style="width: 100%; margin-top: 20px"
            class="g-flex g-content-center"
          >
            <table-data-loader></table-data-loader>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";

import MainHeader from "@/components/MainHeader.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import SinglePatientDetails from "@/components/SinglePatientDetails.vue";
import LoadingLetter from "@/components/reusable/LoadingLetter.vue";
export default {
  components: {
    MainHeader,
    BaseButton,
    UserCircle,
    TableDataLoader,
    SinglePatientDetails,
    LoadingLetter,
  },
  data() {
    return {
      isGettingPatients: false,
      isGettingSinglePatient: false,
      patientsList: [],
      singlePatientDetails: null,
      activePatient: "",
      isMakingOnGoing: false,
      isRejectingPatient: false,
      isMarkingComplete: false,

      searchAgents: "",
      isOnSearchingAgents: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    searchTAgentsHandler() {
      if (!this.searchAgents) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }

      this.isOnSearchingAgents = true;
      this.getMyAssignedPatient();
    },

    addNewConsultation(id) {
      if (sessionStorage.getItem("editConsultation")) {
        sessionStorage.removeItem("editConsultation");
      }
      this.$router.push("/add-consultations/" + id);
    },
    getMyAssignedPatient(page) {
      this.isGettingPatients = true;

      const actionUrl = this.isOnSearchingAgents
        ? `${this.$store.state.apiUrl}/v1/therapists/patients?search=${this.searchAgents}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/therapists/patients?page=${page}`;

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.patientsList = response.data.data.data.map((item) => ({
            ...item,
            isShow: false,
          }));
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPatients = false;
        });
    },

    getSinglePatientDetails(employee_id) {
      this.isGettingSinglePatient = true;
      this.activePatient = employee_id;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/therapists/patients/${employee_id}/details`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.singlePatientDetails = response.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSinglePatient = false;
        });
    },

    approveAssignedPatient(id) {
      this.isMakingOnGoing = true;
      axios
        .put(
          `${this.$store.state.apiUrl}/v1/therapists/patients/${id}/approve`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.patientsList = this.patientsList.map((item) => {
            if (item.id == response.data.data.id) {
              return response.data.data;
            } else {
              return item;
            }
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingOnGoing = false;
        });
    },

    denyAssignedPatient(id) {
      this.isRejectingPatient = true;
      axios
        .put(
          `${this.$store.state.apiUrl}/v1/therapists/patients/${id}/deny`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.patientsList = this.patientsList.map((item) => {
            if (item.id == response.data.data.id) {
              return response.data.data;
            } else {
              return item;
            }
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isRejectingPatient = false;
        });
    },

    markAsComplete(id) {
      this.isMarkingComplete = true;
      axios
        .put(
          `${this.$store.state.apiUrl}/v1/therapists/patients/${id}/complete`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.patientsList = this.patientsList.map((item) => {
            if (item.id == response.data.data.id) {
              return response.data.data;
            } else {
              return item;
            }
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMarkingComplete = false;
        });
    },
  },
  created() {
    this.getMyAssignedPatient();
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.my-patients-cont {
  width: 80%;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.my-patients-search {
  width: 470px;
  margin: 30px auto;
}
.my-patients-search input {
  margin-right: 30px;
}
.my-patients-body {
  border-top: 0.5px solid #a8a8a8;
  width: 100%;
  display: flex;
}
.my-patients-list {
  border-right: 0.5px solid #a8a8a8;
  width: 60%;
  display: flex;
  flex-wrap: wrap;
}
.single-patient-div {
  border: 0.5px solid #a8a8a8;
  flex: 1;
  padding-bottom: 20px;
  position: relative;
  max-width: 230px;
  min-width: 200px;
  height: fit-content;
}
.single-patient-div:hover {
  background-color: #f5f5f5;
  cursor: pointer;
}
.make-active {
  background-color: #f5f5f5;
}
.single-patient-div > div {
  width: 100%;
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
.single-patient-div p {
  text-align: center;
}
.p--1 {
  font-weight: 500;
}
.p--2 {
  font-size: 15px;
}
.my-patients-details {
  padding: 20px;
}
.heading--1 {
  font-weight: 500;
}
.single-patient-option {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.patient-option-btn-div {
  position: absolute;
  right: 10px;
  top: 36px;
  display: flex;
  flex-direction: column;
  width: 180px;
  background: white;
  padding: 5px 0;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
}
.patient-option-btn-div button {
  text-align: left;
  background: transparent;
  border: none;
  height: 30px;
}
.patient-option-btn-div button:hover {
  background: var(--primary-color);
  color: white;
}

.pending-color,
.onGoing-color,
.completed-color,
.rejected-color {
  padding: 1px 10px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}
.pending-color {
  background: #b9960b;
}
.onGoing-color {
  background: #1e4b7d;
}
.completed-color {
  background: #5e8722;
}
.rejected-color {
  background: #a60909;
}
</style>