<template>
  <div>
    <div>
      <p class="heading--1"><b>EMPLOYEE DETAILS</b></p>
      <p><b>Name: </b>{{ selectedPatientToView.name }}</p>
      <p><b>Phone: </b>{{ selectedPatientToView.phone }}</p>
      <p><b>Email: </b>{{ selectedPatientToView.loginDetails.email }}</p>
    </div>

    <div style="margin: 20px 0">
      <p class="heading--1"><b>PSYCHIATRISTS</b></p>
      <table>
        <thead>
          <tr>
            <th>Psychiatrist name</th>
            <th>Psychiatrist Phone</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody v-if="selectedPatientToView.Psychiatrists.length">
          <tr
            v-for="item in selectedPatientToView.Psychiatrists"
            :key="item.id"
          >
            <td>{{ item.name }}</td>
            <td>{{ item.phone }}</td>
            <td>{{ item.status }}</td>

            <td>
              <button
                class="action-btn-emp"
                v-if="item.status !== 'Completed'"
                @click="toggleUnassignWarningHandler(item.id)"
              >
                Unassign
              </button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="isToggleUnassignWarning">
      <warning-msg
        message="Are you sure you want to unassign patient from psychiatrist"
        :onClick="unassignPatientHandler"
        :onClosingModal="toggleUnassignWarningHandler"
        :isOnLoading="isUnassigningPatient"
      ></warning-msg>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";

import ActionsBtn from "./reusable/ActionsBtn.vue";
import WarningMsg from "./reusable/WarningMsg.vue";
import TableDataLoader from "./reusable/TableDataLoader.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
export default {
  components: { WarningMsg, ActionsBtn, TableDataLoader },
  data() {
    return {
      isToggleUnassignWarning: false,
      isUnassigningPatient: false,
      employeeIdToUnassign: "",
      employeeDetails: null,
    };
  },
  mixins: [formatDateMixin],
  props: {
    selectedPatientToView: {
      type: Object,
    },
    removeUnassignedObject: {
      type: Function,
    },
  },
  methods: {
    toggleUnassignWarningHandler(item) {
      this.employeeIdToUnassign = item;
      this.isToggleUnassignWarning = !this.isToggleUnassignWarning;
    },

    unassignPatientHandler() {
      this.isUnassigningPatient = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/agents/employees/${this.employeeIdToUnassign}/detach`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          setTimeout(() => {
            this.removeUnassignedObject(
              this.employeeIdToUnassign,
              this.selectedPatientToView.id
            );
          }, 500);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isUnassigningPatient = false;
          this.isToggleUnassignWarning = false;
        });
    },
  },
  created() {},
};
</script>

<style scoped>
p {
  margin: 0;
}
.heading--1 {
  margin-bottom: 10px;
}
.p--1 {
  border: 1px solid #dddddd;
  margin: 10px 0;
  padding: 10px;
}
.call-sessions {
  border: 1px solid #979797;
  margin: 20px 0;
  padding: 10px;
}
</style>