<template>
  <base-layout>
    <template #content>
      <div class="therapist-header g-flex g-align-center g-space-btw">
        <h3>List of Therapists</h3>

        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchTherapist"
            @keyup.enter="searchTherapistsHandler"
          />
          <button @click="searchTherapistsHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

        <div style="width: 130px">
          <base-button
            name="Add new"
            :onClick="toggleAddNewTherapistModal"
          ></base-button>
        </div>
      </div>
      <div style="padding: 0 10px">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>National ID</th>
              <th>License number</th>
              <th>Health facility</th>
              <th>Diploma</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="therapistsList.length && !isGettingTherapists">
            <tr v-for="(item, index) in therapistsList" :key="item.id">
              <td class="g-flex g-align-center">
                <div style="width: 38px; margin-right: 4px">
                  <user-circle
                    :currentIndex="index"
                    :currentUser="item.login_details.name"
                    marginRight="10px"
                  ></user-circle>
                </div>
                {{ item.login_details.name }}
              </td>
              <td>{{ item.login_details.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.nationalID }}</td>
              <td>{{ item.license_number }}</td>
              <td>{{ item.health_facility }}</td>
              <td>{{ item.qualification }}</td>
              <td
                :class="
                  item.login_details.active ? 'green-status ' : 'red-status'
                "
              >
                {{ item.login_details.active ? "Active" : "Inactive" }}
              </td>
              <td class="td-action">
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      @click="toggleUpdateTherapist(item)"
                    >
                      Edit
                    </button>
                    <button
                      class="action-btn-item"
                      v-if="item.login_details.active"
                      @click="toggleBlockUnblockHandler(item.id, 'block')"
                    >
                      Block
                    </button>
                    <button
                      class="action-btn-item"
                      v-if="!item.login_details.active"
                      @click="toggleBlockUnblockHandler(item.id, 'unblock')"
                    >
                      Unblock
                    </button>
                    <a target="_blank" :href="item.license_document">
                      <button class="action-btn-item">View License</button>
                    </a>
                    <a target="_blank" :href="item.document">
                      <button class="action-btn-item">Diploma or CV</button>
                    </a>
                    <button
                      class="action-btn-item"
                      @click="toggleDeleteWarningHandler(item.id)"
                    >
                      Delete
                    </button>
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingTherapists && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getTherapistsListHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingTherapists"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>
      <div v-if="isToggleAddNewTherapist">
        <modal :closeModal="toggleAddNewTherapistModal" width="400px">
          <template #content>
            <div>
              <h3 style="margin: 20px 0">
                {{ selectedTherapistToEdit ? "Edit " : "Add New " }}Therapist
              </h3>
            </div>
            <div>
              <label>Therapist Name</label>
              <input
                type="text"
                placeholder="Enter therapist name"
                v-model="name"
              />
              <p v-if="formErrors.name && !name" class="form-errors">
                Therapist name is a required
              </p>

              <label>National ID No.</label>
              <input
                type="text"
                placeholder="Enter national ID No."
                v-model="national_id"
              />
              <p
                v-if="formErrors.national_id && !national_id"
                class="form-errors"
              >
                Therapist national ID No. is required
              </p>

              <div class="g-flex" style="width: 100%">
                <div style="width: 50%; margin-right: 5px">
                  <label>Email address</label>
                  <input
                    type="email"
                    placeholder="Enter email address"
                    v-model="email"
                  />
                  <p v-if="formErrors.email && !email" class="form-errors">
                    Email address is required
                  </p>
                </div>
                <div style="width: 50%; margin-left: 5px">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter phone number"
                    v-model="phone"
                  />
                  <p v-if="formErrors.phone && !phone" class="form-errors">
                    Phone number is required
                  </p>
                </div>
              </div>

              <div class="g-flex" style="width: 100%">
                <div style="width: 50%; margin-right: 5px">
                  <label>Diploma</label>
                  <input
                    type="text"
                    placeholder="EX: bachelor degree"
                    v-model="qualification"
                  />
                  <p
                    v-if="formErrors.qualification && !qualification"
                    class="form-errors"
                  >
                    Diploma is required
                  </p>
                </div>
                <div style="width: 50%; margin-left: 5px">
                  <label>Health Facility</label>
                  <input
                    type="text"
                    placeholder="Enter health facility"
                    v-model="health_facility"
                  />
                  <p
                    v-if="formErrors.health_facility && !health_facility"
                    class="form-errors"
                  >
                    Health facility is required
                  </p>
                </div>
              </div>

              <div class="g-flex" style="width: 100%">
                <div style="width: 50%; margin-right: 5px">
                  <label>License Number</label>
                  <input
                    type="text"
                    placeholder="Enter license number"
                    v-model="license_number"
                  />
                  <p
                    v-if="formErrors.license_number && !license_number"
                    class="form-errors"
                  >
                    License number is required
                  </p>
                </div>
                <div style="width: 50%; margin-left: 5px">
                  <label>License Expiry Date</label>
                  <input
                    type="date"
                    placeholder=""
                    v-model="license_expiration_date"
                  />
                  <p
                    v-if="
                      formErrors.license_expiration_date &&
                      !license_expiration_date
                    "
                    class="form-errors"
                  >
                    License Expiry Date is required
                  </p>
                </div>
              </div>

              <div>
                <label>Diploma or CV</label>
                <input
                  type="file"
                  placeholder=""
                  @change="onDocumentFileAdded"
                />
                <p v-if="formErrors.document && !document" class="form-errors">
                  Please add diploma or cv
                </p>
              </div>

              <div>
                <label>License Document</label>
                <input
                  type="file"
                  placeholder=""
                  @change="onLicenceDocFileAdded"
                />
                <p
                  v-if="formErrors.license_document && !license_document"
                  class="form-errors"
                >
                  Please add licence document
                </p>
              </div>
            </div>
            <div style="margin: 20px 0" v-if="!selectedTherapistToEdit">
              <base-button
                name="Add Therapist"
                :isLoading="isMakingChangeOnTherapist"
                :onClick="addNewTherapistHandler"
              ></base-button>
            </div>
            <div style="margin: 20px 0" v-if="selectedTherapistToEdit">
              <base-button
                name="Edit Therapist"
                :isLoading="isMakingChangeOnTherapist"
                :onClick="addNewTherapistHandler"
              ></base-button>
            </div>
          </template>
        </modal>
      </div>
      <div v-if="isToggleDeleteWarning">
        <warning-msg
          message="Are you sure you want to delete this therapist"
          :onClick="deleteTherapistHandler"
          :onClosingModal="toggleDeleteWarningHandler"
          :isOnLoading="isDeletingTherapist"
        ></warning-msg>
      </div>
      <div v-if="isToggleBlockUnblockWarning">
        <warning-msg
          message="Are you sure you want change status of this therapist"
          :onClick="block_unblockTherapistHandler"
          :onClosingModal="toggleBlockUnblockHandler"
          :isOnLoading="isBlockingUnblocking"
        ></warning-msg>
      </div>
    </template>
  </base-layout>
</template>
        
<script>
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";

import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import UserCircle from "@/components/reusable/UserCircle.vue";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    ActionsBtn,
    WarningMsg,
    UserCircle,
  },
  data() {
    return {
      isToggleAddNewTherapist: false,
      isGettingTherapists: false,
      therapistsList: [],
      isDeletingTherapist: false,
      selectedTherapistToDelete: null,
      isToggleDeleteWarning: false,
      selectedTherapistToEdit: null,
      formErrors: {},
      isMakingChangeOnTherapist: false,
      block_unblock: "",
      selectedTherapistToBlockUnblock: null,
      isToggleBlockUnblockWarning: false,
      isBlockingUnblocking: false,
      //   therapist
      name: "",
      email: "",
      phone: "",
      national_id: "",
      qualification: "",
      health_facility: "",
      license_number: "",
      license_expiration_date: "",
      license_document: "",
      document: "",

      searchTherapist: "",
      isOnSearchingTherapist: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    searchTherapistsHandler() {
      if (!this.searchTherapist) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }

      this.isOnSearchingTherapist = true;
      this.getTherapistsListHandler();
    },

    toggleAddNewTherapistModal() {
      this.selectedTherapistToEdit = null;
      this.isToggleAddNewTherapist = !this.isToggleAddNewTherapist;
    },

    onDocumentFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.document = files[0];
    },

    onLicenceDocFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.license_document = files[0];
    },

    validateForm() {
      this.formErrors = {};

      if (!this.name) {
        this.formErrors.name = true;
      }
      if (!this.email) {
        this.formErrors.email = true;
      }
      if (!this.phone) {
        this.formErrors.phone = true;
      }
      if (!this.national_id) {
        this.formErrors.national_id = true;
      }
      if (!this.qualification) {
        this.formErrors.qualification = true;
      }
      if (!this.health_facility) {
        this.formErrors.health_facility = true;
      }
      if (!this.license_number) {
        this.formErrors.license_number = true;
      }
      if (!this.license_expiration_date) {
        this.formErrors.license_expiration_date = true;
      }
      if (!this.license_document) {
        this.formErrors.license_document = true;
      }
      if (!this.document) {
        this.formErrors.document = true;
      }

      return (
        !this.formErrors.name &&
        !this.formErrors.email &&
        !this.formErrors.phone &&
        !this.formErrors.national_id &&
        !this.formErrors.qualification &&
        !this.formErrors.health_facility &&
        !this.formErrors.license_number &&
        !this.formErrors.license_expiration_date &&
        !this.formErrors.license_document &&
        !this.formErrors.document
      );
    },

    addNewTherapistHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnTherapist = true;

      let data = new FormData();

      data.append("name", this.name);
      data.append("phone", this.phone);
      data.append("email", this.email);
      data.append("national_id", this.national_id);
      data.append("qualification", this.qualification);
      data.append("health_facility", this.health_facility);
      data.append("license_number", this.license_number);
      data.append("license_expiration_date", this.license_expiration_date);

      if (this.selectedTherapistToEdit) data.append("_method", "PUT");

      if (typeof this.document === "object") {
        data.append("document", this.document);
      }
      if (typeof this.license_document === "object") {
        data.append("license_document", this.license_document);
      }

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/therapists${
            this.selectedTherapistToEdit
              ? "/" + this.selectedTherapistToEdit
              : ""
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          if (this.selectedTherapistToEdit) {
            this.therapistsList = this.therapistsList.map((item) => {
              if (item.id == this.selectedTherapistToEdit) {
                return response.data.data;
              } else {
                return item;
              }
            });
          } else {
            this.therapistsList.push(response.data.data);
          }
          this.isToggleAddNewTherapist = !this.isToggleAddNewTherapist;
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnTherapist = false;
        });
    },

    getTherapistsListHandler(page) {
      this.isGettingTherapists = true;

      const actionUrl = this.isOnSearchingTherapist
        ? `${this.$store.state.apiUrl}/v1/uhuru/therapists?search=${this.searchTherapist}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/uhuru/therapists?page=${page}`;

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.therapistsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingTherapists = false;
        });
    },

    toggleDeleteWarningHandler(item) {
      this.selectedTherapistToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteTherapistHandler() {
      this.isDeletingTherapist = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/uhuru/therapists/${this.selectedTherapistToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.therapistsList = this.therapistsList.filter(
            (item) => item.id !== this.selectedTherapistToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingTherapist = false;
          this.toggleDeleteWarningHandler();
        });
    },

    toggleUpdateTherapist(item) {
      this.selectedTherapistToEdit = item.id;
      this.name = item.login_details.name;
      this.email = item.login_details.email;
      this.phone = item.phone;
      this.national_id = item.nationalID;
      this.qualification = item.qualification;
      this.health_facility = item.health_facility;
      this.license_number = item.license_number;
      this.license_expiration_date = item.license_expiration_date;
      this.license_document = item.license_document;
      this.document = item.document;
      this.isToggleAddNewTherapist = !this.isToggleAddNewTherapist;
    },

    toggleBlockUnblockHandler(item, block_unblock) {
      this.selectedTherapistToBlockUnblock = item;
      this.block_unblock = block_unblock;
      this.isToggleBlockUnblockWarning = !this.isToggleBlockUnblockWarning;
    },

    block_unblockTherapistHandler() {
      this.isBlockingUnblocking = true;

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/uhuru/therapists/${this.selectedTherapistToBlockUnblock}/${this.block_unblock}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.therapistsList = this.therapistsList.map((item) => {
            if (item.id == this.selectedTherapistToBlockUnblock) {
              return response.data.data;
            } else {
              return item;
            }
          });

          this.isToggleBlockUnblockWarning = !this.isToggleBlockUnblockWarning;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isBlockingUnblocking = false;
        });
    },
  },
  created() {
    this.getTherapistsListHandler();
  },
};
</script>
        
      <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.therapist-header {
  margin: 20px;
}
.therapist-header input {
  margin-bottom: 0 !important;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}
.g-filter-cont select {
  border: none !important;
  width: 120px;
  /* background: #f5f5f5; */
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-therapist-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
  margin-bottom: 0 !important;
}
</style>