<template>
  <div class="fg-pwd-container">
    <div class="fg-pwd-view">
      <div class="fg-pwd-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="fg-pwd-header">
        <h2>Forgot password</h2>
      </div>
      <label>Email</label>
      <input
        type="text"
        placeholder="Enter your email"
        required
        v-model="userEmail"
        @keyup.enter="forgetPwdHandler"
      />
      <p v-if="fgPasswordError.email && !userEmail" class="form-errors">
        Enter your email to receive the code for reset passowrd
      </p>

      <div class="fg-pwd-view-btn">
        <base-button
          name="Send reset code"
          :onClick="forgetPwdHandler"
          :isLoading="isSendingResetCode"
        ></base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/reusable/BaseButton.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { mapState } from "vuex";

export default {
  components: { BaseButton, WarningMsg },
  computed: {
    ...mapState(["apiUrl"]),
  },
  data() {
    return {
      userEmail: "",
      isSendingResetCode: false,
      fgPasswordError: {},
    };
  },
  methods: {
    forgetPwdHandler() {
      this.fgPasswordError = {};
      if (!this.userEmail) {
        this.fgPasswordError.email = true;
        return toast.error(
          "Please Enter your email to receive the code for reset passowrd",
          {
            theme: "colored",
          }
        );
      }

      this.isSendingResetCode = true;
      axios
        .post(`${this.apiUrl}/v1/password/forgot?email=${this.userEmail}`)
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$router.push("/reset-password");
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isSendingResetCode = false;
        });
    },
  },
};
</script>

<style scoped>
.fg-pwd-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fg-pwd-view {
  width: 300px;
  margin: auto;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  background: white;
}

.fg-pwd-view-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fg-pwd-header h2 {
  text-align: center;
}

.fg-pwd-view-img img {
  width: 120px;
  margin: 20px auto 0;
}

.fg-pwd-view-btn {
  margin: 20px 0 30px;
}

input,
select {
  margin-bottom: 8px;
}
</style>