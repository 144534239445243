<template>
  <div>
    <h4 class="heading--1">Change Password</h4>
    <label>Current Password</label>
    <input
      type="password"
      placeholder="Enter current password"
      v-model="currentPassword"
    />
    <p
      v-if="changePwdErrors.currentPassword && !currentPassword"
      class="form-errors"
    >
      Your current password is required
    </p>
    <label>New Password</label>
    <input
      type="password"
      placeholder="Enter new password"
      v-model="newPassword"
    />
    <p v-if="changePwdErrors.newPassword && !newPassword" class="form-errors">
      Your new password is required
    </p>
    <label>Confirm New Password</label>
    <input
      type="password"
      placeholder="Re-Enter new password"
      v-model="confirmNewPassword"
    />
    <p
      v-if="changePwdErrors.confirmNewPassword && !confirmNewPassword"
      class="form-errors"
    >
      Please confirm new password
    </p>
    <div style="width: 170px; margin: 20px 0">
      <base-button
        name="Change"
        :isLoading="isChangingPwd"
        :onClick="changePasswordProfileHandler"
      ></base-button>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseButton from "./reusable/BaseButton.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: { BaseButton },
  data() {
    return {
      changePwdErrors: {},
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      isChangingPwd: false,
    };
  },
  methods: {
    validatePwdForm() {
      this.changePwdErrors = {};

      if (!this.currentPassword) {
        this.changePwdErrors.currentPassword = true;
      }
      if (!this.newPassword) {
        this.changePwdErrors.newPassword = true;
      }
      if (!this.confirmNewPassword) {
        this.changePwdErrors.confirmNewPassword = true;
      }

      return (
        !this.changePwdErrors.currentPassword &&
        !this.changePwdErrors.newPassword &&
        !this.changePwdErrors.confirmNewPassword
      );
    },

    changePasswordProfileHandler() {
      if (!this.validatePwdForm()) {
        return;
      }

      this.isChangingPwd = true;

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/change-password?current_password=${this.currentPassword}&password=${this.newPassword}&password_confirmation=${this.confirmNewPassword}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.currentPassword = "";
          this.newPassword = "";
          this.confirmNewPassword = "";
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.error) {
            for (let item in error.response.data.error) {
              if (error.response.data.error["current_password"]) {
                return toast.error("Current password is incorrect", {
                  theme: "colored",
                });
              }

              returnedError.push(error.response.data.error[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isChangingPwd = false;
        });
    },
  },
};
</script>

<style scoped>
.heading--1 {
  text-transform: uppercase;
  margin: 10px 0 20px;
  font-weight: 500;
  font-size: 15px;
}
</style>