<template>
  <div class="verify-container">
    <div class="verify-view">
      <div class="verify-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="verify-sent-to">
        <p>
          Code has sent to
          <span v-if="userEmail">{{ hideEmailHandler(userEmail) }}</span>
        </p>
      </div>
      <label>Enter the code sent to your email</label>
      <div class="verify-input-container">
        <input
          type="text"
          placeholder="Code"
          v-model="emailOtpCode"
          @keyup.enter="verifyOTPHandler"
        />
        <button @click="resendOTPCodeHandler" :disabled="isResendingOTP">
          <loading-letter v-if="isResendingOTP"></loading-letter>
          {{ !isResendingOTP ? "Resend" : "" }}
        </button>
      </div>
      <div class="verify-view-btn">
        <base-button
          name="Verify"
          :onClick="verifyOTPHandler"
          :isLoading="isVerifyingOTP"
        ></base-button>
      </div>
    </div>
  </div>
</template>
  
  <script>
import BaseButton from "@/components/reusable/BaseButton.vue";
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import LoadingLetter from "@/components/reusable/LoadingLetter.vue";

export default {
  components: { BaseButton, LoadingLetter },
  data() {
    return {
      userEmail: "",
      emailOtpCode: "",
      isVerifyingOTP: false,
      isResendingOTP: false,
    };
  },
  methods: {
    // TODO: To be refactored
    redirectAfterLogin() {
      if (this.$store.state.authUser.role == "Uhuru") {
        this.$router.push("/admin-analytics");
      }
      if (this.$store.state.authUser.role == "Client") {
        this.$router.push("/company-analytics");
      }
      if (this.$store.state.authUser.role == "Employee") {
        this.$router.push("/my-info-view");
      }
      if (this.$store.state.authUser.role == "Psychologist") {
        this.$router.push("/therapist-analytics");
      }
      if (this.$store.state.authUser.role == "Super Agent") {
        this.$router.push("/agent-analytics");
      }
    },
    verifyOTPHandler() {
      if (!this.emailOtpCode) {
        return toast.error(
          "Please, copy and paste the code sent to your email or click resend if you didn't get one",
          {
            theme: "colored",
          }
        );
      }
      this.isVerifyingOTP = true;
      axios
        .post(
          `${this.$store.state.apiUrl}/v1/two-factor-check?code=${this.emailOtpCode}`
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$store.commit("setUserAuthentication", response.data);
          localStorage.setItem("userAuth", JSON.stringify(response.data));
          sessionStorage.removeItem("otpSentTo");
          this.redirectAfterLogin();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isVerifyingOTP = false;
        });
    },

    hideEmailHandler(email) {
      const [username, domain] = email.split("@");
      const hiddenUsername =
        username.charAt(0) + "*******" + username.charAt(username.length - 1);
      return hiddenUsername + "@" + domain;
    },

    resendOTPCodeHandler() {
      this.isResendingOTP = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/resend-two-factor-code`)
        .then((response) => {
          toast.success(
            "The code is re-sent to your email, use it to finish log in",
            {
              theme: "colored",
              clearOnUrlChange: false,
            }
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isResendingOTP = false;
        });
    },
  },
  created() {
    this.userEmail = sessionStorage.getItem("otpSentTo");
    if (!this.userEmail) this.$router.push("/");
  },
};
</script>
  
  <style scoped>
.verify-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.verify-view {
  width: 300px;
  margin: auto;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  background: white;
}
.verify-view-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.verify-view-img img {
  width: 120px;
  margin: 20px auto 0;
}
.verify-sent-to p {
  font-weight: 600;
}
.verify-sent-to p span {
  font-weight: 400 !important;
  color: #7d7d7d;
}
.verify-view-btn {
  margin: 20px 0 30px;
}
.verify-input-container {
  position: relative;
}
.verify-input-container button {
  position: absolute;
  right: 4px;
  top: 12px;
  background-color: transparent;
  border: none;
  font-size: 14px;
  font-weight: 600;
  color: var(--primary-color);
  cursor: pointer;
}
</style>