<template>
  <base-layout>
    <template #content>
      <div class="company-profile-container">
        <div class="company-profile-header">
          <h3>You Company Profile</h3>
        </div>
        <div>
          <div
            class="profile-loader g-flex g-align-center g-content-center"
            v-if="isGettingCompanyProfile"
          >
            <table-data-loader></table-data-loader>
          </div>
          <h4 class="heading--1" v-if="!isGettingCompanyProfile">
            Profile Form
          </h4>
          <div class="comp-profile-form g-flex" v-if="!isGettingCompanyProfile">
            <div class="comp-profile-form-left">
              <label>Company Name</label>
              <input
                type="text"
                placeholder="Enter company name"
                v-model="name"
              />
              <p v-if="registerErrors.name && !name" class="form-errors">
                Company name is required
              </p>
              <label>Company Website</label>
              <input
                type="text"
                placeholder="Enter company website"
                v-model="website"
              />
              <p v-if="registerErrors.website && !website" class="form-errors">
                Company website is required
              </p>
              <div class="comp-profile-person g-flex">
                <div style="margin-right: 5px">
                  <label>Company Email</label>
                  <input
                    type="email"
                    placeholder="Enter company email"
                    v-model="email"
                  />
                  <p v-if="registerErrors.email && !email" class="form-errors">
                    Company email is required
                  </p>
                </div>
                <div style="margin-left: 5px">
                  <label>Company Phone Number</label>
                  <input
                    type="text"
                    placeholder="Enter company phone number"
                    v-model="phone"
                  />
                  <p v-if="registerErrors.phone && !phone" class="form-errors">
                    Company phone number is required
                  </p>
                </div>
              </div>
              <div class="comp-profile-person g-flex">
                <div style="margin-right: 5px">
                  <label>Company TIN Number</label>
                  <input
                    type="text"
                    placeholder="Enter company TIN number"
                    v-model="tin_number"
                  />
                  <p
                    v-if="registerErrors.tin_number && !tin_number"
                    class="form-errors"
                  >
                    Company TIN number is required
                  </p>
                </div>
                <div style="margin-left: 5px">
                  <label>Phyisical Address</label>
                  <input
                    type="text"
                    placeholder="Ex: Kigali/Gasabo/Remera OR KG 001 Ave"
                    v-model="physical_address"
                  />
                  <p
                    v-if="registerErrors.physical_address && !physical_address"
                    class="form-errors"
                  >
                    Company phyisical address is required
                  </p>
                </div>
              </div>

              <div style="width: 170px; margin: 20px 0">
                <base-button
                  name="Update profile"
                  :isLoading="isUpdatingProfile"
                  :onClick="updateCompanyProfileHandler"
                ></base-button>
              </div>
            </div>
            <!-- right side -->
            <div class="comp-profile-form-right">
              <h4>
                Details of a person to contact from your company just in case
              </h4>
              <div class="comp-profile-person g-flex">
                <div style="margin-right: 5px">
                  <label>Name</label>
                  <input
                    type="text"
                    placeholder="Name of a person to contact"
                    v-model="contact_person"
                  />
                  <p
                    v-if="registerErrors.contact_person && !contact_person"
                    class="form-errors"
                  >
                    Enter name of a person to contact
                  </p>
                </div>
                <div style="margin-left: 5px">
                  <label>Email</label>
                  <input
                    type="email"
                    placeholder="Email of a person to contact"
                    v-model="contact_person_email"
                  />
                  <p
                    v-if="
                      registerErrors.contact_person_email &&
                      !contact_person_email
                    "
                    class="form-errors"
                  >
                    Enter email of a person to contact
                  </p>
                </div>
              </div>
              <div class="comp-profile-person g-flex">
                <div style="margin-right: 5px">
                  <label>Phone</label>
                  <input
                    type="text"
                    placeholder="Phone number of a person to contact"
                    v-model="contact_person_phone"
                  />
                  <p
                    v-if="
                      registerErrors.contact_person_phone &&
                      !contact_person_phone
                    "
                    class="form-errors"
                  >
                    Enter phone number of a person to contact
                  </p>
                </div>
                <div style="margin-left: 5px">
                  <label>Registration Certificate</label>
                  <input type="file" placeholder="" @change="onFileChange" />
                  <p
                    v-if="
                      registerErrors.registration_certificate &&
                      !registration_certificate
                    "
                    class="form-errors"
                  >
                    Registration certificate is required
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>
  
  <script>
import axios from "axios";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import employeeMixin from "../mixins/employeeMixin";
import ChangePassword from "@/components/ChangePassword.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: { BaseLayout, TableDataLoader, BaseButton, ChangePassword },
  mixins: [employeeMixin],
  data() {
    return {
      isGettingCompanyProfile: false,
      name: "",
      email: "",
      phone: "",
      contact_person: "",
      contact_person_phone: "",
      contact_person_email: "",
      tin_number: "",
      registration_certificate: "",
      physical_address: "",
      website: "",
      password: "",
      confirmPassword: "",
      isUpdatingProfile: false,
      registerErrors: {},
    };
  },
  methods: {
    assignCompanyProfileData(data) {
      this.name = data.client_name;
      this.phone = data.phone;
      this.contact_person = data.contact_person;
      this.contact_person_phone = data.contact_person_phone;
      this.contact_person_email = data.contact_person_email;
      this.tin_number = data.tin_number;
      this.registration_certificate = data.registration_certificate;
      this.physical_address = data.physical_address;
      this.website = data.website;
      this.email = data.email;
    },

    updateProfileAfterSave() {
      let displayData = {
        name: this.name,
        company: this.name,
      };

      const loggedInUser = localStorage.getItem("userAuth");

      let storedData = JSON.parse(loggedInUser);

      let storeNewUser = {
        data: {
          active: storedData.data.active,
          email: storedData.data.email,
          name: displayData.name,
          role: storedData.data.role,
          type: storedData.data.type,
          verified: storedData.data.verified,
        },
        message: storedData.message,
        status: storedData.status,
        token: storedData.token,
      };

      localStorage.removeItem("userAuth");

      this.$store.commit("setUserWhenProfileChanged", displayData);
      localStorage.setItem("userAuth", JSON.stringify(storeNewUser));
    },

    getCompanyProfile() {
      this.isGettingCompanyProfile = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/dashboard/profile`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          this.assignCompanyProfileData(res.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingCompanyProfile = false;
        });
    },

    onFileChange(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.registration_certificate = files[0];
    },

    validateClientInfoForm() {
      this.registerErrors = {};

      if (!this.email) {
        return (this.registerErrors.email = true);
      }
      if (!this.name) {
        this.registerErrors.name = true;
      }
      if (!this.phone) {
        this.registerErrors.phone = true;
      }
      if (!this.contact_person) {
        this.registerErrors.contact_person = true;
      }
      if (!this.contact_person_phone) {
        this.registerErrors.contact_person_phone = true;
      }
      if (!this.contact_person_email) {
        this.registerErrors.contact_person_email = true;
      }
      if (!this.tin_number) {
        this.registerErrors.tin_number = true;
      }
      if (!this.registration_certificate) {
        this.registerErrors.registration_certificate = true;
      }
      if (!this.physical_address) {
        this.registerErrors.physical_address = true;
      }
      if (!this.website) {
        this.registerErrors.website = true;
      }

      return (
        !this.registerErrors.name &&
        !this.registerErrors.email &&
        !this.registerErrors.contact_person &&
        !this.registerErrors.phone &&
        !this.registerErrors.contact_person_phone &&
        !this.registerErrors.contact_person_email &&
        !this.registerErrors.tin_number &&
        !this.registerErrors.registration_certificate &&
        !this.registerErrors.physical_address &&
        !this.registerErrors.website &&
        !this.registerErrors.mode_of_communication
      );
    },

    updateCompanyProfileHandler() {
      if (!this.validateClientInfoForm()) {
        return;
      }

      this.isUpdatingProfile = true;

      let data = new FormData();

      data.append("client_name", this.name);
      data.append("email", this.email);
      data.append("phone", this.phone);
      data.append("contact_person", this.contact_person);
      data.append("contact_person_phone", this.contact_person_phone);
      data.append("contact_person_email", this.contact_person_email);
      data.append("tin_number", this.tin_number);
      if (typeof this.registration_certificate === "object") {
        data.append("registration_certificate", this.registration_certificate);
      }
      data.append("website", this.website);
      data.append("physical_address", this.physical_address);
      data.append("_method", "PUT");

      axios
        .post(`${this.$store.state.apiUrl}/v1/dashboard/profile`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          // this.updateProfileAfterSave(response.data.data);
          this.updateProfileAfterSave();
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isUpdatingProfile = false;
        });
    },
  },
  created() {
    this.getCompanyProfile();
  },
};
</script>
  
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.company-profile-container {
  padding: 10px 20px;
}
.profile-loader {
  width: 100%;
  margin: 40px 0;
}
.comp-profile-form {
  width: 100%;
}
.comp-profile-form-left,
.comp-profile-form-right {
  width: 50%;
}
.comp-profile-form-left {
  margin: 10px 20px 10px 10px;
}
.comp-profile-form-right {
  margin: 10px 10px 10px 20px;
}
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
.comp-profile-person {
  width: 100%;
}
.comp-profile-person div {
  width: 50%;
}
.heading--1 {
  text-transform: uppercase;
  margin: 20px 0 0 10px;
  font-weight: 500;
  font-size: 15px;
}
</style>