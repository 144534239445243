<template>
  <base-layout>
    <template #content>
      <div class="package-service">
        <div class="package-service-bottom">
          <div>
            <packages-list></packages-list>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>
  
  <script>
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import PackagesList from "@/components/PackagesList.vue";
export default {
  components: { BaseLayout, PackagesList },
  data() {
    return {};
  },
  methods: {},
};
</script>
  
<style scoped>
.package-service-bottom {
  padding: 10px 20px;
  border: 1px solid #dddddd;
}
</style>