<template>
  <base-layout>
    <template #content>
      <div class="therapist-header g-flex g-align-center g-space-btw">
        <h3>List of Therapists</h3>

        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchTherapist"
            @keyup.enter="searchTherapistsHandler"
          />
          <button @click="searchTherapistsHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div style="padding: 0 10px" class="border--1">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Phone</th>
              <th>National ID</th>
              <th>License number</th>
              <th>Health facility</th>
              <th>Diploma</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody v-if="therapistsList.length && !isGettingTherapists">
            <tr v-for="item in therapistsList" :key="item.id">
              <td>{{ item.login_details.name }}</td>
              <td>{{ item.login_details.email }}</td>
              <td>{{ item.phone }}</td>
              <td>{{ item.nationalID }}</td>
              <td>{{ item.license_number }}</td>
              <td>{{ item.health_facility }}</td>
              <td>{{ item.qualification }}</td>
              <td
                :class="
                  item.login_details.active ? 'green-status ' : 'red-status'
                "
              >
                {{ item.login_details.active ? "Active" : "Inactive" }}
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingTherapists && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getTherapistsListHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingTherapists"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>
    </template>
  </base-layout>
</template>
        
    <script>
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";

import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    ActionsBtn,
  },
  data() {
    return {
      isGettingTherapists: false,
      therapistsList: [],

      searchTherapist: "",
      isOnSearchingTherapist: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    searchTherapistsHandler() {
      if (!this.searchTherapist) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }

      this.isOnSearchingTherapist = true;
      this.getTherapistsListHandler();
    },

    getTherapistsListHandler(page) {
      const actionUrl = this.isOnSearchingTherapist
        ? `${this.$store.state.apiUrl}/v1/agents/therapists?search=${this.searchTherapist}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/agents/therapists?page=${page}`;

      this.isGettingTherapists = true;
      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.therapistsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingTherapists = false;
        });
    },
  },
  created() {
    this.getTherapistsListHandler();
  },
};
</script>
        
      <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.therapist-header {
  margin: 20px;
}
.therapist-header input {
  margin-bottom: 0 !important;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}
.g-filter-cont select {
  border: none !important;
  width: 120px;
  /* background: #f5f5f5; */
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-therapist-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
.border--1 {
  border: 1px solid #dddddd;
}
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
  margin-bottom: 0 !important;
}
</style>