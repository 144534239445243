<template>
  <base-layout>
    <template #content>
      <div class="employee-header g-flex g-align-center g-space-btw">
        <h3>List of Employees</h3>
        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchEmployee"
            @keyup.enter="searchEmployeesHandler"
          />
          <button @click="searchEmployeesHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

        <div class="g-flex">
          <div style="width: 140px">
            <base-button
              name="Add from excel"
              :onClick="toggleAddEmployeeFromExcelHandler"
            ></base-button>
          </div>
          <div style="width: 140px; margin-left: 20px">
            <base-button
              name="Add new"
              :onClick="toggleAddNewEmployeeModal"
            ></base-button>
          </div>
        </div>
      </div>
      <div style="padding: 0 10px">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>Employee number</th>
              <th>Email</th>
              <th>Phone</th>
              <th>Payment</th>
              <th>Status</th>
              <th>Verified</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="employeesList.length && !isGettingEmployees">
            <tr v-for="(emp, index) in employeesList" :key="emp.id">
              <td class="g-flex g-align-center">
                <user-circle
                  :currentIndex="index"
                  :currentUser="emp.name"
                  marginRight="10px"
                ></user-circle
                >{{ emp.name }}
              </td>
              <td>{{ emp.employee_number }}</td>
              <td>{{ emp.loginDetails.email }}</td>
              <td>{{ emp.phone }}</td>
              <td>
                <span
                  :class="
                    emp.loginDetails.is_subscription_paid
                      ? 'make-green-bg'
                      : 'make-red-gb'
                  "
                  >{{
                    emp.loginDetails.is_subscription_paid ? "Paid" : "Not paid"
                  }}</span
                >
              </td>
              <td
                :class="emp.loginDetails.active ? 'green-status' : 'red-status'"
              >
                {{ emp.loginDetails.active ? "Active" : "Inactive" }}
              </td>
              <td
                :class="
                  emp.loginDetails.verified ? 'green-status' : 'red-status'
                "
              >
                {{ emp.loginDetails.verified ? "Yes" : "No" }}
              </td>
              <td class="td-action">
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      @click="toggleSingleEmployeeDetails(emp.id)"
                    >
                      View
                    </button>
                    <button
                      class="action-btn-item"
                      @click="toggleDeleteWarningHandler(emp.id)"
                    >
                      Delete
                    </button>
                    <button
                      v-if="emp.loginDetails.active"
                      class="action-btn-item"
                      @click="toggleBockUnblockWarningHandler(emp)"
                    >
                      Block
                    </button>
                    <button
                      v-if="!emp.loginDetails.active"
                      class="action-btn-item"
                      @click="toggleBockUnblockWarningHandler(emp)"
                    >
                      Unblock
                    </button>
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingEmployees && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getCompanyEmployees"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingEmployees"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>

      <div v-if="isToggleAddNewEmployee">
        <modal :closeModal="toggleAddNewEmployeeModal" width="350px">
          <template #content>
            <div>
              <h3 style="margin: 20px 0">Add New Employee</h3>
            </div>
            <div class="add-new-employee-form">
              <label>Employee Name</label>
              <input
                type="text"
                placeholder="Enter employee name"
                v-model="employeeName"
              />
              <p
                v-if="addEmployeeErrors.employeeName && !employeeName"
                class="form-errors"
              >
                Enter employee name
              </p>
              <label>Employee Email</label>
              <input
                type="email"
                placeholder="Enter employee email"
                v-model="employeeEmail"
              />
              <p
                v-if="addEmployeeErrors.employeeEmail && !employeeEmail"
                class="form-errors"
              >
                Enter employee email please
              </p>
              <label>Employee Phone</label>
              <input
                type="text"
                placeholder="Enter employee Phone number"
                v-model="employeePhone"
              />
              <p
                v-if="addEmployeeErrors.employeePhone && !employeePhone"
                class="form-errors"
              >
                Enter employee phone number
              </p>
            </div>
            <div style="margin: 20px 0">
              <base-button
                name="Add Employee"
                :isLoading="isMakingChangeOnEmployee"
                :onClick="addNewEmployeeHandler"
              ></base-button>
            </div>
          </template>
        </modal>
      </div>

      <div v-if="isToggleAddEmployeeFrom">
        <modal :closeModal="toggleAddEmployeeFromExcelHandler" width="360px">
          <template #content>
            <div>
              <h3 class="heading--1">Add employees</h3>
            </div>

            <div class="extend-label-div">
              <label>Upload employees list using excel</label>
            </div>
            <p class="extend-msg">
              <b>NB: </b>
              Excel should have a column of name, email, phone, password and
              code
            </p>
            <input type="file" placeholder="" @change="onAddEmployeeList" />

            <div style="width: 150px; margin-bottom: 10px">
              <base-button
                name="Add"
                :isLoading="isAddingEmployeeFromExcel"
                :onClick="addEmployeeFromExcelHandler"
              ></base-button>
            </div>
          </template>
        </modal>
      </div>

      <div v-if="isViewSingleEmployeeDetails">
        <modal :closeModal="toggleSingleEmployeeDetails" width="700px">
          <template #content>
            <company-employee-details
              :singleSelectedEmployee="singleSelectedEmployee"
            ></company-employee-details>
          </template>
        </modal>
      </div>

      <div v-if="isToggleDeleteWarning">
        <warning-msg
          message="Are you sure you want to delete this employee"
          :onClick="deleteEmployeeHandler"
          :onClosingModal="toggleDeleteWarningHandler"
          :isOnLoading="isDeletingEmployee"
        ></warning-msg>
      </div>

      <div v-if="isToggleBlockUnclockWarning">
        <warning-msg
          message="Are you sure you want to change status of this employee"
          :onClick="handleBlockAndUnblockEmployee"
          :onClosingModal="toggleBockUnblockWarningHandler"
          :isOnLoading="isMakingChangeOnEmployee"
        ></warning-msg>
      </div>
    </template>
  </base-layout>
</template>
  
  <script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import CompanyEmployeeDetails from "@/components/CompanyEmployeeDetails.vue";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    UserCircle,
    ActionsBtn,
    WarningMsg,
    CompanyEmployeeDetails,
  },
  data() {
    return {
      isToggleAddNewEmployee: false,
      addEmployeeErrors: {},
      employeeName: "",
      employeeEmail: "",
      employeePhone: "",
      isGettingEmployees: false,
      employeesList: [],
      isMakingChangeOnEmployee: false,
      isDeletingEmployee: false,
      selectedEmployeeToDelete: null,
      isToggleDeleteWarning: false,
      isToggleBlockUnclockWarning: false,
      selectedEmployeeToBlock_Unblock: null,

      isViewSingleEmployeeDetails: false,
      singleSelectedEmployee: "",

      searchEmployee: "",
      isOnSearchingEmployee: false,

      isToggleAddEmployeeFrom: false,
      isAddingEmployeeFromExcel: false,
      employees_list: null,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  methods: {
    toggleAddEmployeeFromExcelHandler() {
      this.isToggleAddEmployeeFrom = !this.isToggleAddEmployeeFrom;
    },

    onAddEmployeeList(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.employees_list = files[0];
    },

    addEmployeeFromExcelHandler() {
      if (!this.employees_list) {
        return toast.error("Please upload excel sheet", {
          theme: "colored",
        });
      }
      this.isAddingEmployeeFromExcel = true;

      let data = new FormData();

      if (typeof this.employees_list === "object") {
        data.append("employees_list", this.employees_list);
      }

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/dashboard/employees/upload`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.getCompanyEmployees();
          this.isToggleAddEmployeeFrom = false;
        })
        .catch((error) => {
          if (error.response.data.error) {
            toast.error(error.response.data.error, {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isAddingEmployeeFromExcel = false;
        });
    },

    toggleSingleEmployeeDetails(item) {
      this.singleSelectedEmployee = item;
      this.isViewSingleEmployeeDetails = !this.isViewSingleEmployeeDetails;
    },

    toggleAddNewEmployeeModal() {
      this.isToggleAddNewEmployee = !this.isToggleAddNewEmployee;
    },

    searchEmployeesHandler() {
      if (!this.searchEmployee) {
        return toast.error("Please type something to search", {
          theme: "colored",
        });
      }

      this.isOnSearchingEmployee = true;
      this.getCompanyEmployees();
    },

    getCompanyEmployees(page) {
      this.isGettingEmployees = true;

      let actionUrl = this.isOnSearchingEmployee
        ? `${this.$store.state.apiUrl}/v1/dashboard/employees?trashed=0&search=${this.searchEmployee}&page=${page}`
        : `${this.$store.state.apiUrl}/v1/dashboard/employees?trashed=0&page=${page}`;

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.employeesList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingEmployees = false;
          this.isOnSearchingEmployee = false;
        });
    },

    validateAddEmployeeForm() {
      this.addEmployeeErrors = {};

      if (!this.employeeName) {
        this.addEmployeeErrors.employeeName = true;
      }
      if (!this.employeeEmail) {
        this.addEmployeeErrors.employeeEmail = true;
      }
      if (!this.employeePhone) {
        this.addEmployeeErrors.employeePhone = true;
      }
      // Return true if all field is valid, otherwise is false
      return (
        !this.addEmployeeErrors.employeeName &&
        !this.addEmployeeErrors.employeeEmail &&
        !this.addEmployeeErrors.employeePhone
      );
    },

    addNewEmployeeHandler() {
      if (!this.validateAddEmployeeForm()) {
        return;
      }
      this.isMakingChangeOnEmployee = true;

      let data = {
        name: this.employeeName,
        email: this.employeeEmail,
        phone: this.employeePhone,
      };
      axios
        .post(`${this.$store.state.apiUrl}/v1/dashboard/employees`, data, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.employeesList.push(response.data.data);
          this.employeeName = "";
          this.employeeEmail = "";
          this.employeePhone = "";
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnEmployee = false;
          this.toggleAddNewEmployeeModal();
        });
    },

    toggleBockUnblockWarningHandler(item) {
      this.selectedEmployeeToBlock_Unblock = item;
      this.isToggleBlockUnclockWarning = !this.isToggleBlockUnclockWarning;
    },

    handleBlockAndUnblockEmployee() {
      this.isMakingChangeOnEmployee = true;

      let employee = this.selectedEmployeeToBlock_Unblock;
      let status = employee.loginDetails.active ? "block" : "unblock";

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/dashboard/employees/${employee.id}/${status}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          response;
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.employeesList = this.employeesList.map((item) => {
            if (item.id == employee.id) {
              return response.data.data;
            }
            return item;
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnEmployee = false;
          this.isToggleBlockUnclockWarning = false;
        });
    },

    toggleDeleteWarningHandler(item) {
      this.selectedEmployeeToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteEmployeeHandler() {
      this.isDeletingEmployee = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/dashboard/employees/${this.selectedEmployeeToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.employeesList = this.employeesList.filter(
            (item) => item.id !== this.selectedEmployeeToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingEmployee = false;
          this.toggleDeleteWarningHandler();
        });
    },
  },
  created() {
    this.getCompanyEmployees();
  },
};
</script>
  
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.employee-header {
  margin: 20px;
}
.employee-header input {
  margin-bottom: 0 !important;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  /* background: #f5f5f5; */
  padding-left: 26px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-employee-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
.make-green-bg {
  background: #5e8722;
  padding: 1px 10px;
  color: white;
  border-radius: 20px;
  font-size: 13px;
}
.make-red-gb {
  background: #a60909;
  padding: 1px 10px;
  color: white;
  border-radius: 20px;
  font-size: 13px;
}
input[type="file"] {
  border: none;
  margin-top: 15px;
}
</style>