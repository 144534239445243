<template>
  <base-layout>
    <template #content>
      <div class="emp-analytics-cont">
        <div class="emp-analytics-body">
          <div class="uh-analy-cards-cont">
            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-money-bill"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_payments_general }} Rwf</p>
                <div>
                  <label>Total amount paid</label>
                </div>
              </div>
            </div>

            <!-- <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-file-invoice-dollar"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ next_payment }} Rwf</p>
                <div>
                  <label>Next payment</label>
                </div>
              </div>
            </div> -->

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-star"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_subscriptions }}</p>
                <div>
                  <label>Total subscriptions</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-money-check-dollar"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_to_pay_per_current_subscription }} Rwf</p>
                <div>
                  <label>Total amount to pay</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-users"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_employees }}</p>
                <div>
                  <label>Total employees</label>
                </div>
              </div>
            </div>
          </div>

          <div class="g-flex" style="width: 100%; margin: 30px 0">
            <div style="width: 50%">
              <p><label>CALL SESSIONS</label></p>
              <apexchart
                type="area"
                height="350"
                :options="chartOptionsOne"
                :series="seriesOne"
              ></apexchart>
            </div>
            <div style="width: 50%; margin-left: 40px">
              <div v-if="next_payment">
                <p><label>NEXT PAYMENT</label></p>
                <p class="p--1"><b>Amount: </b>{{ next_payment.amount }}Rwf</p>
                <p class="p--1"><b>Date: </b>{{ next_payment.date }}</p>
              </div>
              <div>
                <p class="p--1" v-if="subsvription_expiration_date">
                  <b>Subscription expiration date: </b
                  >{{ subsvription_expiration_date }}
                </p>
              </div>
              <div v-if="request_for_change">
                <p><label>LASTEST EXTENDED SUBSCRIPTION</label></p>
                <p class="p--1"><b>Date: </b>{{ request_for_change.date }}</p>
                <p class="p--1">
                  <b>Consumers: </b>{{ request_for_change.requested_slots }}
                </p>

                <p class="p--1">
                  <b>Status: </b>
                  <span
                    :class="
                      request_for_change.status == 'Approved'
                        ? 'success-color'
                        : 'failure-color'
                    "
                    >{{ request_for_change.status }}</span
                  >
                </p>
                <p class="p--1">
                  <b>Payment status: </b>
                  <span
                    :class="
                      request_for_change.paid
                        ? 'success-color'
                        : 'failure-color'
                    "
                    >{{ request_for_change.paid ? "Paid" : "Not Paid" }}</span
                  >
                </p>
              </div>
            </div>
          </div>

          <div
            style="width: 100%; margin: 20px 0"
            v-if="average_mood_whole_week.length"
          >
            <p><label>WEEKLY AVERAGE MOOD</label></p>
            <table>
              <thead>
                <tr>
                  <th>Weekday</th>
                  <th>Today's total mood</th>
                  <th>Happyness level</th>
                  <th>Energy level</th>
                  <th>Sleep duration</th>
                  <th>Exercise</th>
                  <th>Meditation</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(item, index) in average_mood_whole_week"
                  :key="index"
                >
                  <td>{{ item.weekday }}</td>
                  <td>
                    {{ item.total_mood_taken }}
                  </td>
                  <td>{{ parseFloat(item.mood).toFixed(2) }}%</td>
                  <td>{{ parseFloat(item.energy_level).toFixed(2) }}%</td>
                  <td>{{ parseFloat(item.sleep_duration).toFixed(2) }}%</td>
                  <td>{{ parseFloat(item.exercise).toFixed(2) }}%</td>
                  <td>{{ parseFloat(item.meditation).toFixed(2) }}%</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import MainHeader from "@/components/MainHeader.vue";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
export default {
  components: { MainHeader, BaseLayout },
  data() {
    return {
      isGettingAnalytics: false,
      analyticsAllData: {
        total: [],
        callDates: [],
      },

      next_payment: null,
      total_employees: 0,
      total_payments_general: 0,
      total_subscriptions: 0,
      total_to_pay_per_current_subscription: 0,
      subsvription_expiration_date: "",
      average_mood_whole_week: [],
      request_for_change: null,

      chartOptionsOne: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#0f676c"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesOne: [
        {
          name: "call sessions",
          data: [],
        },
      ],
    };
  },

  methods: {
    assignAnalyticsData(data) {
      this.next_payment = data.next_payment;
      this.total_employees = data.total_employees;
      this.total_payments_general = data.total_payments_general;
      this.total_subscriptions = data.total_subscriptions;
      this.total_to_pay_per_current_subscription =
        data.total_to_pay_per_current_subscription;
      this.subsvription_expiration_date = data.subsvription_expiration_date;
      this.average_mood_whole_week = data.average_mood_whole_week;
      this.request_for_change = data.request_for_change;

      if (data.call_sessions.length) {
        data.call_sessions.map((item) => {
          this.analyticsAllData.total.push(item.total);
          this.analyticsAllData.callDates.push(item.month);

          this.seriesOne = [
            {
              name: "call sessions",
              data: this.analyticsAllData.total,
            },
          ];
          this.chartOptionsOne = {
            xaxis: {
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
          };
        });
      }
    },
    getCompanyAnalytics() {
      this.isGettingAnalytics = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/dashboard/analytics`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.assignAnalyticsData(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingAnalytics = false;
        });
    },
  },

  created() {
    this.getCompanyAnalytics();
  },
};
</script>

<style scoped>
.emp-analytics-body {
  width: 90%;
  background: white;
  padding: 20px;
  margin: 40px auto 0;
}
.p--1 {
  margin: 0;
}
.p--1 b {
  font-weight: 500;
}
.failure-color,
.success-color {
  padding: 1px 10px;
  border-radius: 20px;
  color: white;
  font-size: 14px;
}
.failure-color {
  background: #a60909;
}
.success-color {
  background: #5e8722;
}
</style>
