<template>
  <base-layout>
    <template #content>
      <div class="comp-details-service">
        <div class="comp-details-service-top">
          <button
            :class="{ 'active-tab': isToggleWhatToDisplay == 'Overview' }"
            @click="toggleDisplayHandler('Overview')"
          >
            Overview
          </button>
          <button
            :class="{ 'active-tab': isToggleWhatToDisplay == 'Employees' }"
            @click="toggleDisplayHandler('Employees')"
          >
            Employees list
          </button>
          <button
            :class="{ 'active-tab': isToggleWhatToDisplay == 'Subscription' }"
            @click="toggleDisplayHandler('Subscription')"
          >
            Subscription
          </button>
          <button
            :class="{ 'active-tab': isToggleWhatToDisplay == 'Payments' }"
            @click="toggleDisplayHandler('Payments')"
          >
            Payments
          </button>
        </div>
        <div class="comp-details-service-bottom">
          <div v-if="isToggleWhatToDisplay == 'Overview'">
            <div
              class="g-flex g-align-center g-content-center"
              style="width: 100%"
              v-if="!companyDetails"
            >
              <table-data-loader></table-data-loader>
            </div>
            <div v-if="companyDetails">
              <company-overview :allDetails="companyDetails"></company-overview>
            </div>
          </div>
          <div v-if="isToggleWhatToDisplay == 'Employees'">
            <employees-list></employees-list>
          </div>
          <div v-if="isToggleWhatToDisplay == 'Subscription'">
            <company-details-subsc
              :returnOnSuccess="returnSubscAfterChanges"
              :retrunUpdateSubscription="retrunUpdateSubscription"
              :returnDeleteSubscription="returnDeleteSubscription"
            ></company-details-subsc>
          </div>
          <div v-if="isToggleWhatToDisplay == 'Payments'">
            <company-payments
              :allCompanyDetails="companyDetails"
            ></company-payments>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>
  
<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import EmployeesList from "@/components/EmployeesList.vue";
import CompanyDetailsSubsc from "@/components/CompanyDetailsSubsc.vue";
import CompanyOverview from "@/components/CompanyOverview.vue";
import CompanyPayments from "@/components/CompanyPayments.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
export default {
  components: {
    BaseLayout,
    EmployeesList,
    CompanyDetailsSubsc,
    CompanyOverview,
    CompanyPayments,
    TableDataLoader,
  },
  data() {
    return {
      isToggleWhatToDisplay: "Overview",
      companyDetails: null,
      isGettingClientDetails: false,
      clientId: this.$route.params.id,
    };
  },
  methods: {
    toggleDisplayHandler(value) {
      if (value == "Overview") {
        this.isToggleWhatToDisplay = "Overview";
      }
      if (value == "Employees") {
        this.isToggleWhatToDisplay = "Employees";
      }
      if (value == "Payments") {
        this.isToggleWhatToDisplay = "Payments";
      }
      if (value == "Subscription") {
        this.isToggleWhatToDisplay = "Subscription";
      }
      if (value == "Subscription") {
        sessionStorage.setItem("currentTab", value);
      } else {
        sessionStorage.removeItem("currentTab");
      }
    },
    getClientDetailsHandler() {
      this.isGettingClientDetails = false;

      axios
        .get(`${this.$store.state.apiUrl}/v1/uhuru/clients/${this.clientId}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.companyDetails = response.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingClientDetails = false;
        });
    },
    returnSubscAfterChanges(data) {
      this.companyDetails.subscriptions.push(data);
    },

    retrunUpdateSubscription(data) {
      this.companyDetails.subscriptions = this.companyDetails.subscriptions.map(
        (item) => {
          if (item.id === data.id) {
            return data;
          }
          return item;
        }
      );
    },
    returnDeleteSubscription(id) {
      this.companyDetails.subscriptions =
        this.companyDetails.subscriptions.filter((item) => item.id !== id);
    },
  },
  created() {
    this.getClientDetailsHandler();
    if (sessionStorage.getItem("currentTab")) {
      this.isToggleWhatToDisplay = sessionStorage.getItem("currentTab");
    }
  },
};
</script>
  
<style scoped>
.comp-details-service-top {
  border-bottom: 4px solid #dddddd;
  margin: 20px;
  width: fit-content;
}
.comp-details-service-top button {
  width: 150px;
  height: 30px;
  border: none;
  background: transparent;
}
.comp-details-service-top button:hover {
  background: var(--third-main-color);
  color: var(--primary-color);
}
.active-tab {
  background-color: var(--third-main-color) !important;
  border-bottom: 4px solid var(--primary-color) !important;
  margin-bottom: -4px;
}
.comp-details-service-bottom {
  padding: 10px 20px;
  border: 1px solid #dddddd;
}
</style>