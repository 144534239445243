<template>
  <div>
    <div v-if="callSessionDetails && !isGettingMoreOnCallSession">
      <p><b>CALL SESSIONS</b></p>
      <p class="bordering--1">
        <b class="underline--1">Key point: </b
        ><span v-html="callSessionDetails.key_point"></span>
      </p>
      <p class="bordering--1">
        <b class="underline--1">Overview: </b
        ><span v-html="callSessionDetails.overview"></span>
      </p>
      <p class="bordering--1">
        <b class="underline--1">Summary: </b
        ><span v-html="callSessionDetails.summary"></span>
      </p>
    </div>
    <div
      style="width: 100%"
      class="g-flex g-content-center"
      v-if="isGettingMoreOnCallSession"
    >
      <table-data-loader></table-data-loader>
    </div>
  </div>
</template>

<script>
import formatDateMixin from "@/mixins/formatDateMixin";
import TableDataLoader from "./reusable/TableDataLoader.vue";
export default {
  components: { TableDataLoader },
  props: ["callSessionDetails", "isGettingMoreOnCallSession"],
  mixins: [formatDateMixin],
};
</script>

<style scoped>
p {
  margin: 0;
}
.bordering--1 {
  border: 1px solid #dddddd;
  padding: 10px;
  margin: 10px 0;
}
.underline--1 {
  text-decoration: underline;
}
</style>