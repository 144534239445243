<template>
  <div>
    <main-header></main-header>
    <div class="consult-cont">
      <h3 class="heading--1">CONSULTATIONS</h3>
      <div class="consult-search g-flex">
        <div class="g-flex g-align-center">
          <label>From</label>
          <input
            type="date"
            v-model="rangeFrom"
            @input="searchByEveryThingHandler"
          />
          <label style="margin-left: 10px">To</label>
          <input
            type="date"
            v-model="rangeTo"
            @input="searchByEveryThingHandler"
          />
        </div>
        <div style="width: 300px; margin-left: 40px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchValue"
            @keyup.enter="searchByEveryThingHandler"
          />
          <button @click="searchByEveryThingHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div class="consult-body" v-if="!isGettingConsultations">
        <div
          class="single-consult-div"
          v-for="item in consultationsList"
          :key="item.id"
        >
          <img
            src="/images/Group_91.svg"
            alt=""
            class="single-patient-option"
            @click="item.isShow = !item.isShow"
          />
          <p style="margin-top: 20px">
            <b>Patient: </b>{{ item.employee.name }}
          </p>
          <p><b>Company: </b>{{ item.employee.loginDetails.company }}</p>
          <p><b>Date of visit: </b>{{ item.date_of_visit }}</p>
          <div class="g-flex"></div>
          <span v-if="item.isShow">
            <div class="patient-option-btn-div">
              <button @click="toggleViewSingleConsultation(item)">
                View Details
              </button>
              <!-- <button @click="editConsultationHandler(item)">Edit</button> -->
              <!-- <button @click="toggleDeleteConsultation(item.id)">Delete</button> -->
              <button @click.stop="toggleAddNewFollowUpHandler(item)">
                Add Follow-up
              </button>
              <button @click.stop="viewFollowUpHandler(item.id)">
                View Follow-ups
              </button>
            </div>
          </span>
        </div>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingConsultations && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getMyConsultations"
          />
        </div>
      </div>
      <div
        v-if="isGettingConsultations"
        style="width: 100%; margin-top: 20px"
        class="g-flex g-content-center"
      >
        <table-data-loader></table-data-loader>
      </div>

      <div v-if="isToggleDeleteWarning">
        <warning-msg
          message="Are you sure you want to delete this Consultations"
          :onClick="deleteConsultationHandler"
          :onClosingModal="toggleDeleteConsultation"
          :isOnLoading="isDeletingConsult"
        ></warning-msg>
      </div>

      <div v-if="isToggleViewConsultation">
        <modal :closeModal="toggleViewSingleConsultation" width="750px">
          <template #content>
            <div>
              <single-consultation
                :selectedConsultation="selectedConsultation"
              ></single-consultation>
            </div>
          </template>
        </modal>
      </div>

      <div v-if="isToggleAddNewFollowUp">
        <modal :closeModal="toggleAddNewFollowUpHandler" width="550px">
          <template #content>
            <div>
              <p style="margin-bottom: 20px">FOLLOW UP FORM</p>
              <label style="margin-bottom: 10px">Follow up date</label>
              <input
                style="margin-bottom: 20px"
                type="date"
                placeholder=""
                v-model="followUpDate"
              />
              <label style="margin-bottom: 20px">Details</label>
              <div style="height: 320px">
                <wysiwyg v-model="followUpDetails"></wysiwyg>
              </div>
              <div style="width: 120px">
                <base-button
                  name="Save"
                  :isLoading="isAddingNewFollowUp"
                  :onClick="addNewFollowUpHandler"
                ></base-button>
              </div>
            </div>
          </template>
        </modal>
      </div>

      <div v-if="isToggleViewFolloUp">
        <modal :closeModal="toggleViewFollowUpHandler" width="650px">
          <template #content>
            <p>FOLLOW UP LISTS</p>
            <div v-if="followUpsList.length && !isGettingFollowUp">
              <div style="margin: 20px 0">
                <table>
                  <thead>
                    <tr>
                      <th>Details</th>
                      <th>Follow up date</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in followUpsList" :key="item.id">
                      <td v-html="item.details"></td>
                      <td>{{ formatDate(item.follow_up_date) }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div v-if="!followUpsList.length && !isGettingFollowUp">
              <p style="margin: 12px 0">
                No follow-up on selected consultation
              </p>
            </div>
            <div
              v-if="isGettingFollowUp"
              style="width: 100%; margin-top: 20px"
              class="g-flex g-content-center"
            >
              <table-data-loader></table-data-loader>
            </div>
          </template>
        </modal>
      </div>
    </div>
  </div>
</template>
  
  <script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";

import MainHeader from "@/components/MainHeader.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import SecondaryButton from "@/components/reusable/SecondaryButton.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import Modal from "@/components/reusable/Modal.vue";
import SingleConsultation from "@/components/SingleConsultation.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import formatDateMixin from "@/mixins/formatDateMixin";

export default {
  components: {
    MainHeader,
    BaseButton,
    TableDataLoader,
    SecondaryButton,
    WarningMsg,
    Modal,
    SingleConsultation,
    Wysiwyg,
  },
  data() {
    return {
      isGettingConsultations: false,
      isGettingSingleConsultation: false,
      consultationsList: [],
      singlePatientDetails: null,
      activePatient: "",

      consultationsToDelete: "",
      isToggleDeleteWarning: false,
      isDeletingConsult: false,
      selectedConsultation: null,
      isToggleViewConsultation: false,

      isAddingNewFollowUp: false,
      isToggleAddNewFollowUp: false,
      consultationsToAddFollowUp: "",
      followUpDate: "",
      followUpDetails: "",
      followUpsList: [],
      isGettingFollowUp: false,
      isToggleViewFolloUp: false,

      rangeFrom: "",
      rangeTo: this.formatDate(new Date()),
      searchValue: "",
      isOnSearchingConsultations: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin],
  methods: {
    searchByEveryThingHandler() {
      if (!this.rangeFrom && !this.searchValue) {
        return;
      }
      this.isOnSearchingConsultations = true;
      this.getMyConsultations();
    },

    generateUrlHandler() {
      if (
        this.isOnSearchingConsultations &&
        this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/therapists/consultations?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&search=${this.searchValue}`;
      }

      if (
        this.isOnSearchingConsultations &&
        this.searchValue &&
        !this.rangeFrom
      ) {
        return `${this.$store.state.apiUrl}/v1/therapists/consultations?search=${this.searchValue}`;
      }

      if (
        this.isOnSearchingConsultations &&
        !this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/therapists/consultations?range_from=${this.rangeFrom}&range_until=${this.rangeTo}`;
      }

      if (!this.isOnSearchingConsultations) {
        return `${this.$store.state.apiUrl}/v1/therapists/consultations`;
      }
    },

    toggleAddNewFollowUpHandler(item) {
      this.consultationsToAddFollowUp = item.id;
      this.isToggleAddNewFollowUp = !this.isToggleAddNewFollowUp;
      item.isShow = !item.isShow;
    },
    addNewFollowUpHandler() {
      if (!this.followUpDate || !this.followUpDetails) {
        return toast.error("Please fill the form well", {
          theme: "colored",
        });
      }
      this.isAddingNewFollowUp = true;
      let data = {
        follow_up_date: this.followUpDate,
        details: this.followUpDetails,
      };
      axios
        .post(
          `${this.$store.state.apiUrl}/v1/therapists/consultations/${this.consultationsToAddFollowUp}/add-follow-up`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isAddingNewFollowUp = false;
          this.isToggleAddNewFollowUp = !this.isToggleAddNewFollowUp;
          this.consultationsList = this.consultationsList.map((item) => {
            if (item.isShow) {
              return { ...item, isShow: false };
            } else {
              return item;
            }
          });
        });
    },

    toggleViewFollowUpHandler() {
      this.isToggleViewFolloUp = !this.isToggleViewFolloUp;
    },

    viewFollowUpHandler(id) {
      this.isToggleViewFolloUp = true;
      this.isGettingFollowUp = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/therapists/consultations/${id}/view-all-followups`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.followUpsList = response.data.data.data;
          this.consultationsList = this.consultationsList.map((item) => {
            if (item.isShow) {
              return { ...item, isShow: false };
            } else {
              return item;
            }
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingFollowUp = false;
        });
    },

    toggleViewSingleConsultation(item) {
      this.selectedConsultation = item;
      this.isToggleViewConsultation = !this.isToggleViewConsultation;
      item.isShow = !item.isShow;
    },

    toggleDeleteConsultation(item) {
      this.consultationsToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteConsultationHandler() {
      this.isDeletingConsult = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/therapists/consultations/${this.consultationsToDelete}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.consultationsList = this.consultationsList.filter(
            (item) => item.id !== this.consultationsToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingConsult = false;
          this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
        });
    },

    editConsultationHandler(item) {
      sessionStorage.setItem("editConsultation", JSON.stringify(item));
      this.$router.push("/add-consultations/" + item.id);
    },

    getMyConsultations(page) {
      this.isGettingConsultations = true;

      let actionUrl = "";

      if (!this.isOnSearchingConsultations) {
        actionUrl = this.generateUrlHandler() + `?page=${page}`;
      } else {
        actionUrl = this.generateUrlHandler() + `&page=${page}`;
      }

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.consultationsList = response.data.data.data.map((item) => ({
            ...item,
            isShow: false,
          }));
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingConsultations = false;
        });
    },

    getSingleConsultationDetails(employee_id) {
      this.isGettingSingleConsultation = true;
      this.activePatient = employee_id;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/therapists/patients/${employee_id}/details`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          console.log(response.data.data);
          this.singlePatientDetails = response.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSingleConsultation = false;
        });
    },
  },
  created() {
    this.getMyConsultations();
  },
};
</script>
  
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.consult-cont {
  width: 80%;
  margin: 30px auto;
  padding: 20px;
  border-radius: 10px;
  background-color: white;
}
.consult-search {
  justify-content: center;
  margin: 30px auto;
}
.consult-body {
  border-top: 0.5px solid #a8a8a8;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.single-consult-div {
  border: 0.5px solid #a8a8a8;
  margin: 10px;
  padding: 10px;
  width: 240px;
  position: relative;
}
.single-consult-div p {
  font-size: 15px;
  margin: 4px 0;
  font-style: italic;
}
.single-consult-div b {
  font-weight: 500;
  font-style: normal !important;
}
.single-consult-div > div button {
  background: #ffffff;
  border: 1px solid var(--primary-color);
  color: var(--primary-color);
  padding: 4px 10px;
  border-radius: 4px;
  font-size: 14px;
  width: 100%;
  margin: 10px 6px 4px 0;
}
.single-consult-div button:hover {
  background: #1e4b7d;
  cursor: pointer;
  color: #ffffff;
}
.single-patient-option {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.patient-option-btn-div {
  position: absolute;
  right: 10px;
  top: 36px;
  display: flex;
  flex-direction: column;
  width: 180px;
  background: white;
  padding: 5px 0;
  border: 1px solid #aaaaaa;
  border-radius: 4px;
  z-index: 100;
}
.patient-option-btn-div button {
  text-align: left;
  background: transparent;
  border: none;
  height: 24px;
}
.patient-option-btn-div button:hover {
  background: var(--primary-color);
  color: white;
}
</style>