import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import VueApexCharts from "vue3-apexcharts";

import VueAwesomePaginate from "vue-awesome-paginate";

// import the necessary css file
import "vue-awesome-paginate/dist/style.css";

createApp(App).use(store).use(router).use(VueApexCharts).use(VueAwesomePaginate).mount('#app')


// Navigation guard to check authentication status
router.beforeEach((to, from, next) => {
    if (to.matched.some((route) => route.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next();
        } else {
            next('/');
        }
    } else {
        next();
    }
});