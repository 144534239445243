import { createStore } from 'vuex'

const store = createStore({
  state: {
    authUser: {
      name: "",
      email: "",
      role: "",
      token: null,
      company: "",
      isAuthenticated: false,
      active: false,
      type: "",
      verified: false,
      isSubscriptionPaid: false,
    },
    apiUrl: process.env.VUE_APP_API_URL,
    isToggleActionsDpDown: false,
    patientInfoActiveTab: "personal_info",
    employeesListToManage: [],
    selectedEmployeeForCall: ""
  },

  getters: {
    isAuthenticated(state) {
      return state.authUser.isAuthenticated;
    },
  },

  actions: {
    checkAuthentication({ commit }) {
      const loggedInUser = localStorage.getItem('userAuth');
      if (loggedInUser) commit('setUserAuthentication', JSON.parse(loggedInUser));
    },
  },

  mutations: {
    setUserAuthentication(state, payload) {
      state.authUser.name = payload.data.name;
      state.authUser.email = payload.data.email;
      state.authUser.role = payload.data.role;
      state.authUser.company = payload.data.company || payload.data.name;
      state.authUser.active = payload.data.active;
      state.authUser.type = payload.data.type || null;
      state.authUser.verified = payload.data.verified;
      state.authUser.isSubscriptionPaid = payload.data.is_subscription_paid;
      state.authUser.isAuthenticated = payload.status == "success" ? true : false;
      state.authUser.token = payload.token;
    },
    setUserWhenProfileChanged(state, payload) {
      state.authUser.name = payload.name;
      state.authUser.company = payload.company;
    },
    setUserLogoutReset(state) {
      state.authUser.name = "";
      state.authUser.email = "";
      state.authUser.role = "";
      state.authUser.company = "";
      state.authUser.active = false;
      state.authUser.type = "";
      state.authUser.verified = false;
      state.authUser.isAuthenticated = false;
      state.authUser.token = null;
    },
    setToggleActionsDpDown(state, payload) {
      state.isToggleActionsDpDown = payload
    },
    setPatientInfoActiveTab(state, payload) {
      state.patientInfoActiveTab = payload
    },
    setEmployeesListToManage(state, payload) {
      state.employeesListToManage = payload
    },
    setSelectedEmployeeForCall(state, payload) {
      state.patientInfoActiveTab = payload
    },
  },
})


store.dispatch('checkAuthentication');

export default store;