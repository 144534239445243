<template>
  <div>
    <table>
      <thead>
        <tr>
          <th>Name</th>
          <th>Value</th>
          <th>Email</th>
          <th>Role</th>
          <th>Location</th>
          <th>Gender</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in users" :key="user.id">
          <td class="g-flex g-align-center">
            <user-circle
              :currentIndex="index"
              :currentUser="user.name"
              marginRight="10px"
            ></user-circle
            >{{ user.name }}
          </td>
          <td>{{ user.value }}</td>
          <td>{{ user.email }}</td>
          <td>{{ user.role }}</td>
          <td>{{ user.location }}</td>
          <td>{{ user.gender }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import UserCircle from "./UserCircle.vue";
export default {
  components: { UserCircle },
  data() {
    return {
      users: [
        {
          id: 1,
          name: "John Doe",
          value: 30,
          email: "john@example.com",
          role: "Admin",
          location: "New York",
          gender: "Male",
        },
        {
          id: 2,
          name: "Jane Smith",
          value: 25,
          email: "jane@example.com",
          role: "User",
          location: "Los Angeles",
          gender: "Female",
        },
        {
          id: 3,
          name: "Michael Johnson",
          value: 35,
          email: "michael@example.com",
          role: "User",
          location: "Chicago",
          gender: "Male",
        },
        {
          id: 4,
          name: "Emily Williams",
          value: 28,
          email: "emily@example.com",
          role: "User",
          location: "Houston",
          gender: "Female",
        },
        {
          id: 5,
          name: "Robert Brown",
          value: 32,
          email: "robert@example.com",
          role: "User",
          location: "San Francisco",
          gender: "Male",
        },
        {
          id: 6,
          name: "Sarah Lee",
          value: 29,
          email: "sarah@example.com",
          role: "User",
          location: "Miami",
          gender: "Female",
        },
        {
          id: 7,
          name: "David Wilson",
          value: 27,
          email: "david@example.com",
          role: "User",
          location: "Seattle",
          gender: "Male",
        },
        {
          id: 8,
          name: "Olivia Thomas",
          value: 31,
          email: "olivia@example.com",
          role: "User",
          location: "Denver",
          gender: "Female",
        },
        {
          id: 9,
          name: "William Garcia",
          value: 26,
          email: "william@example.com",
          role: "User",
          location: "Boston",
          gender: "Male",
        },
        {
          id: 10,
          name: "Ava Rodriguez",
          value: 24,
          email: "ava@example.com",
          role: "User",
          location: "Atlanta",
          gender: "Female",
        },
      ],
    };
  },
};
</script>

<style scoped>
</style>