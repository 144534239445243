<template>
  <base-layout>
    <template #content>
      <change-user-profile></change-user-profile>
    </template>
  </base-layout>
</template>
    
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import employeeMixin from "../mixins/employeeMixin";
import ChangePassword from "@/components/ChangePassword.vue";
import ChangeUserProfile from "@/components/ChangeUserProfile.vue";

export default {
  components: {
    BaseLayout,
    TableDataLoader,
    BaseButton,
    ChangePassword,
    ChangeUserProfile,
  },
  mixins: [employeeMixin],
  data() {
    return {
      isGettingUserProfile: false,
      name: "",
      email: "",
      phone: "",
      password: "",
      confirmPassword: "",
      isUpdatingProfile: false,
      registerErrors: {},
    };
  },
  methods: {
    updateProfileAfterSave(data) {
      let displayData = {
        name: data.name,
        company:
          this.$store.state.authUser.role == "Employee"
            ? this.$store.state.authUser.company
            : data.name,
      };

      const loggedInUser = localStorage.getItem("userAuth");

      let storedData = JSON.parse(loggedInUser);

      let storeNewUser = {
        data: {
          active: storedData.data.active,
          email: storedData.data.email,
          name: displayData.name,
          role: storedData.data.role,
          type: storedData.data.type,
          verified: storedData.data.verified,
        },
        message: storedData.message,
        status: storedData.status,
        token: storedData.token,
      };

      localStorage.removeItem("userAuth");

      this.$store.commit("setUserWhenProfileChanged", displayData);
      localStorage.setItem("userAuth", JSON.stringify(storeNewUser));
    },

    getUserProfile() {
      this.isGettingUserProfile = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/profile`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((res) => {
          this.name = res.data.data.name;
          this.email = res.data.data.email;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingUserProfile = false;
        });
    },

    validateForm() {
      this.registerErrors = {};

      if (!this.name) {
        this.registerErrors.name = true;
      }
      if (!this.email) {
        this.registerErrors.email = true;
      }

      return !this.registerErrors.name && !this.registerErrors.email;
    },

    updateUserProfileHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isUpdatingProfile = true;

      axios
        .put(
          `${this.$store.state.apiUrl}/v1/change-profile?name=${this.name}&email=${this.email}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.updateProfileAfterSave(response.data.data);
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isUpdatingProfile = false;
        });
    },
  },
  created() {
    this.getUserProfile();
  },
};
</script>
    
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.user-profile-container {
  padding: 10px 20px;
}
.profile-loader {
  width: 100%;
  margin: 40px 0;
}
.comp-profile-form {
  width: 100%;
}
.comp-profile-form-left,
.comp-profile-form-right {
  width: 50%;
}
.comp-profile-form-left {
  margin: 10px 20px 10px 10px;
}
.comp-profile-form-right {
  margin: 10px 10px 10px 20px;
}
input {
  margin-bottom: 10px;
}
.heading--1 {
  text-transform: uppercase;
  margin: 10px 0 20px;
  font-weight: 500;
  font-size: 15px;
}
.heading--2 {
  margin: 10px 0 -10px 10px;
}
</style>