<template>
  <base-layout>
    <template #content>
      <div>
        <div class="subsc-header g-flex g-align-center g-space-btw">
          <h3>Extended subscriptions</h3>
          <div class="g-flex g-align-center">
            <label>From</label>
            <input
              type="date"
              v-model="rangeFrom"
              @input="searchByEveryThingHandler"
            />
            <label style="margin-left: 6px">To</label>
            <input
              type="date"
              v-model="rangeTo"
              @input="searchByEveryThingHandler"
            />
          </div>
          <div style="width: 300px" class="global-search">
            <input
              type="text"
              placeholder="Search..."
              v-model="searchSubscs"
              @keyup.enter="searchByEveryThingHandler"
            />
            <button @click="searchByEveryThingHandler">
              <i class="fa-solid fa-magnifying-glass"></i>
            </button>
          </div>
          <div class="g-filter-cont">
            <i class="fa-solid fa-filter"></i>
            <select
              v-model="filterStatusValue"
              @change="searchByEveryThingHandler"
            >
              <option value="">Filter</option>
              <option value="Approved">Approved</option>
              <option value="Pending">Pending</option>
              <option value="Denied">Denied</option>
            </select>
          </div>
        </div>
        <div style="padding: 0 10px">
          <table>
            <thead>
              <tr>
                <th>Company</th>
                <th>Payment status</th>
                <th>Consumers</th>
                <th>Requested at</th>
                <th>Status</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody v-if="subscriptionList.length && !isGettingSubsc">
              <tr v-for="item in subscriptionList" :key="item.id">
                <td>{{ item.client.name }}</td>
                <td>
                  <span class="paid-status" v-if="item.paid">Paid</span>
                  <span class="not-paid-status" v-if="!item.paid"
                    >Not Paid</span
                  >
                </td>
                <td>{{ item.slots }}</td>
                <td>
                  {{ item.created_at }}
                </td>
                <td
                  :class="
                    item.status == 'Approved' ? 'green-status' : 'red-status'
                  "
                >
                  {{ item.status }}
                </td>
                <td>
                  <!-- <button
                    v-if="!item.confirmed"
                    @click="toggleApproveSubscription(item.id)"
                  >
                    Approve
                  </button>
                  <button
                    v-if="item.paid"
                    @click="toggleViewPaymentsHandler(item.payment)"
                  >
                    Payment
                  </button> -->

                  <actions-btn v-if="item.paid || !item.confirmed">
                    <template #content>
                      <button
                        class="action-btn-item"
                        v-if="!item.confirmed"
                        @click="toggleApproveSubscription(item.id)"
                      >
                        Approve
                      </button>
                      <button
                        class="action-btn-item"
                        v-if="item.paid"
                        @click="toggleViewPaymentsHandler(item.payment)"
                      >
                        Payment
                      </button>
                    </template>
                  </actions-btn>
                </td>
              </tr>
            </tbody>
          </table>

          <div class="uhuru-pg-container" v-if="!isGettingSubsc && hasNextPage">
            <vue-awesome-paginate
              :total-items="totalPgItems"
              :items-per-page="10"
              :max-pages-shown="8"
              v-model="currentPage"
              paginate-buttons-class="pg-btn"
              active-page-class="pg-btn-active"
              back-button-class="pg-back-btn"
              next-button-class="pg-next-btn"
              prev-button-content="Prev"
              next-button-content="Next"
              :on-click="getExtendedSubscriptionsHandler"
            />
          </div>

          <div
            style="width: 100%; margin: 10px"
            class="g-flex g-align-center g-content-center"
            v-if="isGettingSubsc"
          >
            <table-data-loader></table-data-loader>
          </div>
          <div
            style="width: 100%; margin: 10px"
            class="g-flex g-align-center g-content-center"
            v-if="!isGettingSubsc && !subscriptionList.length"
          >
            <p>No extend subscription found</p>
          </div>
        </div>

        <div v-if="isToggleApproveSubsc">
          <warning-msg
            message="Are you sure you want to approve this extended subscription"
            :onClick="approveExtendedSubscriptionsHandler"
            :onClosingModal="toggleApproveSubscription"
            :isOnLoading="isApprovingSubsc"
          ></warning-msg>
        </div>

        <div v-if="isToggleViewPayments">
          <modal :closeModal="toggleViewPaymentsHandler" width="800px">
            <template #content>
              <p><b>PAYMENTS</b></p>
              <table>
                <thead>
                  <tr>
                    <th>Paid amount</th>
                    <th>Payment date</th>
                    <th>Payment mode</th>
                    <th>Payment month</th>
                    <th>Reference number</th>
                    <!-- <th>Notes</th> -->
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody v-if="selectedPayments.length">
                  <tr v-for="item in selectedPayments" :key="item.id">
                    <td>{{ formatMoney(item.paid_amount) }}</td>
                    <td>{{ formatDate(item.payment_date) }}</td>
                    <td>{{ item.payment_mode }}</td>
                    <td>{{ item.payment_month }}</td>
                    <td>{{ item.reference_number }}</td>
                    <!-- <td>{{ item.notes ? "-" : item.notes }}</td> -->
                    <td>
                      <a target="_blank" :href="item.proof">
                        <button class="subsc-act-btn">Proof</button>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </modal>
        </div>
      </div>
    </template>
  </base-layout>
</template>
    
<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import formatMoney from "@/mixins/formatMoney";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";

export default {
  components: {
    BaseLayout,
    ActionsBtn,
    TableDataLoader,
    BaseButton,
    Modal,
    WarningMsg,
  },
  data() {
    return {
      subscriptionList: [],
      isGettingSubsc: false,
      subscId: this.$route.params.id,

      subscToApprove: "",
      isToggleApproveSubsc: false,
      isApprovingSubsc: false,

      isToggleViewPayments: false,
      selectedPayments: [],

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,

      filterStatusValue: "",
      rangeFrom: "",
      rangeTo: this.formatDate(new Date()),
      searchSubscs: "",
      isOnSearchingSubsc: false,
    };
  },
  mixins: [formatDateMixin, formatMoney],
  methods: {
    toggleViewPaymentsHandler(data) {
      this.selectedPayments = data;
      this.isToggleViewPayments = !this.isToggleViewPayments;
    },

    toggleApproveSubscription(id) {
      this.subscToApprove = id;
      this.isToggleApproveSubsc = !this.isToggleApproveSubsc;
    },

    approveExtendedSubscriptionsHandler() {
      this.isApprovingSubsc = true;
      axios
        .put(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/change-requests/${this.subscToApprove}/approve`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.getExtendedSubscriptionsHandler();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isApprovingSubsc = false;
          this.isToggleApproveSubsc = false;
        });
    },

    searchByEveryThingHandler() {
      if (!this.rangeFrom && !this.searchSubscs && !this.filterStatusValue) {
        return;
      }
      this.isOnSearchingSubsc = true;
      this.getExtendedSubscriptionsHandler();
    },

    generateUrlHandler() {
      if (
        this.isOnSearchingSubsc &&
        this.searchSubscs &&
        this.rangeFrom &&
        this.rangeTo &&
        this.filterStatusValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&search=${this.searchSubscs}&mode=${this.filterStatusValue}`;
      }
      if (
        this.isOnSearchingSubsc &&
        this.searchSubscs &&
        this.rangeFrom &&
        this.rangeTo &&
        !this.filterStatusValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&search=${this.searchSubscs}`;
      }
      if (
        this.isOnSearchingSubsc &&
        this.searchSubscs &&
        !this.rangeFrom &&
        this.filterStatusValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?search=${this.searchSubscs}&mode=${this.filterStatusValue}`;
      }
      if (
        this.isOnSearchingSubsc &&
        !this.searchSubscs &&
        this.rangeFrom &&
        this.rangeTo &&
        this.filterStatusValue
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&mode=${this.filterStatusValue}`;
      }
      if (
        this.isOnSearchingSubsc &&
        !this.searchSubscs &&
        !this.filterStatusValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?range_from=${this.rangeFrom}&range_until=${this.rangeTo}`;
      }
      if (
        this.isOnSearchingSubsc &&
        this.searchSubscs &&
        !this.filterStatusValue &&
        !this.rangeFrom
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?search=${this.searchSubscs}`;
      }
      if (
        this.isOnSearchingSubsc &&
        !this.searchSubscs &&
        this.filterStatusValue &&
        !this.rangeFrom
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes?mode=${this.filterStatusValue}`;
      }
      if (!this.isOnSearchingSubsc) {
        return `${this.$store.state.apiUrl}/v1/uhuru/request-for-changes`;
      }
    },

    getExtendedSubscriptionsHandler(page) {
      this.isGettingSubsc = true;

      let actionUrl = "";
      if (!this.isOnSearchingSubsc) {
        actionUrl = this.generateUrlHandler() + `?page=${page}`;
      } else {
        actionUrl = this.generateUrlHandler() + `&page=${page}`;
      }

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.subscriptionList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingSubsc = false;
        });
    },
  },
  created() {
    this.getExtendedSubscriptionsHandler();
  },
};
</script>
    
  <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.subsc-header {
  margin: 10px;
}
.subsc-header input {
  margin-bottom: 0 !important;
}
.g-filter-cont {
  display: flex;
  align-items: center;
  background: #f5f5f5;
  border: 1px solid #818181;
  border-radius: 4px;
  position: relative;
}
.g-filter-cont i {
  position: absolute;
  left: 10px;
  color: #c2c2c2;
}

.g-filter-cont select {
  border: none !important;
  width: 120px;
  padding-left: 26px;
  margin-bottom: 0 !important;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-subsc-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input,
textarea,
select {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
}
.extend-label-div {
  padding: 8px 0 16px !important;
}
.extend-msg {
  font-size: 15px;
  margin-bottom: 10px;
}
.paid-status,
.not-paid-status {
  padding: 1px 10px;
  border-radius: 20px;
  color: white;
  font-size: 12px;
}
.paid-status {
  background: #5e8722;
}
.not-paid-status {
  background: #a60909;
}
</style>