<template>
  <div>
    <div class="personal-info-header">
      <div class="g-flex g-align-center">
        <user-circle
          currentIndex="0"
          :currentUser="$store.state.authUser.name"
          marginRight="0"
          marginLeft="0"
          width="60px"
          height="60px"
        ></user-circle>
        <div>
          <p>{{ $store.state.authUser.name }}</p>
          <p>
            Status:
            <span
              :class="$store.state.authUser.active ? 'make-green' : 'make-red'"
            >
              {{ $store.state.authUser.active ? "Active" : "Inative" }}
            </span>
          </p>
        </div>
      </div>
      <div class="personal-company">
        <p><b>COMPANY</b></p>
        <P>{{ $store.state.authUser.company }}</P>
      </div>
    </div>

    <div class="personal-info-bottom g-flex" style="margin-top: 20px">
      <div>
        <p><b>Insurance: </b>{{ patientDetails.insurance }}</p>
        <p><b>National ID No.: </b>{{ patientDetails.nid }}</p>
        <p><b>Phone: </b>{{ patientDetails.phone }}</p>
        <p><b>Date of birth: </b>{{ patientDetails.date_of_birth }}</p>
        <p><b>Gender: </b>{{ patientDetails.gender }}</p>
        <p><b>Marital status: </b>{{ patientDetails.marital_status }}</p>
        <p><b>Professional: </b>{{ patientDetails.profession }}</p>
        <p><b>Education: </b>{{ patientDetails.education }}</p>
        <p><b>Address: </b>{{ patientDetails.address }}</p>
        <p><b>Church: </b>{{ patientDetails.church }}</p>
        <p><b>Place of birth: </b>{{ patientDetails.place_of_birth }}</p>
        <p><b>Mother`s name: </b>{{ patientDetails.mothers_name }}</p>
        <p><b>Father`s name: </b>{{ patientDetails.fathers_name }}</p>
      </div>
      <div style="margin-left: 100px">
        <div class="personal-info-title">
          <p><b>TITLE: </b>{{ patientDetails.title }}</p>
        </div>
        <p>
          <b>Email: </b
          >{{ patientDetails.employment_details.loginDetails.email }}
        </p>
        <p>
          <b>Employee number: </b
          >{{ patientDetails.employment_details.employee_number }}
        </p>
        <p>
          <b>Verified: </b>
          <span
            :class="$store.state.authUser.active ? 'make-green' : 'make-red'"
          >
            {{ $store.state.authUser.verified ? "Yes" : "No" }}
          </span>
        </p>
        <div style="width: 130px; margin-top: 20px">
          <base-button
            name="Edit"
            @click="togglePatientComponent('user_profile')"
          ></base-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "./reusable/BaseButton.vue";
import UserCircle from "./reusable/UserCircle.vue";
export default {
  components: { UserCircle, BaseButton },
  props: ["patientDetails", "togglePatientComponent"],
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.personal-info-header {
  border: 1px solid #dddddd;
  padding: 20px;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
}
.personal-info-header p {
  font-size: 14px;
  margin-left: 10px;
}
.personal-info-header .personal-company {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.personal-info-bottom p {
  font-size: 14px;
  margin: 5px 10px;
}
.make-green {
  color: green;
}
.personal-info-title {
  border: 1px solid #dddddd;
}
</style>