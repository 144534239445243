<template>
  <div>
    <p><b>SUBSCRIPTION PAYMENTS</b></p>
    <div>
      <table>
        <thead>
          <tr>
            <th>Paid amount</th>
            <th>Payment date</th>
            <th>Payment mode</th>
            <th>Payment month</th>
            <th>Reference number</th>
            <th>Proof</th>
          </tr>
        </thead>
        <tbody v-if="paymentsList.length && !isGettingPayments">
          <tr v-for="item in paymentsList" :key="item.id">
            <td>{{ formatMoney(item.paid_amount) }}</td>
            <td>{{ formatDate(item.payment_date) }}</td>
            <td>{{ item.payment_mode }}</td>
            <td>{{ item.payment_month }}</td>
            <td>{{ item.reference_number }}</td>
            <td>
              <a v-if="item.proof" target="_blank" :href="item.proof">
                <button>View proof</button>
              </a>
            </td>
          </tr>
        </tbody>
      </table>
      <div
        style="width: 100%; margin: 10px"
        class="g-flex g-align-center g-content-center"
        v-if="isGettingPayments"
      >
        <table-data-loader></table-data-loader>
      </div>
    </div>
  </div>
</template>

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import TableDataLoader from "./reusable/TableDataLoader.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import formatMoney from "@/mixins/formatMoney";

export default {
  components: { TableDataLoader },
  data() {
    return {
      paymentsList: [],
      isGettingPayments: false,
    };
  },
  props: ["subscToViewPayments"],
  mixins: [formatDateMixin, formatMoney],
  methods: {
    getSubscPaymentsHandler() {
      this.isGettingPayments = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/uhuru/subscriptions/${this.subscToViewPayments}/payments`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.paymentsList = response.data.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingPayments = false;
        });
    },
  },
  created() {
    this.getSubscPaymentsHandler();
  },
};
</script>

<style scoped>
</style>