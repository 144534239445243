<template>
  <div class="login-container">
    <div class="login-view">
      <div class="login-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="login-header">
        <h2>Log in</h2>
      </div>
      <label>Email</label>
      <input
        type="email"
        placeholder="Enter your email"
        required
        v-model="userEmail"
        @keyup.enter="loginHandler"
      />
      <p v-if="logginErrors.email && !userEmail" class="form-errors">
        Enter your email please
      </p>
      <label>Password</label>
      <input
        type="password"
        placeholder="Enter your Password"
        v-model="userPassword"
        @keyup.enter="loginHandler"
      />
      <p v-if="logginErrors.password && !userPassword" class="form-errors">
        Enter your password please
      </p>
      <div class="login-view-btn">
        <base-button
          name="Login"
          :onClick="loginHandler"
          :isLoading="isLoaggingIn"
        ></base-button>
      </div>
      <div class="login-view-bottom">
        <div class="login-view-bottom-one">
          <router-link to="/forget-password">Forgot password</router-link>
        </div>
      </div>
    </div>
    <!-- <div v-if="isToggleResendVerification">
      <warning-msg
        message="Your account is not verified, Please click 'Yes button' to resend verification to your email"
        :onClosingModal="toggleResendVerificationModal"
        :onClick="resendVerificationHandler"
        :isOnLoading="isResendingVerification"
      ></warning-msg>
    </div> -->
  </div>
</template>

<script>
import BaseButton from "@/components/reusable/BaseButton.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { mapState } from "vuex";

export default {
  components: { BaseButton, WarningMsg },
  computed: {
    ...mapState(["apiUrl"]),
  },
  data() {
    return {
      userEmail: "",
      userPassword: "",
      isLoaggingIn: false,
      logginErrors: {},
      isToggleResendVerification: false,
      isResendingVerification: false,
    };
  },
  methods: {
    validateLoginForm() {
      this.logginErrors = {};

      if (!this.userEmail) {
        this.logginErrors.email = true;
      }
      if (!this.userPassword) {
        this.logginErrors.password = true;
      }
      // Return true if all field is valid, otherwise is false
      return !this.logginErrors.email && !this.logginErrors.password;
    },

    loginHandler() {
      if (!this.validateLoginForm()) {
        return;
      }
      this.isLoaggingIn = true;
      axios
        .post(
          `${this.apiUrl}/v1/login?email=${this.userEmail}&password=${this.userPassword}`
        )
        .then((response) => {
          toast.success(
            "Request is done successfully, Use the code sent to your email to finish log in",
            {
              theme: "colored",
              clearOnUrlChange: false,
            }
          );

          sessionStorage.setItem("otpSentTo", this.userEmail);
          this.$router.push("/user-verification");
        })
        .catch((error) => {
          // if (error.response.data.is_verified === false) {
          //   this.isToggleResendVerification = true;
          // }
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isLoaggingIn = false;
        });
    },

    toggleResendVerificationModal() {
      this.isToggleResendVerification = false;
    },

    resendVerificationHandler() {
      this.isResendingVerification = true;
      axios
        .post(
          `${this.$store.state.apiUrl}/v1/account/verification?email=${this.userEmail}`
        )
        .then((reponse) => {
          toast.success(reponse.data.message, {
            theme: "colored",
          });
          this.toggleResendVerificationModal();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isResendingVerification = false;
        });
    },
  },
};
</script>

<style scoped>
.login-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.login-view {
  width: 300px;
  margin: auto;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  background: white;
}

.login-view-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.login-header h2 {
  text-align: center;
}

.login-view-img img {
  width: 120px;
  margin: 20px auto 0;
}

.login-view-btn {
  margin-top: 20px;
}

.login-view-bottom {
  margin: 20px 0;
}

.login-view-bottom a {
  color: black;
}

.login-view-bottom-one a:hover {
  color: #1e4b7d;
}

.login-view-bottom-one {
  margin-bottom: 10px;
}

.login-view-bottom-two a {
  text-decoration: none;
}

.login-view-bottom-two a span {
  text-decoration: underline;
  color: #5e8722;
}

input,
select {
  margin-bottom: 8px;
}
</style>