<template>
  <base-layout>
    <template #content>
      <div class="agents-header g-flex g-align-center g-space-btw">
        <h3>Call sessions with employees</h3>
        <div class="g-flex g-align-center">
          <label>From</label>
          <input
            type="date"
            v-model="rangeFrom"
            @input="searchByEveryThingHandler"
          />
          <label style="margin-left: 6px">To</label>
          <input
            type="date"
            v-model="rangeTo"
            @input="searchByEveryThingHandler"
          />
        </div>
        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchValue"
            @keyup.enter="searchByEveryThingHandler"
          />
          <button @click="searchByEveryThingHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>
      </div>
      <div style="padding: 0 10px">
        <table>
          <thead>
            <tr>
              <th>Employee name</th>
              <th>Employee number</th>
              <th>Company</th>
              <th>Agent name</th>
              <th>Agent email</th>
              <th>Call session date</th>
              <th>Call duration</th>
            </tr>
          </thead>
          <tbody v-if="callSessionsList.length && !isGettingCallSessions">
            <tr v-for="item in callSessionsList" :key="item.id">
              <td>{{ item.patient_name }}</td>
              <td>{{ item.employee_number }}</td>
              <td>{{ item.employeer }}</td>
              <td>{{ item.agent.name }}</td>
              <td>{{ item.agent.email }}</td>
              <td>{{ item.call_date }}</td>
              <td>{{ item.call_duration }}</td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingCallSessions && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getcallSessionsListHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingCallSessions"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>
    </template>
  </base-layout>
</template>
      
<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import SingleCallSession from "@/components/SingleCallSession.vue";
import CallSessionNotes from "@/components/CallSessionNotes.vue";
import formatDateMixin from "@/mixins/formatDateMixin";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    ActionsBtn,
    WarningMsg,
    Wysiwyg,
    SingleCallSession,
    CallSessionNotes,
  },
  data() {
    return {
      isGettingCallSessions: false,
      callSessionsList: [],

      rangeFrom: "",
      rangeTo: this.formatDate(new Date()),
      searchValue: "",
      isOnSearchingCalls: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin],
  methods: {
    searchByEveryThingHandler() {
      if (!this.rangeFrom && !this.searchValue) {
        return;
      }
      this.isOnSearchingCalls = true;
      this.getcallSessionsListHandler();
    },

    generateUrlHandler() {
      if (
        this.isOnSearchingCalls &&
        this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/call-sessions?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&search=${this.searchValue}`;
      }

      if (this.isOnSearchingCalls && this.searchValue && !this.rangeFrom) {
        return `${this.$store.state.apiUrl}/v1/uhuru/call-sessions?search=${this.searchValue}`;
      }

      if (
        this.isOnSearchingCalls &&
        !this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/uhuru/call-sessions?range_from=${this.rangeFrom}&range_until=${this.rangeTo}`;
      }

      if (!this.isOnSearchingCalls) {
        return `${this.$store.state.apiUrl}/v1/uhuru/call-sessions`;
      }
    },

    getcallSessionsListHandler(page) {
      this.isGettingCallSessions = true;

      let actionUrl = "";
      if (!this.isOnSearchingCalls) {
        actionUrl = this.generateUrlHandler() + `?page=${page}`;
      } else {
        actionUrl = this.generateUrlHandler() + `&page=${page}`;
      }

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.callSessionsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingCallSessions = false;
        });
    },
  },
  created() {
    this.getcallSessionsListHandler();
  },
};
</script>
      
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.agents-header {
  margin: 20px;
}
.agents-header input {
  margin-bottom: 0 !important;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-agents-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input {
  margin-bottom: 10px;
}
</style>