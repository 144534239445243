<template>
  <div class="fg-pwd-container">
    <div class="fg-pwd-view">
      <div class="fg-pwd-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="fg-pwd-header">
        <h2>Reset password</h2>
      </div>
      <label>Enter the code</label>
      <input
        type="text"
        placeholder="Enter the code sent to your email"
        required
        v-model="resetCode"
        @keyup.enter="forgetPwdHandler"
      />
      <p v-if="fgPasswordError.resetCode && !resetCode" class="form-errors">
        Enter the code sent to your email
      </p>
      <label>New password</label>
      <input
        type="password"
        placeholder="Enter your new password"
        required
        v-model="newPassword"
        @keyup.enter="forgetPwdHandler"
      />
      <p v-if="fgPasswordError.newPassword && !newPassword" class="form-errors">
        Enter your new passowrd
      </p>
      <label>Confirm new password</label>
      <input
        type="password"
        placeholder="Re-Enter your new password"
        required
        v-model="confirmNewPassword"
        @keyup.enter="forgetPwdHandler"
      />
      <p
        v-if="fgPasswordError.confirmNewPassword && !confirmNewPassword"
        class="form-errors"
      >
        Re-Enter your new passowrd
      </p>

      <div class="fg-pwd-view-btn">
        <base-button
          name="Reset Password"
          :onClick="forgetPwdHandler"
          :isLoading="isResetingPassword"
        ></base-button>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/reusable/BaseButton.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import { mapState } from "vuex";

export default {
  components: { BaseButton, WarningMsg },
  computed: {
    ...mapState(["apiUrl"]),
  },
  data() {
    return {
      resetCode: "",
      newPassword: "",
      confirmNewPassword: "",
      isResetingPassword: false,
      fgPasswordError: {},
    };
  },
  methods: {
    validateForm() {
      this.fgPasswordError = {};

      if (!this.resetCode) {
        this.fgPasswordError.resetCode = true;
      }
      if (!this.newPassword) {
        this.fgPasswordError.newPassword = true;
      }
      if (!this.confirmNewPassword) {
        this.fgPasswordError.confirmNewPassword = true;
      }

      // Return true if all field is valid, otherwise is false
      return (
        !this.fgPasswordError.resetCode &&
        !this.fgPasswordError.newPassword &&
        !this.fgPasswordError.confirmNewPassword
      );
    },
    forgetPwdHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isResetingPassword = true;
      axios
        .post(
          `${this.apiUrl}/v1/password/reset?code=${this.resetCode}&password=${this.newPassword}&password_confirmation=${this.confirmNewPassword}`
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$router.push("/");
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isResetingPassword = false;
        });
    },
  },
};
</script>

<style scoped>
.fg-pwd-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.fg-pwd-view {
  width: 300px;
  margin: auto;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  background: white;
}

.fg-pwd-view-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.fg-pwd-header h2 {
  text-align: center;
}

.fg-pwd-view-img img {
  width: 120px;
  margin: 20px auto 0;
}

.fg-pwd-view-btn {
  margin: 20px 0 30px;
}

input,
select {
  margin-bottom: 8px;
}
</style>