<template>
  <div class="emp-analytics-cont">
    <main-header></main-header>
    <div class="emp-analytics-body">
      <div class="uh-analy-cards-cont">
        <div class="single-analy-card">
          <div class="analytic-card-left">
            <i class="fa-solid fa-phone"></i>
          </div>
          <div class="analytic-card-right">
            <p>{{ totalCallSessions }}</p>
            <div>
              <label>Total call sessions</label>
            </div>
          </div>
        </div>
        <div class="single-analy-card">
          <div class="analytic-card-left">
            <i class="fa-solid fa-clock"></i>
          </div>
          <div class="analytic-card-right">
            <p>{{ totalCallDurations }}</p>
            <div>
              <label>Total call durations</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Pie chart -->
      <div class="g-flex" style="width: 100%; margin: 30px 0">
        <div style="width: 50%" v-if="moodAnalytics.todays.length">
          <p><label>TODAY'S MOOD</label></p>
          <apexchart
            type="pie"
            width="400"
            :options="chartOptionsThree"
            :series="seriesThree"
          ></apexchart>
        </div>
        <div style="width: 50%" v-if="moodAnalytics.yesterdays.length">
          <p><label>YESTERDAY'S MOOD</label></p>
          <apexchart
            type="pie"
            width="400"
            :options="chartOptionsFour"
            :series="seriesFour"
          ></apexchart>
        </div>
      </div>

      <div class="g-flex" style="width: 100%; margin: 30px 0">
        <div style="width: 50%">
          <p><label>CALL SESSIONS</label></p>
          <apexchart
            type="area"
            height="350"
            :options="chartOptionsOne"
            :series="seriesOne"
          ></apexchart>
        </div>
        <div style="width: 50%">
          <p><label>CALL DURATIONS</label></p>
          <apexchart
            type="area"
            height="350"
            :options="chartOptionsTwo"
            :series="seriesTwo"
          ></apexchart>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import MainHeader from "@/components/MainHeader.vue";
export default {
  components: { MainHeader },
  data() {
    return {
      isGettingAnalytics: false,
      analyticsAllData: {
        total: [],
        callDuration: [],
        callDates: [],
      },

      moodAnalytics: {
        yesterdays: [],
        todays: [],
      },

      totalCallSessions: 0,
      totalCallDurations: 0,

      chartOptionsOne: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#0f676c"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesOne: [
        {
          name: "call sessions",
          data: [],
        },
      ],

      chartOptionsTwo: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#464646"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesTwo: [
        {
          name: "call duration",
          data: [],
        },
      ],

      seriesThree: [],
      chartOptionsThree: {
        chart: {
          width: 700,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },

      seriesFour: [],
      chartOptionsFour: {
        chart: {
          width: 700,
          type: "pie",
        },
        labels: [],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  methods: {
    assignAnalyticsData(data) {
      if (data.call_sessions.length) {
        data.call_sessions.map((item) => {
          this.totalCallSessions =
            this.totalCallSessions + parseInt(item.total);
          this.totalCallDurations =
            this.totalCallDurations + parseInt(item.call_duration);
          this.analyticsAllData.total.push(item.total);
          this.analyticsAllData.callDuration.push(item.call_duration);
          this.analyticsAllData.callDates.push(item.month);

          this.seriesOne = [
            {
              name: "call sessions",
              data: this.analyticsAllData.total,
            },
          ];
          this.chartOptionsOne = {
            xaxis: {
              //   type: "datetime",
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
            // tooltip: {
            //   x: {
            //     format: "dd/MMM/yyyy",
            //   },
            // },
          };

          this.seriesTwo = [
            {
              name: "call durations",
              data: this.analyticsAllData.callDuration,
            },
          ];
          this.chartOptionsTwo = {
            xaxis: {
              //   type: "datetime",
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
            // tooltip: {
            //   x: {
            //     format: "dd/MMM/yyyy",
            //   },
            // },
          };
        });
      }

      if (data.mood_comparison.todays.length) {
        this.moodAnalytics.todays = data.mood_comparison.todays;
        this.chartOptionsThree = {
          labels: [
            "mood",
            "energy level",
            "exercise",
            "meditation",
            "sleeping duration",
          ],
        };
        this.seriesThree = [
          parseFloat(data.mood_comparison.todays[0]["mood"]),
          parseFloat(data.mood_comparison.todays[0]["energy_level"]),
          parseFloat(data.mood_comparison.todays[0]["exercise"]),
          parseFloat(data.mood_comparison.todays[0]["meditation"]),
          parseFloat(data.mood_comparison.todays[0]["sleep_duration"]),
        ];
      }
      if (data.mood_comparison.yesterdays.length) {
        this.moodAnalytics.yesterdays = data.mood_comparison.yesterdays;
        this.chartOptionsFour = {
          labels: [
            "mood",
            "energy level",
            "exercise",
            "meditation",
            "sleeping duration",
          ],
        };
        this.seriesFour = [
          parseFloat(data.mood_comparison.yesterdays[0]["mood"]),
          parseFloat(data.mood_comparison.yesterdays[0]["energy_level"]),
          parseFloat(data.mood_comparison.yesterdays[0]["exercise"]),
          parseFloat(data.mood_comparison.yesterdays[0]["meditation"]),
          parseFloat(data.mood_comparison.yesterdays[0]["sleep_duration"]),
        ];
      }
    },
    getEmployeeAnalytics() {
      this.isGettingAnalytics = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/analytics`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.assignAnalyticsData(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingAnalytics = false;
        });
    },
  },

  created() {
    this.getEmployeeAnalytics();
  },
};
</script>

<style scoped>
.emp-analytics-body {
  width: 90%;
  background: white;
  padding: 20px;
  margin: 40px auto 0;
}
</style>
