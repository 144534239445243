<template>
  <base-layout>
    <template #content>
      <div class="ag-comp-emp">
        <div>
          <div class="g-flex g-align-center g-space-btw">
            <h3 style="margin: 0 0 10px" v-if="employeesList.length">
              {{ employeesList[0].loginDetails.company }} Employees
            </h3>
            <div
              style="width: 40px; margin-bottom: 10px"
              v-if="!isGettingEmployees"
            >
              <button class="g-back-btn" @click="goBackHandler">
                <i class="fa-solid fa-arrow-left"></i>
              </button>
            </div>
          </div>
          <table>
            <thead>
              <tr>
                <th>Name</th>
                <th>Employee number</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Assigned</th>
                <th>Active</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody v-if="employeesList.length && !isGettingEmployees">
              <tr v-for="(item, index) in employeesList" :key="item.id">
                <td class="g-flex g-align-center">
                  <user-circle
                    :currentIndex="index"
                    :currentUser="item.name"
                    marginRight="10px"
                  ></user-circle
                  >{{ item.name }}
                </td>
                <td>{{ item.employee_number }}</td>
                <td>{{ item.loginDetails.email }}</td>
                <td>{{ item.phone }}</td>
                <td :class="item.assigned ? 'green-status' : 'red-status'">
                  {{ item.assigned ? "Yes" : "No" }}
                </td>
                <td
                  :class="
                    item.loginDetails.active ? 'green-status' : 'red-status'
                  "
                >
                  {{ item.loginDetails.active ? "Yes" : "No" }}
                </td>
                <td>
                  <actions-btn>
                    <template #content>
                      <router-link
                        :to="'/agent-view-employee-details/' + item.id"
                      >
                        <button class="action-btn-item">Details</button>
                      </router-link>
                      <button
                        class="action-btn-item"
                        @click="toggleAssignHandler(item.id)"
                      >
                        Assign
                      </button>
                      <button
                        class="action-btn-item"
                        @click="toggleUnassignPatientHandler(item)"
                      >
                        Unassign
                      </button>
                      <button
                        class="action-btn-item"
                        @click="toggleAddNewCallSessionModal(item.id)"
                      >
                        Call Session
                      </button>
                    </template>
                  </actions-btn>
                </td>
              </tr>
            </tbody>
          </table>

          <div
            class="uhuru-pg-container"
            v-if="!isGettingEmployees && hasNextPage"
          >
            <vue-awesome-paginate
              :total-items="totalPgItems"
              :items-per-page="10"
              :max-pages-shown="8"
              v-model="currentPage"
              paginate-buttons-class="pg-btn"
              active-page-class="pg-btn-active"
              back-button-class="pg-back-btn"
              next-button-class="pg-next-btn"
              prev-button-content="Prev"
              next-button-content="Next"
              :on-click="getCompanyEmployees"
            />
          </div>

          <div
            style="width: 100%; margin: 10px"
            class="g-flex g-align-center g-content-center"
            v-if="isGettingEmployees"
          >
            <table-data-loader></table-data-loader>
          </div>
        </div>

        <div v-if="isToggleAssignment">
          <modal :closeModal="toggleAssignHandler" width="400px">
            <template #content>
              <div>
                <h3 style="margin: 20px 0">Assign Patient to Psychiatrist</h3>
              </div>
              <div class="assign-ts-form">
                <label>Select Doctor</label>
                <div style="margin: 10px 0">
                  <multiselect
                    v-model="therapistToAssign"
                    :options="therapistsList"
                    track-by="id"
                    label="name"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="true"
                    :multiple="false"
                    :loading="isGettingTherapists"
                    placeholder="Type to search and select psychiatrist"
                    @search-change="getTherapistsListHandler"
                  >
                  </multiselect>
                </div>
              </div>
              <div style="margin-top: 20px">
                <base-button
                  name="Assign"
                  :isLoading="isAssigningToDoctor"
                  :onClick="assignPatientToDoctorHandler"
                ></base-button>
              </div>
            </template>
          </modal>
        </div>

        <div v-if="isToggleAddNewCallSession">
          <modal :closeModal="toggleAddNewCallSessionModal" width="580px">
            <template #content>
              <div>
                <h3 style="margin: 20px 0">Add New Call Session</h3>
              </div>
              <div>
                <div class="g-flex">
                  <div>
                    <label>Start Time</label>
                    <input type="time" v-model="start_at" />
                    <p
                      v-if="formErrors.start_at && !start_at"
                      class="form-errors"
                    >
                      Start time of call session is required
                    </p>
                  </div>
                  <div style="margin: 0 10px">
                    <label>End Time</label>
                    <input type="time" v-model="end_at" />
                    <p v-if="formErrors.end_at && !end_at" class="form-errors">
                      End time of call session is required
                    </p>
                  </div>
                  <div>
                    <label>Call Session Date</label>
                    <input type="date" v-model="call_date" />
                    <p
                      v-if="formErrors.call_date && !call_date"
                      class="form-errors"
                    >
                      End time of call session is required
                    </p>
                  </div>
                </div>

                <label>Enter Key point</label>
                <div style="height: 200px">
                  <wysiwyg v-model="key_point"></wysiwyg>
                </div>
                <p
                  v-if="formErrors.key_point && !key_point"
                  class="form-errors"
                >
                  Key point of a call session is a required
                </p>

                <label>Enter Summary</label>
                <div style="height: 200px">
                  <wysiwyg v-model="summary"></wysiwyg>
                </div>
                <p v-if="formErrors.summary && !summary" class="form-errors">
                  Summary of a call session is required
                </p>

                <label>Call Overview</label>
                <div style="height: 200px">
                  <wysiwyg v-model="overview"></wysiwyg>
                </div>
                <p v-if="formErrors.overview && !overview" class="form-errors">
                  Overview of a call session is required
                </p>
              </div>
              <div style="margin: 20px 0">
                <base-button
                  name="Submit Call Session"
                  :isLoading="isMakingChangeOnCallSession"
                  :onClick="addNewCallSessionHandler"
                ></base-button>
              </div>
            </template>
          </modal>
        </div>

        <div v-if="isTogglePatientDetails">
          <modal :closeModal="toggleUnassignPatientHandler" width="650px">
            <template #content>
              <agent-unassign-employee
                v-if="selectedPatientToView"
                :selectedPatientToView="selectedPatientToView"
                :removeUnassignedObject="removeUnassignedObject"
              ></agent-unassign-employee>
            </template>
          </modal>
        </div>
      </div>
    </template>
  </base-layout>
</template>

    
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import TableDataLoader from "../components/reusable/TableDataLoader.vue";
import BaseButton from "../components/reusable/BaseButton.vue";
import Modal from "../components/reusable/Modal.vue";
import ActionsBtn from "../components/reusable/ActionsBtn.vue";
import WarningMsg from "../components/reusable/WarningMsg.vue";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import AgentUnassignEmployee from "@/components/AgentUnassignEmployee.vue";
import Multiselect from "vue-multiselect";
import goBackHandler from "@/mixins/goBackHandler";

export default {
  components: {
    TableDataLoader,
    BaseButton,
    Modal,
    ActionsBtn,
    WarningMsg,
    BaseLayout,
    UserCircle,
    Wysiwyg,
    AgentUnassignEmployee,
    Multiselect,
  },
  data() {
    return {
      isGettingEmployees: false,
      employeesList: [],
      therapistsList: [],

      employeeToAssign: "",
      therapistToAssign: "",
      isToggleAssignment: false,
      isAssigningToDoctor: false,
      isUnassigningPatient: false,
      isToggleUnassignWarning: false,

      isTogglePatientDetails: false,
      selectedPatientToView: null,
      isGettingTherapists: false,

      selectedEmployeeForCall: "",
      isToggleAddNewCallSession: false,
      formErrors: {},
      key_point: "",
      overview: "",
      summary: "",
      start_at: "",
      end_at: "",
      call_date: "",
      isMakingChangeOnCallSession: false,

      clientId: this.$route.params.id,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  computed: {},
  mixins: [goBackHandler],
  methods: {
    formattedTime(val) {
      const [hours, minutes] = val.split(":");

      let formattedHours = parseInt(hours, 10) % 12 || 12;
      const amOrPm = parseInt(hours, 10) >= 12 ? "PM" : "AM";

      formattedHours = String(formattedHours).padStart(2, "0");

      const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
      return formattedTime;
    },

    toggleAddNewCallSessionModal(id) {
      this.selectedEmployeeForCall = id;
      this.key_point = "";
      this.summary = "";
      this.overview = "";
      this.start_at = "";
      this.end_at = "";
      this.call_date = "";
      this.isToggleAddNewCallSession = !this.isToggleAddNewCallSession;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.key_point) {
        this.formErrors.key_point = true;
      }
      if (!this.summary) {
        this.formErrors.summary = true;
      }
      if (!this.overview) {
        this.formErrors.overview = true;
      }
      if (!this.start_at) {
        this.formErrors.start_at = true;
      }
      if (!this.end_at) {
        this.formErrors.end_at = true;
      }
      if (!this.call_date) {
        this.formErrors.call_date = true;
      }

      return (
        !this.formErrors.key_point &&
        !this.formErrors.summary &&
        !this.formErrors.overview &&
        !this.formErrors.start_at &&
        !this.formErrors.end_at &&
        !this.formErrors.call_date
      );
    },

    addNewCallSessionHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnCallSession = true;

      let data = {
        key_point: this.key_point,
        summary: this.summary,
        overview: this.overview,
        start_at: this.formattedTime(this.start_at),
        end_at: this.formattedTime(this.end_at),
        call_date: this.call_date,
      };

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/agents/call-sessions/${this.selectedEmployeeForCall}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.toggleAddNewCallSessionModal();
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnCallSession = false;
        });
    },

    toggleUnassignPatientHandler(item) {
      this.selectedPatientToView = item;
      this.isTogglePatientDetails = !this.isTogglePatientDetails;
    },

    removeUnassignedObject(p_id, e_id) {
      const empIndex = this.employeesList.findIndex((obj) => obj.id === e_id);

      this.selectedPatientToView.Psychiatrists =
        this.selectedPatientToView.Psychiatrists.filter(
          (item) => item.id !== p_id
        );
      // this.employeesList.Psychiatrists = this.employeesList[
      //   empIndex
      // ].Psychiatrists.filter((item) => item.id !== p_id);
      this.getCompanyEmployees();
    },

    getCompanyEmployees(page) {
      this.isGettingEmployees = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/agents/clients/${this.clientId}/employees?page=${page}`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.employeesList = response.data.data.data.map((item) => {
            return { ...item, checked: false };
          });
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingEmployees = false;
        });
    },

    toggleAssignHandler(item) {
      this.employeeToAssign = item;
      this.isToggleAssignment = !this.isToggleAssignment;
    },

    assignPatientToDoctorHandler() {
      if (!this.therapistToAssign) {
        return toast.error("Select a doctor please", {
          theme: "colored",
        });
      }

      this.isAssigningToDoctor = true;

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/agents/employees/${this.employeeToAssign}/assign?therapist=${this.therapistToAssign.id}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.employeesList = this.employeesList.map((item) => {
            if (item.id == response.data.data.id) {
              return response.data.data;
            }
            return item;
          });
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isAssigningToDoctor = false;
          this.isToggleAssignment = !this.isToggleAssignment;
        });
    },

    getTherapistsListHandler(query) {
      if (query) {
        this.isGettingTherapists = true;
      }

      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
      }

      this.delayTimer = setTimeout(() => {
        axios
          .get(
            `${this.$store.state.apiUrl}/v1/agents/therapists?search=${query}`,
            {
              headers: {
                Authorization: `Bearer ${this.$store.state.authUser.token}`,
              },
            }
          )
          .then((response) => {
            this.therapistsList = response.data.data.data.map((item) => ({
              name: item.login_details.name,
              id: item.id,
            }));
          })
          .catch((error) => {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          })
          .finally(() => {
            this.isGettingTherapists = false;
          });
      }, 1000);
    },
  },
  created() {
    this.getCompanyEmployees();
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.css"></style>
<style scoped>
.ag-comp-emp {
  padding: 20px;
}
.spanning--1 {
  font-size: 15px;
}
.assign-ts-form input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
  width: 220px;
}
input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
.assign-therapist {
  background-color: var(--secondary-color);
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
input {
  margin-bottom: 10px;
}
</style>