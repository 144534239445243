<template>
  <div class="register-container">
    <div class="register-view">
      <div class="register-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="register-header">
        <h2>Create an account</h2>
      </div>
      <label>Full name</label>
      <input type="text" placeholder="Enter your full name" v-model="name" />
      <p v-if="registerErrors.name && !name" class="form-errors">
        Enter your full name
      </p>
      <label>Email</label>
      <input type="email" placeholder="Enter your email" v-model="email" />
      <p v-if="registerErrors.email && !email" class="form-errors">
        Enter your email please
      </p>
      <label>Type</label>
      <select v-model="type">
        <option value="">---- Select type ----</option>
        <option value="Individual">Individual</option>
        <option value="Company">Company/Organization</option>
      </select>
      <p v-if="registerErrors.type && !type" class="form-errors">
        Select the type
      </p>
      <label>Password</label>
      <input
        type="password"
        placeholder="Enter your password"
        v-model="password"
      />
      <p v-if="registerErrors.password && !password" class="form-errors">
        Enter the password
      </p>
      <label>Confirm Password</label>
      <input
        type="password"
        placeholder="Re-enter your password"
        v-model="confirmPassword"
      />
      <p
        v-if="registerErrors.confirmPassword && !confirmPassword"
        class="form-errors"
      >
        Re-enter the password
      </p>
      <div class="register-view-btn">
        <base-button
          name="Register"
          :onClick="registerHandler"
          :isLoading="isRegistering"
        ></base-button>
      </div>
      <div class="register-view-bottom">
        <div class="register-view-bottom-two">
          <router-link to="/">Have an account? <span>Login</span> </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import BaseButton from "@/components/reusable/BaseButton.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: { BaseButton },
  data() {
    return {
      name: "",
      email: "",
      type: "",
      password: "",
      confirmPassword: "",
      isRegistering: false,
      registerErrors: {},
    };
  },
  methods: {
    validateSignUpForm() {
      this.registerErrors = {};

      if (!this.name) {
        this.registerErrors.name = true;
      }
      if (!this.email) {
        this.registerErrors.email = true;
      }
      if (!this.password) {
        this.registerErrors.password = true;
      }
      if (!this.type) {
        this.registerErrors.type = true;
      }
      if (!this.confirmPassword) {
        this.registerErrors.confirmPassword = true;
      }
      // Return true if all field is valid, otherwise is false
      return (
        !this.registerErrors.email &&
        !this.registerErrors.password &&
        !this.registerErrors.name &&
        !this.registerErrors.type &&
        !this.registerErrors.confirmPassword
      );
    },
    registerHandler() {
      if (!this.validateSignUpForm()) {
        return;
      }

      if (this.password !== this.confirmPassword) {
        return toast.error("Passwords should match", {
          theme: "colored",
        });
      }

      this.isRegistering = true;

      let data = {
        name: this.name,
        email: this.email,
        type: this.type,
        password: this.password,
        confirmPassword: this.confirmPassword,
      };

      sessionStorage.setItem("registerUser", JSON.stringify(data));

      toast.success(
        "Request is done successfully, please proceed with one more step",
        {
          theme: "colored",
          clearOnUrlChange: false,
        }
      );

      this.isRegistering = false;
      this.$router.push("/clients-info");
    },
  },
};
</script>

<style scoped>
.register-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.register-view {
  width: 350px;
  margin: auto;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  background: white;
}

.register-view-img {
  display: flex;
  align-items: center;
  justify-content: center;
}

.register-header h2 {
  text-align: center;
}

.register-view-img img {
  width: 120px;
  margin: 20px auto 0;
}

.register-view-btn {
  margin-top: 20px;
}

.register-view-bottom {
  margin: 20px 0;
}

.register-view-bottom a {
  color: black;
}

.register-view-bottom-one a:hover {
  color: #1e4b7d;
}

.register-view-bottom-one {
  margin-bottom: 10px;
}

.register-view-bottom-two a {
  text-decoration: none;
}

.register-view-bottom-two a span {
  text-decoration: underline;
  color: #5e8722;
}

input,
select {
  margin-bottom: 8px;
}
</style>