<template>
  <div>
    <p><b>VIEW CONSULTATION</b></p>

    <p class="margin--1">
      <b>Patient name: </b>{{ selectedConsultation.employee.name }}
    </p>
    <p>
      <b>Company: </b>{{ selectedConsultation.employee.loginDetails.company }}
    </p>
    <p>
      <b>Employee number: </b
      >{{ selectedConsultation.employee.employee_number }}
    </p>
    <p class="margin--1"><b>ABOUT CONSULTATION</b></p>
    <p class="bordering--1">
      <b class="underline--1">Current problem: </b>
      <span v-html="selectedConsultation.current_problem"></span>
    </p>
    <p class="bordering--1">
      <b class="underline--1">Examination of mental state: </b>
      <span v-html="selectedConsultation.examination_of_mental_state"></span>
    </p>
    <p class="bordering--1">
      <b class="underline--1">Treatment plan: </b>
      <span v-html="selectedConsultation.treatment_plan"></span>
    </p>
  </div>
</template>

<script>
export default {
  props: ["selectedConsultation"],
};
</script>

<style scoped>
.bordering--1 {
  border: 1px solid #dddddd;
  padding: 10px;
}
.underline--1 {
  text-decoration: underline;
}
p {
  margin: 4px 0;
}
.margin--1 {
  margin-top: 20px;
}
</style>