<template>
  <div>
    <p><b>Name: </b>{{ singlePatientDetails.name }}</p>
    <p><b>Employee number: </b>{{ singlePatientDetails.employee_number }}</p>
    <p><b>Phone: </b>{{ singlePatientDetails.phone }}</p>
    <p><b>Email: </b>{{ singlePatientDetails.email }}</p>
    <p><b>Company: </b>{{ singlePatientDetails.company }}</p>
    <div v-if="singlePatientDetails.patient_form">
      <p><b>Gender: </b>{{ singlePatientDetails.patient_form.gender }}</p>
      <p>
        <b>Date of Birth: </b
        >{{ singlePatientDetails.patient_form.date_of_birth }}
      </p>
      <p><b>National ID No.: </b>{{ singlePatientDetails.patient_form.nid }}</p>
      <p>
        <b>Marital status: </b
        >{{ singlePatientDetails.patient_form.marital_status }}
      </p>
      <p><b>Address: </b>{{ singlePatientDetails.patient_form.address }}</p>
      <p><b>Insurance: </b>{{ singlePatientDetails.patient_form.insurance }}</p>
      <p><b>Education: </b>{{ singlePatientDetails.patient_form.education }}</p>
      <p>
        <b>Father's name: </b
        >{{ singlePatientDetails.patient_form.fathers_name }}
      </p>
      <p>
        <b>Mother's name: </b
        >{{ singlePatientDetails.patient_form.mothers_name }}
      </p>
      <p><b>Church: </b>{{ singlePatientDetails.patient_form.church }}</p>
      <p>
        <b>Place of birth: </b
        >{{ singlePatientDetails.patient_form.place_of_birth }}
      </p>
      <p><b>Title: </b>{{ singlePatientDetails.patient_form.title }}</p>
      <p>
        <b>Professional in: </b
        >{{ singlePatientDetails.patient_form.profession }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: ["singlePatientDetails"],
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
p {
  font-size: 15px;
  margin: 4px 0;
  font-style: italic;
}
b {
  font-weight: 500;
  font-style: normal !important;
}
</style>