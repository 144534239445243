<template>
  <div class="all-packages">
    <div class="package-card">
      <div class="package-header">
        <h3>BASIC</h3>
        <div class="g-flex g-align-center">
          <span>Rwf</span>
          <h2>20,000</h2>
        </div>
      </div>
      <div class="package-services">
        <h4>Included Serves</h4>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Patient records</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>consultation</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Psychotherapy/Counseling</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Assessment and Diagnosis</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Planning and Intervention</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Behavioral Interventions</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Family Counseling</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Group Therapy</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Psychoeducation</p>
        </div>
      </div>

      <div class="g-flex g-align-center g-content-center padding-1">
        <button @click="subscribePackageHandler">BUY NOW</button>
      </div>
    </div>
    <div class="package-card" style="background-color: green">
      <div class="package-header">
        <h3>Standard</h3>
        <div class="g-flex g-align-center">
          <span>Rwf</span>
          <h2>80,000</h2>
        </div>
      </div>
      <div class="package-services">
        <h4>Included Serves</h4>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Patient records</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>consultation</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Psychotherapy/Counseling</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Assessment and Diagnosis</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Planning and Intervention</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Behavioral Interventions</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Family Counseling</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Group Therapy</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Psychoeducation</p>
        </div>
      </div>

      <div class="g-flex g-align-center g-content-center padding-1">
        <button @click="subscribePackageHandler">BUY NOW</button>
      </div>
    </div>
    <div class="package-card">
      <div class="package-header">
        <h3>Bussines</h3>
        <div class="g-flex g-align-center">
          <span>Rwf</span>
          <h2>100,000</h2>
        </div>
      </div>
      <div class="package-services">
        <h4>Included Serves</h4>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Patient records</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>consultation</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Psychotherapy/Counseling</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Assessment and Diagnosis</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Planning and Intervention</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Behavioral Interventions</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Family Counseling</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Group Therapy</p>
        </div>
        <div>
          <i class="fa-solid fa-circle-check"></i>
          <p>Psychoeducation</p>
        </div>
      </div>

      <div class="g-flex g-align-center g-content-center padding-1">
        <button @click="subscribePackageHandler">BUY NOW</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    subscribePackageHandler() {
      this.$router.push("/checkout");
    },
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.all-packages {
  width: 80%;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.package-card {
  background: var(--primary-color);
  width: 300px;
  position: relative;
  border-radius: 10px;
  margin: 100px 10px 20px;
}
.package-header {
  position: absolute;
  width: 200px;
  height: 100px;
  background: white;
  left: calc(50% - 100px);
  top: -50px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.75);
  display: flex;
  flex-direction: column;
  align-items: center;
}
.package-header h3 {
  font-size: 14px;
  padding-top: 14px;
  font-weight: 500;
  text-transform: uppercase;
}
.package-header div {
  margin-top: 10px;
}
.package-header div span {
  margin-right: 6px;
  font-weight: 500;
}
.package-services {
  padding-top: 80px;
  color: white;
}
.package-services h4 {
  text-transform: uppercase;
  text-align: center;
}
.package-services div {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}
.package-services div p {
  margin-left: 10px;
}
.padding-1 {
  padding: 20px 0 40px;
}
button {
  background: white;
  color: black;
  border: none;
  padding: 10px 30px;
  border-radius: 4px;
  font-size: 16px;
  width: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
button:hover {
  color: #193f6b;
  cursor: pointer;
}
</style>