<template>
  <div class="patient-p-form">
    <h4 class="heading--1">
      {{ patientDetails ? "EDIT " : "FILL " }}YOUR PROFILE
    </h4>
    <div class="">
      <div class="g-flex width--1">
        <div class="width--2" style="margin-right: 10px">
          <label>Name</label>
          <input
            type="text"
            placeholder=""
            :value="$store.state.authUser.name"
            readonly
          />

          <label>Date of Birth</label>
          <input type="date" placeholder="" v-model="date_of_birth" />
          <p
            v-if="formErros.date_of_birth && !date_of_birth"
            class="form-errors"
          >
            Enter your date of birth
          </p>

          <label>Current Address</label>
          <input
            type="text"
            placeholder="EX: Gasabo-Remera-Gishushu"
            v-model="address"
          />
          <p v-if="formErros.address && !address" class="form-errors">
            Enter your date of birth
          </p>

          <div class="g-flex width--1">
            <div class="width--2" style="margin-right: 5px">
              <label>Mother's Name</label>
              <input
                type="text"
                placeholder="Enter your mother's name"
                v-model="mothers_name"
              />
              <p
                v-if="formErros.mothers_name && !mothers_name"
                class="form-errors"
              >
                A place where you were born is required
              </p>
            </div>
            <div class="width--2" style="margin-left: 5px">
              <label>Father's Name</label>
              <input
                type="text"
                placeholder="Enter your mother's name"
                v-model="fathers_name"
              />
              <p
                v-if="formErros.fathers_name && !fathers_name"
                class="form-errors"
              >
                A place where you were born is required
              </p>
            </div>
          </div>

          <div class="g-flex width--1">
            <div class="width--2" style="margin-right: 5px">
              <label>Title/Post</label>
              <input
                type="text"
                placeholder="Enter your title/post"
                v-model="title"
              />
              <p v-if="formErros.title && !title" class="form-errors">
                Your title is required
              </p>
            </div>
            <div class="width--2" style="margin-left: 5px">
              <label>Your Professional</label>
              <input
                type="text"
                placeholder="Enter your professional"
                v-model="profession"
              />
              <p v-if="formErros.profession && !profession" class="form-errors">
                Your professional is required
              </p>
            </div>
          </div>
        </div>

        <div class="width--2" style="margin-left: 10px">
          <div class="g-flex width--1">
            <div class="width--2" style="margin-right: 5px">
              <label>Select Gender</label>
              <select v-model="gender">
                <option value="">-----Select your gender-----</option>
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <p v-if="formErros.gender && !gender" class="form-errors">
                Select your gender please
              </p>
            </div>
            <div class="width--2" style="margin-left: 5px">
              <label>Marital Status</label>
              <select v-model="marital_status">
                <option value="">-----Select marital status-----</option>
                <option value="Single">Single</option>
                <option value="Married">Married</option>
                <option value="Divorced">Divorced</option>
              </select>
              <p
                v-if="formErros.marital_status && !marital_status"
                class="form-errors"
              >
                Select your status please
              </p>
            </div>
          </div>

          <div class="g-flex width--1">
            <div class="width--2" style="margin-right: 5px">
              <label>National ID No.</label>
              <input
                type="text"
                placeholder="Enter your ID number"
                v-model="nid"
              />
              <p v-if="formErros.nid && !nid" class="form-errors">
                Enter your national ID number
              </p>
            </div>
            <div class="width--2" style="margin-left: 5px">
              <label>Place of Birth</label>
              <input
                type="text"
                placeholder="A place where you were born"
                v-model="place_of_birth"
              />
              <p
                v-if="formErros.place_of_birth && !place_of_birth"
                class="form-errors"
              >
                A place where you were born is required
              </p>
            </div>
          </div>

          <div class="g-flex width--1">
            <div class="width--2" style="margin-right: 5px">
              <label>Phone Number</label>
              <input
                type="text"
                placeholder="Enter your phone number"
                v-model="phone"
              />
              <p v-if="formErros.phone && !phone" class="form-errors">
                Your phone number is required
              </p>
            </div>
            <div class="width--2" style="margin-left: 5px">
              <label>Insurance</label>
              <select v-model="insurance">
                <option value="">-----Select insurance-----</option>
                <option
                  v-for="item in insurancesList"
                  :key="item"
                  :value="item"
                >
                  {{ item }}
                </option>
              </select>
              <p v-if="formErros.insurance && !insurance" class="form-errors">
                An insurance you have is required
              </p>
            </div>
          </div>

          <div class="g-flex width--1">
            <div class="width--2" style="margin-right: 5px">
              <label>Your Edication</label>
              <input
                type="text"
                placeholder="Enter your education"
                v-model="education"
              />
              <p v-if="formErros.education && !education" class="form-errors">
                Your educational is required
              </p>
            </div>
            <div class="width--2" style="margin-left: 5px">
              <label>Your Religion</label>
              <input
                type="text"
                placeholder="Enter your education"
                v-model="church"
              />
              <p v-if="formErros.church && !church" class="form-errors">
                Your religion is required
              </p>
            </div>
          </div>
        </div>
      </div>
      <div style="width: 200px; margin-top: 10px">
        <base-button
          :name="btnDisplayMessage"
          :isLoading="isAddingPatientForm"
          :onClick="addNewPatientHandler"
        ></base-button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import BaseButton from "./reusable/BaseButton.vue";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: { BaseButton },
  data() {
    return {
      date_of_birth: "",
      gender: "",
      nid: "",
      address: "",
      marital_status: "",
      place_of_birth: "",
      phone: "",
      mothers_name: "",
      fathers_name: "",
      insurance: "",
      title: "",
      profession: "",
      education: "",
      church: "",
      formErros: {},
      isAddingPatientForm: false,
      insurancesList: [],
    };
  },
  computed: {
    btnDisplayMessage() {
      return this.patientDetails ? "Edit Profile" : "Save Profile";
    },
  },
  props: ["getPatientDetailsHandler", "patientDetails"],
  methods: {
    validateForm() {
      this.formErros = {};

      if (!this.date_of_birth) {
        this.formErros.date_of_birth = true;
      }
      if (!this.gender) {
        this.formErros.gender = true;
      }
      if (!this.nid) {
        this.formErros.nid = true;
      }
      if (!this.address) {
        this.formErros.address = true;
      }
      if (!this.marital_status) {
        this.formErros.marital_status = true;
      }
      if (!this.place_of_birth) {
        this.formErros.place_of_birth = true;
      }
      if (!this.phone) {
        this.formErros.phone = true;
      }
      if (!this.mothers_name) {
        this.formErros.mothers_name = true;
      }
      if (!this.fathers_name) {
        this.formErros.fathers_name = true;
      }
      if (!this.insurance) {
        this.formErros.insurance = true;
      }
      if (!this.title) {
        this.formErros.title = true;
      }
      if (!this.profession) {
        this.formErros.profession = true;
      }
      if (!this.education) {
        this.formErros.education = true;
      }
      if (!this.church) {
        this.formErros.church = true;
      }

      return (
        !this.formErros.date_of_birth &&
        !this.formErros.gender &&
        !this.formErros.nid &&
        !this.formErros.address &&
        !this.formErros.marital_status &&
        !this.formErros.place_of_birth &&
        !this.formErros.phone &&
        !this.formErros.mothers_name &&
        !this.formErros.fathers_name &&
        !this.formErros.insurance &&
        !this.formErros.title &&
        !this.formErros.profession &&
        !this.formErros.education &&
        !this.formErros.church
      );
    },
    clearPatientForm() {
      this.date_of_birth = "";
      this.gender = "";
      this.nid = "";
      this.address = "";
      this.marital_status = "";
      this.place_of_birth = "";
      this.phone = "";
      this.mothers_name = "";
      this.fathers_name = "";
      this.insurance = "";
      this.title = "";
      this.profession = "";
      this.education = "";
      this.church = "";
    },

    addNewPatientHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isAddingPatientForm = true;

      let data = {
        date_of_birth: this.date_of_birth,
        gender: this.gender,
        nid: this.nid,
        address: this.address,
        marital_status: this.marital_status,
        place_of_birth: this.place_of_birth,
        phone: this.phone,
        mothers_name: this.mothers_name,
        fathers_name: this.fathers_name,
        insurance: this.insurance,
        title: this.title,
        profession: this.profession,
        education: this.education,
        church: this.church,
      };
      if (this.patientDetails) data._method = "PUT";

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/patients${
            this.patientDetails ? "/update" : ""
          }`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success("Profile saved successfully", {
            theme: "colored",
          });
          this.clearPatientForm();
          this.getPatientDetailsHandler();
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isAddingPatientForm = false;
        });
    },

    InitializeFormToUpdate() {
      this.date_of_birth = this.patientDetails.date_of_birth;
      this.gender = this.patientDetails.gender;
      this.nid = this.patientDetails.nid;
      this.address = this.patientDetails.address;
      this.marital_status = this.patientDetails.marital_status;
      this.place_of_birth = this.patientDetails.place_of_birth;
      this.phone = this.patientDetails.phone;
      this.mothers_name = this.patientDetails.mothers_name;
      this.fathers_name = this.patientDetails.fathers_name;
      this.insurance = this.patientDetails.insurance;
      this.title = this.patientDetails.title;
      this.profession = this.patientDetails.profession;
      this.education = this.patientDetails.education;
      this.church = this.patientDetails.church;
    },

    getPatientInsurancesHandler() {
      axios
        .get(`${this.$store.state.apiUrl}/v1/insurance-companies`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.insurancesList = response.data.data;
        })
        .catch((error) => {
          toast.error("Something went, cannot get insurance list", {
            theme: "colored",
            clearOnUrlChange: false,
          });
        })
        .finally(() => {});
    },
  },
  mounted() {
    if (this.patientDetails) {
      this.InitializeFormToUpdate();
    }
  },
  created() {
    this.getPatientInsurancesHandler();
  },
};
</script>

<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

.patient-p-form {
  padding: 20px;
}

.heading--1 {
  margin-bottom: 20px;
}

input,
select {
  margin-bottom: 10px;
}

.width--1 {
  width: 100%;
}

.width--2 {
  width: 50%;
}
</style>