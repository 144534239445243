import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'
import LoginView from '../views/LoginView.vue'
import RegisterView from "../views/RegisterView"
import VerificationView from "../views/VerificationView"
import DashboardChatView from "../views/DashboardChatView"
import PaymentsView from "../views/PaymentsView"
import CompanyProfileView from "../views/CompanyProfileView"
import PricingAndBillView from "../views/PricingAndBillView"
import EmployeesView from "../views/EmployeesView"
import ClientsInfoView from "../views/ClientsInfoView"
import PricingView from "../views/PricingView"
import ForgotPasswordView from "../views/ForgotPasswordView"
import RestForgottenPasswordView from "../views/RestForgottenPasswordView"
import CheckoutView from "../views/CheckoutView"
import PackagesView from "../views/PackagesView"
import CompaniesView from "../views/CompaniesView"
import UserProfileView from "../views/UserProfileView"
import CompanyDetails from "../views/CompanyDetails"
import MyPaymentsView from "../views/MyPaymentsView"
import MySubscriptionView from "../views/MySubscriptionView"
import PatientInfoView from "../views/PatientInfoView"
import AgentsView from "../views/AgentsView"
import TherapistsView from "../views/TherapistsView"
import MyHealthView from "../views/MyHealthView"
import MyAssignedPatient from "../views/MyAssignedPatient"
import AgentViewTherapists from "../views/AgentViewTherapists"
import AgentViewCompanies from "../views/AgentViewCompanies"
import AgentCompanyEmployees from "../views/AgentCompanyEmployees"
import AgentCallSessionsView from "../views/AgentCallSessionsView"
import ConsultationsListView from "../views/ConsultationsListView"
import AddConsultationsView from "../views/AddConsultationsView"
import AgentManageEmployees from "../views/AgentManageEmployees"
import SingleEmployeeDetailsView from "../views/SingleEmployeeDetailsView"
import ExtendMySubscriptionView from "../views/ExtendMySubscriptionView"
import ExtendedSubscriptionView from "../views/ExtendedSubscriptionView"
import EmployeeAnalyticsView from "../views/EmployeeAnalyticsView"
import TherapistAnalyticsView from "../views/TherapistAnalyticsView"
import AgentAnalyticsView from "../views/AgentAnalyticsView"
import CompanyAnalyticsView from "../views/CompanyAnalyticsView"
import AdminAnalyticsView from "../views/AdminAnalyticsView"
import AdminViewCallSessions from "../views/AdminViewCallSessions"
import AdminViewConsultations from "../views/AdminViewConsultations"
import TherepistLoginProfile from "../views/TherepistLoginProfile"
import MyAssignedTherapists from "../views/MyAssignedTherapists"
import AllPaymentsView from "../views/AllPaymentsView"
import AllSubscriptionsView from "../views/AllSubscriptionsView"
import AddCallSessionView from "../views/AddCallSessionView"
import AllExtendedSubscriptions from "../views/AllExtendedSubscriptions"

const routes = [
  {
    path: '/',
    name: 'login',
    component: LoginView
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView
  },
  {
    path: '/user-verification',
    name: 'user-verification',
    component: VerificationView,
  },
  {
    path: '/dashboard-chat',
    name: 'dashboard-chat',
    component: DashboardChatView,
    meta: { requiresAuth: true }
  },
  {
    path: '/payments',
    name: 'payments',
    component: PaymentsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/company-profile',
    name: 'company-profile',
    component: CompanyProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/pricing-and-bill',
    name: 'pricing-and-bill',
    component: PricingAndBillView,
    meta: { requiresAuth: true }
  },
  {
    path: '/employees',
    name: 'employees',
    component: EmployeesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/reusable',
    name: 'reusable',
    component: HomeView
  },
  {
    path: '/clients-info',
    name: 'clients-info',
    component: ClientsInfoView,
  },
  {
    path: '/pricing',
    name: 'pricing',
    component: PricingView,
    meta: { requiresAuth: true }
  },
  {
    path: '/forget-password',
    name: 'forget-password',
    component: ForgotPasswordView
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: RestForgottenPasswordView
  },
  {
    path: '/checkout',
    name: 'checkout',
    component: CheckoutView,
    meta: { requiresAuth: true }
  },
  {
    path: '/packages',
    name: 'packages',
    component: PackagesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/companies',
    name: 'companies',
    component: CompaniesView,
    meta: { requiresAuth: true }
  },
  {
    path: '/user-profile',
    name: 'user-profile',
    component: UserProfileView,
    meta: { requiresAuth: true }
  },
  {
    path: '/company-details/:id',
    name: 'company-details',
    component: CompanyDetails,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-company-subscriptions',
    name: 'my-company-subscriptions',
    component: MySubscriptionView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-company-payments',
    name: 'my-company-payments',
    component: MyPaymentsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-info-view',
    name: 'my-info-view',
    component: PatientInfoView,
    meta: { requiresAuth: true }
  },
  {
    path: '/agents',
    name: 'agents',
    component: AgentsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/therapists',
    name: 'therapists',
    component: TherapistsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/mood-tracker',
    name: 'mood-tracker',
    component: MyHealthView,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-patient',
    name: 'my-patient',
    component: MyAssignedPatient,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-therapists',
    name: 'agent-therapists',
    component: AgentViewTherapists,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-companies',
    name: 'agent-companies',
    component: AgentViewCompanies,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-view-companies-employees/:id',
    name: 'agent-view-companies-employees',
    component: AgentCompanyEmployees,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-call-sessions',
    name: 'agent-call-sessions',
    component: AgentCallSessionsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-manage-employees',
    name: 'agent-manage-employees',
    component: AgentManageEmployees,
    meta: { requiresAuth: true }
  },
  {
    path: '/patient-consultations',
    name: 'patient-consultations',
    component: ConsultationsListView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-consultations/:id',
    name: 'add-consultations',
    component: AddConsultationsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-view-employee-details/:id',
    name: 'agent-view-employee-details',
    component: SingleEmployeeDetailsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/extended-subscription/:id',
    name: 'extended-subscription',
    component: ExtendedSubscriptionView,
    meta: { requiresAuth: true }
  },
  {
    path: '/employee-analytics',
    name: 'employee-analytics',
    component: EmployeeAnalyticsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/therapist-analytics',
    name: 'therapist-analytics',
    component: TherapistAnalyticsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/agent-analytics',
    name: 'agent-analytics',
    component: AgentAnalyticsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/company-analytics',
    name: 'company-analytics',
    component: CompanyAnalyticsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-analytics',
    name: 'admin-analytics',
    component: AdminAnalyticsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-view-call-sessions',
    name: 'admin-view-call-sessions',
    component: AdminViewCallSessions,
    meta: { requiresAuth: true }
  },
  {
    path: '/admin-view-consultations',
    name: 'admin-view-consultations',
    component: AdminViewConsultations,
    meta: { requiresAuth: true }
  },
  {
    path: '/therapist-login-profile',
    name: 'therapist-login-profile',
    component: TherepistLoginProfile,
    meta: { requiresAuth: true }
  },
  {
    path: '/my-doctors',
    name: 'my-doctors',
    component: MyAssignedTherapists,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-payments',
    name: 'all-payments',
    component: AllPaymentsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-subscriptions',
    name: 'all-subscriptions',
    component: AllSubscriptionsView,
    meta: { requiresAuth: true }
  },
  {
    path: '/add-call-session/:id',
    name: 'add-call-session',
    component: AddCallSessionView,
    meta: { requiresAuth: true }
  },
  {
    path: '/all-extended-subscriptions',
    name: 'all-extended-subscriptions',
    component: AllExtendedSubscriptions,
    meta: { requiresAuth: true }
  },
  {
    path: '/extend-my-subscriptions',
    name: 'extend-my-subscriptions',
    component: ExtendMySubscriptionView,
    meta: { requiresAuth: true }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
