<template>
  <base-layout>
    <template #content>
      <div class="agents-header g-flex g-align-center g-space-btw">
        <h3>Call sessions</h3>

        <div class="g-flex g-align-center">
          <label>From</label>
          <input
            type="date"
            v-model="rangeFrom"
            @input="searchByEveryThingHandler"
          />
          <label style="margin-left: 6px">To</label>
          <input
            type="date"
            v-model="rangeTo"
            @input="searchByEveryThingHandler"
          />
        </div>
        <div style="width: 300px" class="global-search">
          <input
            type="text"
            placeholder="Search..."
            v-model="searchValue"
            @keyup.enter="searchByEveryThingHandler"
          />
          <button @click="searchByEveryThingHandler">
            <i class="fa-solid fa-magnifying-glass"></i>
          </button>
        </div>

        <div style="width: 160px">
          <router-link to="/agent-manage-employees">
            <base-button name="New Call Session"></base-button>
          </router-link>
        </div>
      </div>
      <div style="padding: 0 10px">
        <table>
          <thead>
            <tr>
              <th>Employee name</th>
              <th>Company</th>
              <th>Employee Phone</th>
              <th>Call session date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody v-if="callSessionsList.length && !isGettingCallSessions">
            <tr v-for="item in callSessionsList" :key="item.id">
              <td>{{ item.patient_name }}</td>
              <td>{{ item.employeer }}</td>
              <td>{{ item.patient.phone }}</td>
              <td>{{ item.call_date }}</td>
              <td class="td-action">
                <actions-btn>
                  <template #content>
                    <button
                      class="action-btn-item"
                      @click="toggleGetCallSessionDetails(item.id)"
                    >
                      Details
                    </button>
                    <button
                      class="action-btn-item"
                      @click="toggleAddNoteToCall(item.id)"
                    >
                      Add Note
                    </button>
                    <button
                      class="action-btn-item"
                      @click="toggleGetCallSessionNotes(item.id)"
                    >
                      View Notes
                    </button>
                    <!-- <button
                      class="action-btn-item"
                      @click="toggleDeleteWarningHandler(item.id)"
                    >
                      Delete
                    </button> -->
                  </template>
                </actions-btn>
              </td>
            </tr>
          </tbody>
        </table>

        <div
          class="uhuru-pg-container"
          v-if="!isGettingCallSessions && hasNextPage"
        >
          <vue-awesome-paginate
            :total-items="totalPgItems"
            :items-per-page="10"
            :max-pages-shown="8"
            v-model="currentPage"
            paginate-buttons-class="pg-btn"
            active-page-class="pg-btn-active"
            back-button-class="pg-back-btn"
            next-button-class="pg-next-btn"
            prev-button-content="Prev"
            next-button-content="Next"
            :on-click="getcallSessionsListHandler"
          />
        </div>

        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingCallSessions"
        >
          <table-data-loader></table-data-loader>
        </div>
      </div>

      <div v-if="isToggleAddNoteToCall">
        <modal :closeModal="toggleAddNoteToCall" width="580px">
          <template #content>
            <div>
              <h3 style="margin: 20px 0">Add note on this Call</h3>
            </div>
            <div>
              <label>Enter any Note</label>
              <div style="height: 200px">
                <wysiwyg v-model="notes"></wysiwyg>
              </div>
            </div>
            <div style="margin: 20px 0">
              <base-button
                name="Add Note"
                :isLoading="isMakingChangeOnCallSession"
                :onClick="addNoteOnCallSessionHadnler"
              ></base-button>
            </div>
          </template>
        </modal>
      </div>

      <div v-if="isToggleMoreOnCall">
        <modal :closeModal="closeSingleCallSessionModal" width="780px">
          <template #content>
            <single-call-session
              :callSessionDetails="singleCallSessionVal"
              :isGettingMoreOnCallSession="isGettingMoreOnCallSession"
            ></single-call-session>
          </template>
        </modal>
      </div>

      <div v-if="isToggleNotesOnCall">
        <modal :closeModal="closeViewCallSessionNotesModal" width="780px">
          <template #content>
            <call-session-notes
              :callSessionNotes="singleCallSessionNotes"
              :isGettingMoreOnCallSession="isGettingMoreOnCallSession"
            ></call-session-notes>
          </template>
        </modal>
      </div>

      <div v-if="isToggleDeleteWarning">
        <warning-msg
          message="Are you sure you want to delete this call session"
          :onClick="deleteCallSessionHandler"
          :onClosingModal="toggleDeleteWarningHandler"
          :isOnLoading="isDeletingCallSession"
        ></warning-msg>
      </div>
    </template>
  </base-layout>
</template>
      
<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseLayout from "@/components/reusable/BaseLayout.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";
import Modal from "@/components/reusable/Modal.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
import ActionsBtn from "@/components/reusable/ActionsBtn.vue";
import WarningMsg from "@/components/reusable/WarningMsg.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";
import SingleCallSession from "@/components/SingleCallSession.vue";
import CallSessionNotes from "@/components/CallSessionNotes.vue";
import formatDateMixin from "@/mixins/formatDateMixin";

export default {
  components: {
    BaseLayout,
    BaseButton,
    Modal,
    TableDataLoader,
    ActionsBtn,
    WarningMsg,
    Wysiwyg,
    SingleCallSession,
    CallSessionNotes,
  },
  data() {
    return {
      isGettingCallSessions: false,
      callSessionsList: [],
      isDeletingCallSession: false,
      selectedCallSessionToDelete: null,
      isToggleDeleteWarning: false,
      selectedCallSessionToEdit: null,

      isToggleAddNewCallSession: false,
      isMakingChangeOnCallSession: false,
      formErrors: {},
      key_point: "",
      overview: "",
      summary: "",
      start_at: "",
      end_at: "",
      call_date: "",

      isToggleMoreOnCall: false,
      callSessionIdToToggle: "",
      isGettingMoreOnCallSession: false,
      singleCallSessionVal: null,
      singleCallSessionNotes: [],
      isToggleNotesOnCall: false,

      isToggleAddNoteToCall: false,
      callToAddNoteOn: "",

      rangeFrom: "",
      rangeTo: this.formatDate(new Date()),
      searchValue: "",
      isOnSearchingAgents: false,

      currentPage: 1,
      totalPgItems: 0,
      hasNextPage: false,
    };
  },
  mixins: [formatDateMixin],
  methods: {
    searchByEveryThingHandler() {
      if (!this.rangeFrom && !this.searchValue) {
        return;
      }
      this.isOnSearchingAgents = true;
      this.getcallSessionsListHandler();
    },

    generateUrlHandler() {
      if (
        this.isOnSearchingAgents &&
        this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/agents/call-sessions?range_from=${this.rangeFrom}&range_until=${this.rangeTo}&search=${this.searchValue}`;
      }

      if (this.isOnSearchingAgents && this.searchValue && !this.rangeFrom) {
        return `${this.$store.state.apiUrl}/v1/agents/call-sessions?search=${this.searchValue}`;
      }

      if (
        this.isOnSearchingAgents &&
        !this.searchValue &&
        this.rangeFrom &&
        this.rangeTo
      ) {
        return `${this.$store.state.apiUrl}/v1/agents/call-sessions?range_from=${this.rangeFrom}&range_until=${this.rangeTo}`;
      }

      if (!this.isOnSearchingAgents) {
        return `${this.$store.state.apiUrl}/v1/agents/call-sessions`;
      }
    },

    closeViewCallSessionNotesModal() {
      this.isToggleNotesOnCall = !this.isToggleNotesOnCall;
    },
    toggleGetCallSessionNotes(item) {
      this.callSessionIdToToggle = item;
      this.isToggleNotesOnCall = !this.isToggleNotesOnCall;
      if (this.isToggleNotesOnCall && this.callSessionIdToToggle) {
        this.getNotesOnCallSessionHandler();
      }
    },

    getNotesOnCallSessionHandler() {
      this.isGettingMoreOnCallSession = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/agents/call-sessions/${this.callSessionIdToToggle}/view-notes`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.singleCallSessionNotes = response.data.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingMoreOnCallSession = false;
        });
    },

    toggleAddNoteToCall(item) {
      this.callToAddNoteOn = item;
      this.isToggleAddNoteToCall = !this.isToggleAddNoteToCall;
    },

    addNoteOnCallSessionHadnler() {
      if (!this.notes) {
        return toast.error("Type note to add on a call session", {
          theme: "colored",
        });
      }

      this.isMakingChangeOnCallSession = true;

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/agents/call-sessions/${this.callToAddNoteOn}/add-notes?notes=${this.notes}`,
          null,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });

          this.isToggleAddNoteToCall = false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnCallSession = false;
        });
    },

    closeSingleCallSessionModal() {
      this.isToggleMoreOnCall = !this.isToggleMoreOnCall;
    },
    toggleGetCallSessionDetails(item) {
      this.callSessionIdToToggle = item;
      this.isToggleMoreOnCall = !this.isToggleMoreOnCall;
      if (this.isToggleMoreOnCall && this.callSessionIdToToggle) {
        this.getMoreOnCallSessionHandler();
      }
    },

    getMoreOnCallSessionHandler() {
      this.isGettingMoreOnCallSession = true;
      axios
        .get(
          `${this.$store.state.apiUrl}/v1/agents/call-sessions/${this.callSessionIdToToggle}/show`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          this.singleCallSessionVal = response.data.data;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingMoreOnCallSession = false;
        });
    },

    toggleAddNewCallSessionModal() {
      this.selectedCallSessionToEdit = null;
      this.key_point = "";
      this.summary = "";
      this.overview = "";
      this.start_at = "";
      this.end_at = "";
      this.call_date = "";
      this.isToggleAddNewCallSession = !this.isToggleAddNewCallSession;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.key_point) {
        this.formErrors.key_point = true;
      }
      if (!this.summary) {
        this.formErrors.summary = true;
      }
      if (!this.overview) {
        this.formErrors.overview = true;
      }
      if (!this.start_at) {
        this.formErrors.start_at = true;
      }
      if (!this.end_at) {
        this.formErrors.end_at = true;
      }
      if (!this.call_date) {
        this.formErrors.call_date = true;
      }

      return (
        !this.formErrors.key_point &&
        !this.formErrors.summary &&
        !this.formErrors.overview &&
        !this.formErrors.start_at &&
        !this.formErrors.end_at &&
        !this.formErrors.call_date
      );
    },

    getcallSessionsListHandler(page) {
      this.isGettingCallSessions = true;
      let actionUrl = "";
      if (this.isOnSearchingAgents) {
        actionUrl = this.generateUrlHandler() + `&page=${page}`;
      } else {
        actionUrl = this.generateUrlHandler() + `?page=${page}`;
      }

      axios
        .get(actionUrl, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.callSessionsList = response.data.data.data;
          this.totalPgItems = response.data.data.total;
          this.hasNextPage = response.data.data.last_page > 1 ? true : false;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingCallSessions = false;
        });
    },

    toggleDeleteWarningHandler(item) {
      this.selectedCallSessionToDelete = item;
      this.isToggleDeleteWarning = !this.isToggleDeleteWarning;
    },

    deleteCallSessionHandler() {
      this.isDeletingCallSession = true;

      axios
        .delete(
          `${this.$store.state.apiUrl}/v1/agents/call-sessions/${this.selectedCallSessionToDelete}/delete`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.callSessionsList = this.callSessionsList.filter(
            (item) => item.id !== this.selectedCallSessionToDelete
          );
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isDeletingCallSession = false;
          this.isToggleDeleteWarning = false;
        });
    },
  },
  created() {
    this.getcallSessionsListHandler();
  },
};
</script>
      
    <style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.agents-header {
  margin: 20px;
}
.agents-header input {
  margin-bottom: 0 !important;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
.add-new-agents-form input {
  margin-bottom: 10px;
}
.form-errors {
  margin-top: -10px;
}
input {
  margin-bottom: 10px;
}
a {
  text-decoration: none;
}
</style>