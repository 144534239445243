<template>
  <div>
    <p><b>EMPLOYEE DETAILS</b></p>

    <div
      style="width: 100%; margin: 10px"
      class="g-flex g-align-center g-content-center"
      v-if="isGettingEmployeeDetails"
    >
      <table-data-loader></table-data-loader>
    </div>

    <div v-if="employeeDetails" class="g-flex g-space-btw">
      <div>
        <p><b>Name: </b>{{ employeeDetails.name }}</p>
        <p><b>Employee number: </b>{{ employeeDetails.employee_number }}</p>
        <p><b>Email: </b>{{ employeeDetails.loginDetails.email }}</p>
        <p><b>Phone: </b>{{ employeeDetails.phone || "N/A" }}</p>
        <p>
          <b>Date of birth: </b
          >{{
            employeeDetails.patient_form
              ? employeeDetails.patient_form.date_of_birth
              : "N/A"
          }}
        </p>
        <p>
          <b>Gender: </b
          >{{
            employeeDetails.patient_form
              ? employeeDetails.patient_form.gender
              : "N/A"
          }}
        </p>
        <p>
          <b>Payment status: </b>
          {{ employeeDetails.is_subscription_paid ? "Paid" : "Not Paid" }}
        </p>
        <p>
          <b>Treatment status: </b>
          {{ employeeDetails.assigned ? "Has doctor" : "Has no doctor" }}
        </p>
        <p>
          <b>Account status: </b>
          {{
            employeeDetails.active
              ? "Active"
              : "Not Active" + "/" + employeeDetails.verified
              ? "Verified"
              : "Not verified"
          }}
        </p>
        <!-- <p><b>Assign to doctor: </b>{{ employeeDetails.assigned }}</p> -->
      </div>
      <div style="margin-left: 40px" v-if="employeeDetails.patient_form">
        <p><b>Address: </b>{{ employeeDetails.patient_form.address }}</p>
        <p>
          <b>Marital status: </b
          >{{ employeeDetails.patient_form.marital_status }}
        </p>
        <p><b>National ID No.: </b>{{ employeeDetails.patient_form.nid }}</p>
        <p>
          <b>Father's name: </b>{{ employeeDetails.patient_form.fathers_name }}
        </p>
        <p>
          <b>Mother's name: </b>{{ employeeDetails.patient_form.mothers_name }}
        </p>
        <p><b>Insurance: </b>{{ employeeDetails.patient_form.insurance }}</p>
        <p>
          <b>Place of birth: </b
          >{{ employeeDetails.patient_form.place_of_birth }}
        </p>
        <p><b>Education: </b>{{ employeeDetails.patient_form.education }}</p>
        <p><b>Title/Post: </b>{{ employeeDetails.patient_form.title }}</p>
        <p>
          <b>Professional: </b>{{ employeeDetails.patient_form.profession }}
        </p>
        <p><b>Religion: </b>{{ employeeDetails.patient_form.church }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import TableDataLoader from "./reusable/TableDataLoader.vue";
export default {
  components: { TableDataLoader },
  props: ["singleSelectedEmployee"],
  data() {
    return {
      isGettingEmployeeDetails: false,
      employeeDetails: null,
    };
  },
  methods: {
    getSingleEmployeeDetails() {
      this.isGettingEmployeeDetails = true;

      let actionUrl =
        this.$store.state.authUser.role == "Client"
          ? `dashboard/employees/${this.singleSelectedEmployee}`
          : `uhuru/clients/employees/${this.singleSelectedEmployee}/details`;
      axios
        .get(`${this.$store.state.apiUrl}/v1/${actionUrl}`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.employeeDetails =
            this.$store.state.authUser.role == "Client"
              ? response.data.data
              : response.data.data.details;
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingEmployeeDetails = false;
        });
    },
  },
  created() {
    this.getSingleEmployeeDetails();
  },
};
</script>

<style scoped>
p {
  margin: 4px 0;
}
p b {
  font-weight: 600;
}
</style>