<template>
  <base-layout>
    <template #content>
      <div class="ag-comp-emp">
        <div style="width: 80%; margin: 0 auto">
          <div style="width: 100%" class="g-flex g-align-center g-space-btw">
            <h3 style="margin: 20px 0">ADD NEW CALL SESSION</h3>
          </div>

          <div>
            <div class="g-flex">
              <div>
                <label>Start Time</label>
                <input type="time" v-model="start_at" />
                <p v-if="formErrors.start_at && !start_at" class="form-errors">
                  Start time of call session is required
                </p>
              </div>
              <div style="margin: 0 10px">
                <label>End Time</label>
                <input type="time" v-model="end_at" />
                <p v-if="formErrors.end_at && !end_at" class="form-errors">
                  End time of call session is required
                </p>
              </div>
              <div>
                <label>Call Session Date</label>
                <input type="date" v-model="call_date" />
                <p
                  v-if="formErrors.call_date && !call_date"
                  class="form-errors"
                >
                  End time of call session is required
                </p>
              </div>
            </div>

            <label>Enter Key point</label>
            <div style="height: 200px">
              <wysiwyg v-model="key_point"></wysiwyg>
            </div>
            <p v-if="formErrors.key_point && !key_point" class="form-errors">
              Key point of a call session is a required
            </p>

            <label>Enter Summary</label>
            <div style="height: 200px">
              <wysiwyg v-model="summary"></wysiwyg>
            </div>
            <p v-if="formErrors.summary && !summary" class="form-errors">
              Summary of a call session is required
            </p>

            <label>Call Overview</label>
            <div style="height: 200px">
              <wysiwyg v-model="overview"></wysiwyg>
            </div>
            <p v-if="formErrors.overview && !overview" class="form-errors">
              Overview of a call session is required
            </p>
          </div>
          <div style="width: 200px; margin: 20px 0">
            <base-button
              name="Submit Call Session"
              :isLoading="isMakingChangeOnCallSession"
              :onClick="addNewCallSessionHandler"
            ></base-button>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>
  
<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";
import TableDataLoader from "../components/reusable/TableDataLoader.vue";
import BaseButton from "../components/reusable/BaseButton.vue";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import Wysiwyg from "@/components/Wysiwyg.vue";

export default {
  components: {
    TableDataLoader,
    BaseButton,
    BaseLayout,
    Wysiwyg,
  },
  data() {
    return {
      formErrors: {},
      key_point: "",
      overview: "",
      summary: "",
      start_at: "",
      end_at: "",
      call_date: "",
      isMakingChangeOnCallSession: false,

      clientId: this.$route.params.id,
    };
  },

  methods: {
    formattedTime(val) {
      const [hours, minutes] = val.split(":");

      let formattedHours = parseInt(hours, 10) % 12 || 12;
      const amOrPm = parseInt(hours, 10) >= 12 ? "PM" : "AM";

      formattedHours = String(formattedHours).padStart(2, "0");

      const formattedTime = `${formattedHours}:${minutes} ${amOrPm}`;
      return formattedTime;
    },

    validateForm() {
      this.formErrors = {};

      if (!this.key_point) {
        this.formErrors.key_point = true;
      }
      if (!this.summary) {
        this.formErrors.summary = true;
      }
      if (!this.overview) {
        this.formErrors.overview = true;
      }
      if (!this.start_at) {
        this.formErrors.start_at = true;
      }
      if (!this.end_at) {
        this.formErrors.end_at = true;
      }
      if (!this.call_date) {
        this.formErrors.call_date = true;
      }

      return (
        !this.formErrors.key_point &&
        !this.formErrors.summary &&
        !this.formErrors.overview &&
        !this.formErrors.start_at &&
        !this.formErrors.end_at &&
        !this.formErrors.call_date
      );
    },

    addNewCallSessionHandler() {
      if (!this.validateForm()) {
        return;
      }

      this.isMakingChangeOnCallSession = true;

      let data = {
        key_point: this.key_point,
        summary: this.summary,
        overview: this.overview,
        start_at: this.formattedTime(this.start_at),
        end_at: this.formattedTime(this.end_at),
        call_date: this.call_date,
      };

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/agents/call-sessions/${this.clientId}`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$router.push("/agent-call-sessions");
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isMakingChangeOnCallSession = false;
        });
    },
  },
  created() {},
};
</script>
  
  <style src="vue-multiselect/dist/vue-multiselect.css"></style>
  <style scoped>
.ag-comp-emp {
  flex: 1;
  padding: 20px;
}
.spanning--1 {
  font-size: 15px;
}
.assign-ts-form input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none !important;
  width: 220px;
}
input[type="checkbox"] {
  width: 15px;
  height: 15px;
}
.assign-therapist {
  background-color: var(--secondary-color);
  color: #ffffff;
  border: none;
  padding: 10px;
  border-radius: 4px;
  font-size: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
}
.green-status {
  color: #5e8722;
}
.red-status {
  color: #a60909;
}
input {
  margin-bottom: 10px;
}
.search-emp {
  width: 500px;
  border-radius: 50px;
}
</style>