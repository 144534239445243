<template>
  <div>
    <div class="checkout-top-header g-flex g-align-center">
      <div class="login-view-img">
        <img src="/images/uhuru-logo.png" alt="" />
      </div>
      <div class="g-flex g-align-center">
        <p>{{ authUser.name }}</p>
        <user-circle
          :currentIndex="0"
          :currentUser="authUser.name"
          marginLeft="10px"
        ></user-circle>
      </div>
    </div>
    <div class="checkout-body g-flex">
      <div class="checkout-body-left">
        <div class="checkout-body-left-top g-flex">
          <img src="/images/visa_master_card.png" alt="" />
          <img src="/images/mtn.png" alt="" />
          <div>
            <div><h4>Payment Options</h4></div>
            <label class="g-flex g-align-center">
              <input type="radio" name="paymentOption" checked />
              <p>Pay with credit card</p>
            </label>
            <label class="g-flex g-align-center">
              <input type="radio" name="paymentOption" />
              <p>Pay with MoMo</p>
            </label>
          </div>
        </div>
        <div class="checkout-body-left-bottom">
          <div class="g-flex g-align-center">
            <h4 class="heading-1">Basic</h4>
            <p>Package</p>
          </div>
          <div class="marging--2 g-flex g-align-center">
            <label class="g-flex g-align-center">
              <input type="radio" name="paymentDuration" checked />
              <p class="paragraph--1">
                Annual <span class="spanning--1">(Save 10%)</span>
              </p>
            </label>
            <label class="padding--2 g-flex g-align-center">
              <input type="radio" name="paymentDuration" />
              <p class="paragraph--1">Monthly</p>
            </label>
          </div>
          <!-- middle -->
          <div class="checkout-body-left-price">
            <div class="g-flex g-space-btw">
              <p><b>Price</b></p>
              <p class="marging--3">
                <i><b>Rwf</b></i>
              </p>
            </div>
            <div class="g-flex g-space-btw">
              <p><i>Per Month</i></p>
              <p><i>20,000 * 12</i></p>
            </div>
            <div class="g-flex g-space-btw">
              <p><i>Per Month</i></p>
              <p><i>240,000 * 2</i></p>
            </div>
          </div>
          <div class="g-flex g-space-btw">
            <p><b>Total Amount</b></p>
            <p>
              <i><b>480,000 Rwf</b></i>
            </p>
          </div>
        </div>
      </div>

      <div class="checkout-body-right">
        <div class="checkout-body-right-header">
          <h3>Payment Details</h3>
        </div>
        <label>Card Number</label>
        <input
          type="text"
          placeholder="1234 1234 1234 1234"
          v-model="cardNumber"
        />
        <p v-if="checkoutErrors.cardNumber && !cardNumber" class="form-errors">
          Enter your card number
        </p>
        <label>Name on Card</label>
        <input
          type="text"
          placeholder="Enter the name on card"
          v-model="cardNumber"
        />
        <p v-if="checkoutErrors.cardNumber && !cardNumber" class="form-errors">
          Enter the name on your card
        </p>
        <div class="checkout-card-expiry g-flex">
          <div>
            <label>Year (Ex: 24)</label>
            <input type="text" placeholder="Exp. Year" v-model="cardNumber" />
            <p
              v-if="checkoutErrors.cardNumber && !cardNumber"
              class="form-errors"
            >
              The expiry year field is required
            </p>
          </div>
          <div class="marging-1">
            <label>Month (Ex: 12)</label>
            <input type="text" placeholder="Exp. Month" v-model="cardNumber" />
            <p
              v-if="checkoutErrors.cardNumber && !cardNumber"
              class="form-errors"
            >
              The expiry month field is required
            </p>
          </div>
          <div>
            <label>CVC/CVV</label>
            <input type="text" placeholder="CVC/CVV" v-model="cardNumber" />
            <p
              v-if="checkoutErrors.cardNumber && !cardNumber"
              class="form-errors"
            >
              The CVC/CVV field is required.
            </p>
          </div>
        </div>
        <div class="checkout-body-right-bottom-btn">
          <base-button name="Pay" :onClick="payPackageHandler"></base-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import UserCircle from "@/components/reusable/UserCircle.vue";
import BaseButton from "@/components/reusable/BaseButton.vue";

import { mapState } from "vuex";

export default {
  components: { UserCircle, BaseButton },
  data() {
    return {
      cardNumber: "",
      checkoutErrors: {},
    };
  },
  computed: {
    ...mapState(["authUser"]),
  },
  methods: {
    payPackageHandler() {
      this.$router.push("/employees");
    },
  },
};
</script>
  
<style scoped>
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}
.checkout-top-header {
  background: white;
  height: 80px;
  width: calc(100% - 40px);
  justify-content: space-between;
  padding: 0 20px;
}
.checkout-top-header img {
  width: 100px;
}
.checkout-body {
  justify-content: center;
  margin: 30px 0;
}
.checkout-body-left-top {
  width: calc(100% - 40px);
}
.checkout-body-left-top img {
  width: 80px;
  height: 50px;
  margin: 4px;
}
.checkout-body-left-top div label {
  font-size: 14px;
}
.checkout-body-left-top div {
  margin-left: 10px;
}
.checkout-body-left input[type="radio"] {
  width: 16px !important;
  height: 16px !important;
  padding: 0 !important;
}
.checkout-body-right,
.checkout-body-left-top,
.checkout-body-left-bottom {
  background: white;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
  padding: 20px;
  width: 370px;
  margin: 10px;
}
.checkout-body-right input {
  margin-bottom: 10px;
}
.checkout-body-right-header h3 {
  margin: 10px 0;
}
.checkout-body-right-bottom-btn {
  margin: 20px 0;
}
.marging-1 {
  margin: 0 10px;
}
.heading-1 {
  text-transform: uppercase;
  margin-right: 4px;
}
.paragraph--1 {
  margin-top: 4px;
  font-size: 14px;
}
.spanning--1 {
  color: #97dd31;
}
.marging--2 {
  margin: 10px 0;
}
.padding--2 {
  margin-left: 40px;
}
.checkout-body-left-price {
  border-top: 1px solid #b5b5b5;
  border-bottom: 1px solid #b5b5b5;
  margin: 20px 0;
  padding: 20px 0;
}
.checkout-body-left-price i {
  font-size: 15px;
}
.marging--3 {
  margin-right: 75px;
}
</style>