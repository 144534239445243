<template>
  <router-view />
</template>

<style>
html,
body {
  margin: 0;
  padding: 0;
}

body {
  background: #f5f5f5;
  min-width: 1300px;
}

:root {
  --primary-color: #1e4b7d;
  --secondary-color: #5e8722;
  --third-main-color: #f5f5f5;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.g-flex {
  display: flex;
}

.g-align-center {
  align-items: center;
}

.g-content-center {
  justify-content: center;
}
.g-space-btw {
  justify-content: space-between;
}

/* About form input */
input,
select,
textarea {
  border: 1px solid #687282;
  /* border: 1px solid #1e4b7d; */
  height: 40px;
  border-radius: 4px;
  color: #000000;
  font-size: 16px;
}

label {
  font-weight: 500;
}

select {
  height: 42px;
}

input {
  width: calc(100% - 22px);
  padding: 0 10px;
}

textarea {
  width: calc(100% - 22px);
  padding: 0 10px;
  min-height: 80px;
  resize: vertical;
}

input::placeholder {
  color: #a8a8a8;
  font-size: 15px;
}

select {
  width: 100%;
  padding-left: 8px;
}

select::placeholder {
  font-size: 14px;
}

input:focus,
select:focus {
  outline: none;
}

.form-errors {
  margin: -10px 0 0 0 !important;
  color: red;
  font-size: 15px;
}

button:hover {
  cursor: pointer;
}

.action-btn-item {
  background: transparent;
  border: none;
  width: 100%;
  padding: 4px 5px;
  text-align: left;
}

.action-btn-item:hover {
  background: var(--primary-color);
  color: white;
}
.g-back-btn {
  width: 34px;
  height: 34px;
  border: 2px solid var(--primary-color);
  color: var(--primary-color);
  border-radius: 50%;
}

/* Analytics cards */
.uh-analy-cards-cont {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}
.single-analy-card {
  background: white;
  width: 250px;
  height: 80px;
  display: flex;
  border: 1px solid #dddddd;
  margin: 10px 10px 10px 0;
}
.single-analy-card p {
  margin: 0;
}
.analytic-card-left {
  background: var(--primary-color);
  color: white;
  width: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.analytic-card-right {
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.analytic-card-right p {
  font-weight: 600;
  font-size: 18px;
  margin: 0 0 6px !important;
}
.global-search {
  position: relative;
}
.global-search button {
  position: absolute;
  right: 0;
  top: 0;
  height: 42px;
  width: 42px;
}
/* all table styles */
table {
  border-collapse: collapse;
  width: 100%;
}
th,
td {
  text-align: left;
  padding: 8px;
}
th {
  border-bottom: 1px solid #dddddd;
  background-color: #f8f8f8;
  color: #000000;
}
tr:nth-child(even) {
  background-color: #f8f8f8;
}
tbody tr:hover {
  background: #ececec;
  /* cursor: pointer; */
}
.td-action {
  min-width: 80px;
}
/* all loaders rotates */
@-webkit-keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes rotation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.uhuru-pg-container {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pagination-container {
  display: flex;
  column-gap: 10px;
}
/* .paginate-buttons {
  height: 40px;
  width: 40px;
  border-radius: 20px;
  cursor: pointer;
  background-color: rgb(242, 242, 242);
  border: 1px solid rgb(217, 217, 217);
  color: black;
}
.paginate-buttons:hover {
  background-color: #d8d8d8;
}
.active-page {
  background-color: #3498db;
  border: 1px solid #3498db;
  color: white;
}
.active-page:hover {
  background-color: #2988c8;
} */
.pg-btn {
  height: 40px;
  width: 40px;
  border: none;
  margin-inline: 5px;
  cursor: pointer;
}
.pg-back-btn {
  background-color: var(--primary-color);
  color: white;
  width: 60px;
}
.pg-next-btn {
  background-color: var(--primary-color);
  color: white;
  width: 60px;
}
.pg-btn-active {
  background-color: var(--secondary-color);
  color: white;
}
</style>
