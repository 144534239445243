<template>
  <div>
    <div class="comp-info-container">
      <div class="comp-info-view">
        <div class="client-view-img">
          <img src="/images/uhuru-logo.png" alt="" />
        </div>
        <div class="comp-info-header">
          <h2>
            {{ userType == "Company" ? "Company Info" : "Personal Info" }}
          </h2>
        </div>
        <!-- <div class="g-flex">
          <h4 class="zero-margin">
            {{ userType == "Company" ? "Company name:" : "Name" }}
          </h4>
          <p class="zero-margin padding-1">{{ name }}</p>
        </div>
        <div class="g-flex marging-1">
          <h4 class="zero-margin">
            {{ userType == "Company" ? "Company email:" : "Email" }}
          </h4>
          <p class="zero-margin padding-1">{{ email }}</p>
        </div> -->

        <label>Phyisical Address</label>
        <input
          type="text"
          placeholder="Ex: Kigali/Gasabo/Remera or KG 12 Ave"
          v-model="physical_address"
        />
        <p
          v-if="registerErrors.physical_address && !physical_address"
          class="form-errors"
        >
          Phyisical Address is required
        </p>
        <div v-if="userType == 'Company'">
          <label>Company TIN</label>
          <input
            type="text"
            placeholder="Enter company TIN number"
            v-model="tin_number"
          />
          <p
            v-if="registerErrors.tin_number && !tin_number"
            class="form-errors"
          >
            TIN number is required
          </p>
        </div>

        <div class="g-flex" v-if="userType == 'Company'">
          <div class="marging-2">
            <label>Company Phone</label>
            <input
              type="text"
              placeholder="Enter company phone"
              v-model="phone"
            />
            <p v-if="registerErrors.phone && !phone" class="form-errors">
              phone number is required
            </p>
          </div>
          <div>
            <label>Company Website</label>
            <input
              type="text"
              placeholder="Company website"
              v-model="website"
            />
            <p v-if="registerErrors.website && !website" class="form-errors">
              Company website is required
            </p>
          </div>
        </div>

        <label class="heading-1" v-if="userType == 'Company'"
          >Enter a person from your company to contact just in case</label
        >
        <br v-if="userType == 'Company'" />

        <div class="g-flex" v-if="userType == 'Company'">
          <div class="marging-2">
            <label>Name</label>
            <input
              type="text"
              placeholder="Person to contact"
              v-model="contact_person"
            />
            <p
              v-if="registerErrors.contact_person && !contact_person"
              class="form-errors"
            >
              Name of a person is required
            </p>
          </div>
          <div>
            <label>Phone</label>
            <input
              type="text"
              placeholder="Phone to contact"
              v-model="contact_person_phone"
            />
            <p
              v-if="
                registerErrors.contact_person_phone && !contact_person_phone
              "
              class="form-errors"
            >
              Phone number of a person is required
            </p>
          </div>
        </div>
        <div class="g-flex" v-if="userType == 'Company'">
          <div class="marging-2 sizing-1">
            <label>Email</label>
            <input
              type="email"
              placeholder="Email to contact"
              v-model="contact_person_email"
            />
            <p
              v-if="
                registerErrors.contact_person_email && !contact_person_email
              "
              class="form-errors"
            >
              Phone number of a person is required
            </p>
          </div>
          <div class="sizing-1">
            <label>Mode of Communication</label>
            <select v-model="mode_of_communication">
              <option value="">------Select option------</option>
              <option value="Telephone">Phone</option>
              <option value="Email">Email</option>
            </select>
            <p
              v-if="
                registerErrors.mode_of_communication && !mode_of_communication
              "
              class="form-errors"
            >
              Select mode of communication
            </p>
          </div>
        </div>
        <div v-if="userType == 'Company'">
          <label>Registration Certificate</label>
          <input type="file" placeholder="" @change="onFileChange" />
          <p
            v-if="
              registerErrors.registration_certificate &&
              !registration_certificate
            "
            class="form-errors"
          >
            Registration Certificate is required
          </p>
        </div>
        <div v-if="userType !== 'Company'">
          <label>Phone</label>
          <input
            type="text"
            placeholder="Enter your phone number"
            v-model="phone"
          />
          <p v-if="registerErrors.phone && !phone" class="form-errors">
            phone number is required
          </p>
          <label>Mode of Communication</label>
          <select v-model="mode_of_communication">
            <option value="">------Select option------</option>
            <option value="Telephone">Phone</option>
            <option value="Email">Email</option>
          </select>
          <p
            v-if="
              registerErrors.mode_of_communication && !mode_of_communication
            "
            class="form-errors"
          >
            Select mode of communication
          </p>
        </div>

        <div class="comp-info-view-btn">
          <base-button
            name="Save"
            :onClick="saveClientInfoHandler"
            :isLoading="isSavingClient"
          ></base-button>
        </div>
      </div>
    </div>
  </div>
</template>
  
<script>
import BaseButton from "@/components/reusable/BaseButton.vue";
import UserCircle from "@/components/reusable/UserCircle.vue";
import axios from "axios";
import employeeMixin from "../mixins/employeeMixin";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

export default {
  components: { BaseButton, UserCircle },
  mixins: [employeeMixin],
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      userType: "",
      contact_person: "",
      contact_person_phone: "",
      contact_person_email: "",
      tin_number: "",
      registration_certificate: null,
      physical_address: "",
      website: "",
      mode_of_communication: "",
      password: "",
      confirmPassword: "",
      isSavingClient: false,
      registerErrors: {},
    };
  },
  computed: {},
  methods: {
    saveClientInfoHandler() {
      if (!this.validateClientInfoForm()) {
        return;
      }
      this.isSavingClient = true;
      let data = new FormData();

      data.append("name", this.name);
      data.append("email", this.email);
      data.append("phone", this.phone);
      data.append("type", this.userType);
      if (this.userType === "Company") {
        data.append("contact_person", this.contact_person);
        data.append("contact_person_phone", this.contact_person_phone);
        data.append("contact_person_email", this.contact_person_email);
        data.append("tin_number", this.tin_number);
        data.append("registration_certificate", this.registration_certificate);
        data.append("website", this.website);
      }
      data.append("physical_address", this.physical_address);
      data.append("mode_of_communication", this.mode_of_communication);
      data.append("password", this.password);
      data.append("password_confirmation", this.confirmPassword);

      axios
        .post(`${this.$store.state.apiUrl}/v1/register`, data)
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
            clearOnUrlChange: false,
          });
          this.$store.commit("setUserAuthentication", response.data);
          localStorage.setItem("userAuth", JSON.stringify(response.data));
          this.$router.push("/pricing");
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isSavingClient = false;
        });
    },

    grabUserHandler() {
      const grabUser = sessionStorage.getItem("registerUser");
      if (!grabUser) {
        this.$route.push("/register");
      } else {
        let grabbedUser = JSON.parse(grabUser);

        this.name = grabbedUser.name;
        this.email = grabbedUser.email;
        this.userType = grabbedUser.type;
        this.password = grabbedUser.password;
        this.confirmPassword = grabbedUser.confirmPassword;
      }
    },
  },
  created() {
    this.grabUserHandler();
  },
};
</script>
  
<style scoped>
.comp-info-container {
  width: 100vw;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
.comp-info-view {
  width: 400px;
  margin: 20px auto;
  padding: 20px 40px;
  border-radius: 10px;
  box-shadow: 0px 0px 60px -26px rgba(0, 0, 0, 0.25);
  background: white;
}
.comp-info-header h2 {
  text-align: center;
}
.comp-info-view-btn {
  width: 150px;
}
input,
select {
  margin-bottom: 8px;
}
select {
  font-size: 14px !important;
}
input[type="file"] {
  border: none !important;
  width: 220px;
}
.heading-1 {
  font-weight: 600;
}
.zero-margin {
  margin: 0 !important;
}
.marging-1 {
  margin-bottom: 20px;
}
.padding-1 {
  padding-left: 4px;
}
.marging-2 {
  margin-right: 10px;
}
.sizing-1 {
  width: calc(50% - 5px);
}
.client-view-img {
  display: flex;
  align-items: center;
  justify-content: center;
}
.client-view-img img {
  width: 120px;
  margin: 10px auto 0;
}
</style>