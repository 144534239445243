<template>
  <div class="emp-analytics-cont">
    <main-header></main-header>
    <div class="emp-analytics-body">
      <div class="uh-analy-cards-cont" v-if="!isGettingAnalytics">
        <div
          class="single-analy-card"
          v-for="item in patientStatus"
          :key="item.status"
        >
          <div
            class="analytic-card-left pending-color"
            v-if="item.status == 'Assigned'"
          >
            <i class="fa-brands fa-hive"></i>
          </div>
          <div
            class="analytic-card-left rejected-color"
            v-if="item.status == 'Rejected'"
          >
            <i class="fa-solid fa-rectangle-xmark"></i>
          </div>
          <div class="analytic-card-left" v-if="item.status == 'On-going'">
            <i class="fa-solid fa-bars-progress"></i>
          </div>
          <div
            class="analytic-card-left completed-color"
            v-if="item.status == 'Completed'"
          >
            <i class="fa-solid fa-check-to-slot"></i>
          </div>
          <div class="analytic-card-right">
            <p>{{ item.total }}</p>
            <div>
              <label>{{ item.status }}</label>
            </div>
          </div>
        </div>
      </div>

      <div style="width: 100%; margin: 30px 0" v-if="!isGettingAnalytics">
        <p><label>TOTAL PATIENTS PER MONTHS</label></p>
        <apexchart
          type="area"
          height="350"
          :options="chartOptionsOne"
          :series="seriesOne"
        ></apexchart>
      </div>

      <div
        style="width: 100%; margin: 10px"
        class="g-flex g-align-center g-content-center"
        v-if="isGettingAnalytics"
      >
        <table-data-loader></table-data-loader>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import MainHeader from "@/components/MainHeader.vue";
import TableDataLoader from "@/components/reusable/TableDataLoader.vue";
export default {
  components: { MainHeader, TableDataLoader },
  data() {
    return {
      isGettingAnalytics: false,
      analyticsAllData: {
        total: [],
        callDates: [],
      },
      patientStatus: [],

      chartOptionsOne: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#0f676c"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesOne: [
        {
          name: "total patients",
          data: [],
        },
      ],
    };
  },

  methods: {
    assignAnalyticsData(data) {
      if (data.patients.length) {
        this.patientStatus = data.patients;
      }

      if (data.patients_trends.length) {
        data.patients_trends.map((item) => {
          this.analyticsAllData.total.push(item.total);
          this.analyticsAllData.callDates.push(item.month);

          this.seriesOne = [
            {
              name: "total patients",
              data: this.analyticsAllData.total,
            },
          ];
          this.chartOptionsOne = {
            xaxis: {
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
          };
        });
      }
    },
    getTherapistAnalytics() {
      this.isGettingAnalytics = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/therapists/analytics`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          console.log(response.data.data);
          this.assignAnalyticsData(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingAnalytics = false;
        });
    },
  },

  created() {
    this.getTherapistAnalytics();
  },
};
</script>

<style scoped>
.emp-analytics-body {
  width: 90%;
  background: white;
  padding: 20px;
  margin: 40px auto 0;
}
.pending-color {
  background: #b9960b !important;
}
.onGoing-color {
  background: #1e4b7d !important;
}
.completed-color {
  background: #5e8722 !important;
}
.rejected-color {
  background: #a60909 !important;
}
</style>
