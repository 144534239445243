<template>
  <div class="modal-container">
    <div @click="closeModal" class="modal-shadow"></div>
    <div class="modal-center" :style="{ width: width }">
      <img src="/images/Group_95.svg" alt="" @click="closeModal" />
      <slot name="content"></slot>
    </div>
  </div>
</template>
  
  <script>
export default {
  name: "Modal",
  props: {
    width: {
      type: String,
      default: "34%",
    },
    closeModal: {
      type: Function,
      default: () => {},
    },
  },
};
</script>
  
  <style scoped>
.modal-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}
.modal-shadow {
  position: fixed;
  left: 0;
  top: 0;
  background-color: rgb(0, 0, 0, 0.42);
  width: 100%;
  height: 100vh;
  z-index: 100;
}

.modal-center {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  /* margin: 0 auto; */
  background-color: white;
  max-height: 85vh;
  border-radius: 10px;
  padding: 20px;
  z-index: 100;
  overflow-y: auto;
}
.modal-center img {
  position: absolute;
  font-size: 22px;
  right: 10px;
  top: 10px;
  cursor: pointer;
  width: 30px;
  height: 30px;
}

@media (max-width: 1000px) {
  .modal-center {
    width: 60% !important;
  }
}
@media (max-width: 870px) {
  .modal-center {
    width: 65% !important;
  }
}
@media (max-width: 700px) {
  .modal-center {
    width: 70% !important;
  }
}
@media (max-width: 500px) {
  .modal-center {
    width: 80% !important;
  }
}
</style>
  