<template>
  <base-layout>
    <template #content>
      <div class="emp-analytics-cont">
        <div class="emp-analytics-body">
          <div class="uh-analy-cards-cont">
            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-mobile"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ totalCallSessions }}</p>
                <div>
                  <label>Total call sessions</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-phone-volume"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_sessions_current_month }}</p>
                <div>
                  <label>Monthly call sessions</label>
                </div>
              </div>
            </div>

            <div class="single-analy-card">
              <div class="analytic-card-left">
                <i class="fa-solid fa-headset"></i>
              </div>
              <div class="analytic-card-right">
                <p>{{ total_sessions_current_year }}</p>
                <div>
                  <label>Annual call sessions</label>
                </div>
              </div>
            </div>
          </div>
          <div class="g-flex" style="width: 100%; margin: 30px 0">
            <!-- Pie chart -->
            <div style="width: 50%">
              <p><label>WEEKLY CALLS</label></p>
              <apexchart
                type="pie"
                width="500"
                :options="chartOptionsThree"
                :series="seriesThree"
              ></apexchart>
            </div>

            <div style="width: 50%">
              <p><label>CALL DURATIONS</label></p>
              <apexchart
                type="area"
                height="350"
                :options="chartOptionsTwo"
                :series="seriesTwo"
              ></apexchart>
            </div>
          </div>
          <div style="width: 100%">
            <p><label>CALL SESSIONS</label></p>
            <apexchart
              type="area"
              height="350"
              :options="chartOptionsOne"
              :series="seriesOne"
            ></apexchart>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import MainHeader from "@/components/MainHeader.vue";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
export default {
  components: { MainHeader, BaseLayout },
  data() {
    return {
      isGettingAnalytics: false,
      analyticsAllData: {
        total: [],
        callDuration: [],
        callDates: [],
      },
      totalCallSessions: 0,
      total_sessions_current_month: 0,
      total_sessions_current_year: 0,

      chartOptionsOne: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#0f676c"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesOne: [
        {
          name: "call sessions",
          data: [],
        },
      ],

      chartOptionsTwo: {
        dataLabels: {
          enabled: false,
        },
        colors: ["#464646"],
        chart: {
          id: "vuechart-example",
        },
        xaxis: {
          categories: [],
        },
      },
      seriesTwo: [
        {
          name: "call durations",
          data: [],
        },
      ],

      seriesThree: [],
      chartOptionsThree: {
        chart: {
          width: 700,
          type: "pie",
        },
        labels: [],
        colors: [
          "#10666C",
          "#39539E",
          "#5E8722",
          "#0077B5",
          "#1e4b7d",
          "#5e8722",
          "#6c6c6c",
        ],
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
      },
    };
  },

  methods: {
    assignAnalyticsData(data) {
      this.total_sessions_current_month = data.total_sessions_current_month;
      this.total_sessions_current_year = data.total_sessions_current_year;
      this.totalCallSessions = data.total_sessions;

      if (data.call_sessions.length) {
        data.call_sessions.map((item) => {
          this.analyticsAllData.total.push(item.total);
          this.analyticsAllData.callDuration.push(item.call_duration);
          this.analyticsAllData.callDates.push(item.month);

          this.seriesOne = [
            {
              name: "call sessions",
              data: this.analyticsAllData.total,
            },
          ];
          this.chartOptionsOne = {
            xaxis: {
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
          };

          this.seriesTwo = [
            {
              name: "call durations",
              data: this.analyticsAllData.callDuration,
            },
          ];
          this.chartOptionsTwo = {
            xaxis: {
              categories: this.analyticsAllData.callDates,
            },
            dataLabels: {
              enabled: false,
            },
          };
        });
      }

      if (data.this_weekl_call_duration.length) {
        data.this_weekl_call_duration.map((item) => {
          this.chartOptionsThree.labels.push(item.weekday);
          this.seriesThree.push(parseInt(item.call_duration));
        });
      }
    },
    getAgentsAnalytics() {
      this.isGettingAnalytics = true;
      axios
        .get(`${this.$store.state.apiUrl}/v1/agents/analytics`, {
          headers: {
            Authorization: `Bearer ${this.$store.state.authUser.token}`,
          },
        })
        .then((response) => {
          this.assignAnalyticsData(response.data.data);
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingAnalytics = false;
        });
    },
  },

  created() {
    this.getAgentsAnalytics();
  },
};
</script>

<style scoped>
.emp-analytics-body {
  width: 90%;
  background: white;
  padding: 20px;
  margin: 40px auto 0;
}
</style>
