<template>
  <base-layout>
    <template #content>
      <div class="emp-details-cont">
        <div
          style="width: 100%; margin: 10px"
          class="g-flex g-align-center g-content-center"
          v-if="isGettingEmployeeDetails"
        >
          <table-data-loader></table-data-loader>
        </div>
        <div
          class="g-flex g-space-btw"
          v-if="!isGettingEmployeeDetails"
          style="margin-bottom: 20px"
        >
          <div style="width: 140px">
            <router-link :to="'/add-call-session/' + clientId">
              <base-button name="Add new call"></base-button>
            </router-link>
          </div>
          <button class="g-back-btn" @click="goBackHandler">
            <i class="fa-solid fa-arrow-left"></i>
          </button>
        </div>
        <div class="g-flex g-space-btw">
          <div v-if="!isGettingEmployeeDetails">
            <p class="heading--1"><b>EMPLOYEE DETAILS</b></p>
            <div class="g-flex g-space-btw">
              <div style="margin: 10px 30px 0">
                <p><b>Name: </b>{{ employeeDetails.details.name }}</p>
                <p><b>Phone: </b>{{ employeeDetails.details.phone }}</p>
                <p>
                  <b>Email: </b>{{ employeeDetails.details.loginDetails.email }}
                </p>
                <p>
                  <b>Gender: </b
                  >{{
                    employeeDetails.details.patient_form
                      ? employeeDetails.details.patient_form.gender
                      : "-"
                  }}
                </p>
                <p>
                  <b>Address: </b
                  >{{
                    employeeDetails.details.patient_form
                      ? employeeDetails.details.patient_form.address
                      : "-"
                  }}
                </p>
                <p>
                  <b>Date of birth: </b
                  >{{
                    employeeDetails.details.patient_form
                      ? employeeDetails.details.patient_form.date_of_birth
                      : "-"
                  }}
                </p>
                <p>
                  <b>Title/Post: </b
                  >{{
                    employeeDetails.details.patient_form
                      ? employeeDetails.details.patient_form.title
                      : "-"
                  }}
                </p>
                <p>
                  <b>Professional: </b
                  >{{
                    employeeDetails.details.patient_form
                      ? employeeDetails.details.patient_form.profession
                      : "-"
                  }}
                </p>
              </div>
              <div
                style="margin-left: 50px"
                v-if="employeeDetails.details.patient_form"
              >
                <p>
                  <b>Insurance: </b
                  >{{ employeeDetails.details.patient_form.insurance }}
                </p>
                <p>
                  <b>National ID No.: </b
                  >{{
                    employeeDetails.details.patient_form
                      ? employeeDetails.details.patient_form.nid
                      : "-"
                  }}
                </p>
                <p>
                  <b>Education: </b
                  >{{ employeeDetails.details.patient_form.education }}
                </p>
                <p>
                  <b>Marital status: </b
                  >{{ employeeDetails.details.patient_form.marital_status }}
                </p>
                <p>
                  <b>Father's name: </b
                  >{{ employeeDetails.details.patient_form.fathers_name }}
                </p>
                <p>
                  <b>Mother's name: </b
                  >{{ employeeDetails.details.patient_form.mothers_name }}
                </p>
                <p>
                  <b>Place of birth: </b
                  >{{ employeeDetails.details.patient_form.place_of_birth }}
                </p>
                <p>
                  <b>Church: </b
                  >{{ employeeDetails.details.patient_form.church }}
                </p>
              </div>
            </div>
          </div>

          <!-- <div
            style="width: 40px; margin-bottom: 10px"
            v-if="!isGettingEmployeeDetails"
          >
            <button class="g-back-btn" @click="goBackHandler">
              <i class="fa-solid fa-arrow-left"></i>
            </button>
          </div> -->
        </div>

        <div
          class="call-sessions"
          v-if="
            !isGettingEmployeeDetails && employeeDetails.call_sessions.length
          "
        >
          <p class="heading--1"><b>CALL SESSIONS</b></p>
          <div
            v-for="(item, index) in employeeDetails.call_sessions"
            :key="item.id"
          >
            <div class="g-flex g-align-center call-session-less">
              <span style="margin-right: 10px">
                <b>{{ index + 1 + ")" }}</b>
              </span>
              <p><b>Call date: </b>{{ formatDate(item.call_date) }}</p>
              <p><b>Start at: </b>{{ item.start_at }}</p>
              <p><b>End at: </b>{{ item.end_at }}</p>
              <div class="btn-call-session-less">
                <button @click="toggleViewMoreHandler(item)">More</button>
                <button @click="toggleViewNotesHandler(item)">
                  View notes
                </button>
              </div>
            </div>
            <div v-if="item.isToggleViewMore">
              <p class="p--1 g-flex g-align-center">
                <b style="width: 220px">Key-point: </b>
                <span v-html="item.key_point"></span>
              </p>
              <p class="p--1 g-flex g-align-center">
                <b style="width: 220px">Summary: </b>
                <span v-html="item.summary"></span>
              </p>
              <p class="p--1 g-flex g-align-center">
                <b style="width: 220px">Overview: </b>
                <span v-html="item.overview"></span>
              </p>
            </div>

            <div
              style="margin: 20px 0"
              v-if="item.isToggleViewNotes && item.notes.length"
            >
              <p class="heading--1"><b>CALL NOTES</b></p>
              <table>
                <thead>
                  <tr>
                    <th>Notes</th>
                    <th>Recorded_at</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in item.notes" :key="item.id">
                    <td v-html="item.notes"></td>
                    <td>{{ item.created_at }}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </template>
  </base-layout>
</template>

    

<script>
import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import axios from "axios";

import ActionsBtn from "../components/reusable/ActionsBtn.vue";
import WarningMsg from "../components/reusable/WarningMsg.vue";
import TableDataLoader from "../components/reusable/TableDataLoader.vue";
import formatDateMixin from "@/mixins/formatDateMixin";
import BaseLayout from "@/components/reusable/BaseLayout.vue";
import goBackHandler from "@/mixins/goBackHandler";
import BaseButton from "@/components/reusable/BaseButton.vue";

export default {
  components: {
    WarningMsg,
    ActionsBtn,
    TableDataLoader,
    BaseLayout,
    BaseButton,
  },
  data() {
    return {
      isToggleUnassignWarning: false,
      isUnassigningPatient: false,
      employeeIdToUnassign: "",
      isGettingEmployeeDetails: false,
      employeeDetails: null,

      // isToggleViewMore: false,
      // isToggleViewNotes: false,

      clientId: this.$route.params.id,
    };
  },
  mixins: [formatDateMixin, goBackHandler],
  props: {},
  methods: {
    toggleViewMoreHandler(item) {
      item.isToggleViewNotes = false;
      item.isToggleViewMore = !item.isToggleViewMore;
    },
    toggleViewNotesHandler(item) {
      item.isToggleViewMore = false;
      item.isToggleViewNotes = !item.isToggleViewNotes;
    },

    toggleUnassignWarningHandler(item) {
      this.employeeIdToUnassign = item;
      this.isToggleUnassignWarning = !this.isToggleUnassignWarning;
    },

    getEmployeeDetailsHandler() {
      this.isGettingEmployeeDetails = true;

      axios
        .get(
          `${this.$store.state.apiUrl}/v1/agents/employees/${this.clientId}/details`,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          const updatedCallSessions = response.data.data.call_sessions.length
            ? response.data.data.call_sessions.map((item) => ({
                ...item,
                isToggleViewMore: false,
                isToggleViewNotes: false,
              }))
            : [];

          this.employeeDetails = {
            details: response.data.data.details,
            call_sessions: updatedCallSessions,
          };
        })
        .catch((error) => {
          toast.error(error.response.data.message, {
            theme: "colored",
          });
        })
        .finally(() => {
          this.isGettingEmployeeDetails = false;
        });
    },
  },
  created() {
    this.getEmployeeDetailsHandler();
  },
};
</script>

<style scoped>
.emp-details-cont {
  padding: 20px;
}
p {
  margin: 0;
}
.heading--1 {
  margin-bottom: 10px;
}
.p--1 {
  margin: 10px 0;
  padding: 10px;
}
.call-sessions {
  margin: 20px 0;
  padding: 10px;
}
.call-session-less {
  border: 1px solid #dddddd;
  padding: 10px;
  margin-bottom: 20px;
}
.call-session-less p {
  margin-right: 40px;
}
.btn-call-session-less {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.btn-call-session-less button {
  margin-left: 20px;
  width: 100px;
}
p b {
  font-weight: 500;
}
a {
  text-decoration: none;
}
</style>