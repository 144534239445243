<template>
  <base-layout></base-layout>
</template>

<script>
import BaseLayout from "@/components/reusable/BaseLayout.vue";
export default {
  components: { BaseLayout },
};
</script>

<style>
</style>