<template>
  <div style="height: calc(100% - 60px)">
    <div ref="editor"></div>
  </div>
</template>
  
  <script>
import { ref, onMounted, getCurrentInstance } from "vue";
import Quill from "quill";
import "quill/dist/quill.snow.css";

export default {
  name: "WysiwygEditor",
  setup() {
    const editor = ref(null);
    const instance = getCurrentInstance(); // Get the current component instance

    let quillInstance = null;

    onMounted(() => {
      const options = {
        modules: {
          toolbar: [
            [{ header: [1, 2, 3, false] }],
            ["bold", "italic", "underline", "link"],
            [{ list: "ordered" }, { list: "bullet" }],
          ],
        },
        theme: "snow",
      };
      quillInstance = new Quill(editor.value, options);

      // Emit input event on editor changes
      quillInstance.on("text-change", () => {
        instance.emit("update:modelValue", quillInstance.root.innerHTML);
      });
    });

    return {
      editor,
    };
  },
};
</script>
  
<style scoped>
</style>
  


