<template>
  <div>
    <h3 class="heading--1">Contract Confirmation</h3>
    <!-- n -->
    <label>Contract Start Date</label>
    <input type="date" placeholder="" v-model="startingDate" />
    <p v-if="companyErrors.startingDate && !startingDate" class="form-errors">
      Contract start date is required
    </p>

    <!-- n -->
    <label>Contract File</label>
    <input type="file" placeholder="" @change="onContractFileAdded" />
    <p v-if="companyErrors.newContract && !newContract" class="form-errors">
      Contract file is required
    </p>
    <base-button
      name="Save"
      :isLoading="isMakingChangeOnCompany"
      :onClick="approveCompanyHandler"
    ></base-button>
  </div>
</template>

<script>
import axios from "axios";

import { toast } from "vue3-toastify";
import "vue3-toastify/dist/index.css";

import BaseButton from "./reusable/BaseButton.vue";

export default {
  components: { BaseButton },
  data() {
    return {
      startingDate: "",
      endingDate: "",
      newContract: null,
      isMakingChangeOnCompany: false,
      companyErrors: {},
    };
  },

  props: ["companyToApprove", "returnApprovedCompany"],

  methods: {
    onContractFileAdded(e) {
      var files = e.target.files;
      if (!files.length) return;
      this.newContract = files[0];
    },

    validateForm() {
      this.companyErrors = {};

      if (!this.startingDate) {
        this.companyErrors.startingDate = true;
      }
      if (!this.newContract) {
        this.companyErrors.newContract = true;
      }

      return (
        !this.companyErrors.startingDate && !this.companyErrors.newContract
      );
    },

    approveCompanyHandler() {
      if (!this.validateForm()) {
        return;
      }
      this.isMakingChangeOnCompany = true;

      let data = new FormData();

      data.append("starting_date", this.startingDate);
      data.append("_method", "PUT");
      if (typeof this.newContract === "object") {
        data.append("contract", this.newContract);
      }

      axios
        .post(
          `${this.$store.state.apiUrl}/v1/uhuru/clients/${this.companyToApprove}/confirm`,
          data,
          {
            headers: {
              Authorization: `Bearer ${this.$store.state.authUser.token}`,
            },
          }
        )
        .then((response) => {
          toast.success(response.data.message, {
            theme: "colored",
          });
          this.returnApprovedCompany(this.companyToApprove, response.data.data);
        })
        .catch((error) => {
          let returnedError = [];

          if (error.response.data.errors) {
            for (let item in error.response.data.errors) {
              returnedError.push(error.response.data.errors[item][0]);
            }
            toast.error(returnedError.toString(), {
              theme: "colored",
            });
          } else {
            toast.error(error.response.data.message, {
              theme: "colored",
            });
          }
        })
        .finally(() => {
          this.isMakingChangeOnCompany = false;
        });
    },
  },
};
</script>

<style scoped>
input {
  margin-bottom: 10px;
}
input[type="file"] {
  border: none;
}
.heading--1 {
  margin: 10px 0;
}
</style>